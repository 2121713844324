export const PauseCircle = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1209 7.84638 17.2635 5.78216 15.7407 4.25932C14.2178 2.73648 12.1536 1.87913 10 1.875ZM8.75 12.5C8.75 12.6658 8.68416 12.8247 8.56695 12.9419C8.44974 13.0592 8.29076 13.125 8.125 13.125C7.95924 13.125 7.80027 13.0592 7.68306 12.9419C7.56585 12.8247 7.5 12.6658 7.5 12.5V7.5C7.5 7.33424 7.56585 7.17527 7.68306 7.05806C7.80027 6.94085 7.95924 6.875 8.125 6.875C8.29076 6.875 8.44974 6.94085 8.56695 7.05806C8.68416 7.17527 8.75 7.33424 8.75 7.5V12.5ZM12.5 12.5C12.5 12.6658 12.4342 12.8247 12.3169 12.9419C12.1997 13.0592 12.0408 13.125 11.875 13.125C11.7092 13.125 11.5503 13.0592 11.4331 12.9419C11.3159 12.8247 11.25 12.6658 11.25 12.5V7.5C11.25 7.33424 11.3159 7.17527 11.4331 7.05806C11.5503 6.94085 11.7092 6.875 11.875 6.875C12.0408 6.875 12.1997 6.94085 12.3169 7.05806C12.4342 7.17527 12.5 7.33424 12.5 7.5V12.5Z"
      fill="#EF9021"
    />
  </svg>
);
