export const Visa = () => (
  <svg
    width="48"
    height="16"
    viewBox="0 0 48 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.2641 0C27.856 0 24.8103 1.81737 24.8103 5.17511C24.8103 9.0258 30.2119 9.29176 30.2119 11.2262C30.2119 12.0408 29.3046 12.7699 27.7549 12.7699C25.5556 12.7699 23.9118 11.7511 23.9118 11.7511L23.2085 15.1394C23.2085 15.1394 25.1021 16 27.6161 16C31.3424 16 34.2745 14.0934 34.2745 10.6782C34.2745 6.6093 28.8503 6.35124 28.8503 4.55572C28.8503 3.91765 29.5951 3.21852 31.1404 3.21852C32.8839 3.21852 34.3063 3.95948 34.3063 3.95948L34.9947 0.686927C34.9947 0.686927 33.4469 0 31.2641 0ZM0.0825258 0.246985L0 0.740955C0 0.740955 1.43383 1.01092 2.72523 1.54945C4.38801 2.16697 4.50646 2.52646 4.7865 3.64303L7.83807 15.7453H11.9287L18.2307 0.246985H14.1494L10.1 10.7844L8.44764 1.85239C8.2961 0.83013 7.52851 0.246985 6.58893 0.246985H0.0825258ZM19.8718 0.246985L16.6702 15.7453H20.5621L23.7524 0.246985H19.8718ZM41.578 0.246985C40.6395 0.246985 40.1423 0.763883 39.7774 1.66715L34.0756 15.7453H38.1569L38.9465 13.3989H43.9187L44.3989 15.7453H48L44.8584 0.246985H41.578ZM42.1088 4.43415L43.3185 10.2499H40.0775L42.1088 4.43415Z"
      fill="white"
    />
  </svg>
);

export const MasterCard = () => (
  <svg
    width="36"
    height="22"
    viewBox="0 0 36 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.3203 2.35156H22.9453V19.646H13.3203V2.35156Z" fill="#FF5F00" />
    <path
      d="M13.9312 11C13.9312 7.48611 15.5812 4.36945 18.1173 2.35278C16.2534 0.886115 13.9006 0 11.334 0C5.25343 0 0.333984 4.91944 0.333984 11C0.333984 17.0806 5.25343 22 11.334 22C13.9006 22 16.2534 21.1139 18.1173 19.6472C15.5812 17.6611 13.9312 14.5139 13.9312 11Z"
      fill="#EB001B"
    />
    <path
      d="M35.9318 11C35.9318 17.0806 31.0123 22 24.9318 22C22.3651 22 20.0123 21.1139 18.1484 19.6472C20.7151 17.6306 22.3346 14.5139 22.3346 11C22.3346 7.48611 20.6845 4.36945 18.1484 2.35278C20.0123 0.886115 22.3651 0 24.9318 0C31.0123 0 35.9318 4.95 35.9318 11Z"
      fill="#F79E1B"
    />
  </svg>
);

export const AmericanExpress = () => (
  <svg
    width="65"
    height="16"
    viewBox="0 0 65 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.88894 0L0.666016 15.9794H9.31286L10.3848 13.4317H12.8351L13.907 15.9794H23.4248V14.0349L24.2729 15.9794H29.1962L30.0443 13.9938V15.9794H49.8386L52.2455 13.4978L54.4992 15.9794L64.666 16L57.4203 8.03434L64.666 0H54.6569L52.3139 2.43576L50.1312 0H28.5975L26.7484 4.12443L24.856 0H16.2272V1.87839L15.2673 0C15.2673 0 7.88894 0 7.88894 0ZM9.56205 2.2691H13.7769L18.5679 13.1049V2.2691H23.1851L26.8856 10.0383L30.296 2.2691H34.8901V13.7354H32.0947L32.0719 4.75048L27.9964 13.7354H25.4957L21.3974 4.75048V13.7354H15.6466L14.5563 11.1648H8.66608L7.57808 13.7332H4.49683L9.56205 2.2691ZM37.4477 2.2691H48.8145L52.2911 6.02337L55.8798 2.2691H59.3564L54.0741 8.03208L59.3564 13.7286H55.7221L52.2455 9.93077L48.6386 13.7286H37.4477V2.2691ZM11.6125 4.20914L9.67185 8.78845H13.5508L11.6125 4.20914ZM40.2547 4.64321V6.73638H46.4558V9.06939H40.2547V11.3545H47.2101L50.442 7.98872L47.3473 4.64121H40.2547V4.64321Z"
      fill="white"
    />
  </svg>
);
