export const Rating = () => (
  <svg
    width="46"
    height="42"
    viewBox="0 0 46 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.8722 34.2253H12.6299L0.599609 41.0668V6.72755C0.599609 4.99639 1.28731 3.33613 2.51143 2.11201C3.73555 0.887897 5.39581 0.200195 7.12697 0.200195H38.8722C39.7294 0.200195 40.5782 0.36903 41.3702 0.697061C42.1621 1.02509 42.8817 1.50589 43.4878 2.11201C44.0939 2.71814 44.5747 3.43771 44.9027 4.22964C45.2308 5.02158 45.3996 5.87037 45.3996 6.72755V27.6979C45.3996 28.5551 45.2308 29.4039 44.9027 30.1959C44.5747 30.9878 44.0939 31.7074 43.4878 32.3135C42.8817 32.9196 42.1621 33.4004 41.3702 33.7284C40.5782 34.0565 39.7294 34.2253 38.8722 34.2253Z"
      fill="#EF9021"
    />
    <path
      d="M22.9993 7.73535L25.7799 13.3692L31.9966 14.2727L27.4982 18.6576L28.56 24.8499L22.9993 21.926L17.4386 24.8499L18.5004 18.6576L14.002 14.2727L20.2187 13.3692L22.9993 7.73535Z"
      fill="white"
    />
  </svg>
);
