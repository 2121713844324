export const ShootingStar = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.825 4.34102L13.9999 6.69101C14.1583 7.01601 14.5833 7.32434 14.9416 7.39101L17.0666 7.741C18.425 7.966 18.7416 8.94934 17.7666 9.93268L16.1083 11.591C15.8333 11.866 15.675 12.4077 15.7666 12.7993L16.2416 14.8493C16.6166 16.466 15.75 17.0993 14.325 16.2493L12.3333 15.066C11.9749 14.8493 11.375 14.8493 11.0166 15.066L9.02495 16.2493C7.59995 17.091 6.73329 16.466 7.10829 14.8493L7.5833 12.7993C7.67496 12.416 7.51663 11.8743 7.24163 11.591L5.5833 9.93268C4.6083 8.95768 4.92497 7.97434 6.2833 7.741L8.40829 7.39101C8.76662 7.33267 9.19163 7.01601 9.34996 6.69101L10.525 4.34102C11.15 3.06602 12.1833 3.06602 12.825 4.34102Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6665 4.16602H1.6665"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.1665 15.834H1.6665"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49984 10H1.6665"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
