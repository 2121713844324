import { BaseIconProps } from './types';

export function Person({
  width = 20,
  height = 20,
  fill = 'currentColor',
}: BaseIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 21.5C25.799 21.5 30.5 16.799 30.5 11C30.5 5.20101 25.799 0.5 20 0.5C14.201 0.5 9.5 5.20101 9.5 11C9.5 16.799 14.201 21.5 20 21.5Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M20 21.5C25.799 21.5 30.5 16.799 30.5 11C30.5 5.20101 25.799 0.5 20 0.5C14.201 0.5 9.5 5.20101 9.5 11C9.5 16.799 14.201 21.5 20 21.5Z"
        fill="white"
      />
      <path
        d="M27.455 24.5H12.545C9.35169 24.504 6.2903 25.7743 4.03228 28.0323C1.77427 30.2903 0.503969 33.3517 0.5 36.545V44C0.5 44.3978 0.658035 44.7794 0.93934 45.0607C1.22064 45.342 1.60218 45.5 2 45.5H38C38.3978 45.5 38.7794 45.342 39.0607 45.0607C39.342 44.7794 39.5 44.3978 39.5 44V36.545C39.496 33.3517 38.2257 30.2903 35.9677 28.0323C33.7097 25.7743 30.6483 24.504 27.455 24.5V24.5Z"
        fill={fill}
      />
    </svg>
  );
}

export function PersonOutline() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0002 10.0001C12.3013 10.0001 14.1668 8.1346 14.1668 5.83341C14.1668 3.53223 12.3013 1.66675 10.0002 1.66675C7.69898 1.66675 5.8335 3.53223 5.8335 5.83341C5.8335 8.1346 7.69898 10.0001 10.0002 10.0001Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1585 18.3333C17.1585 15.1083 13.9501 12.5 10.0001 12.5C6.05013 12.5 2.8418 15.1083 2.8418 18.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const PersonCheck = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4C4 1.79 5.79 0 8 0C10.21 0 12 1.79 12 4C12 6.21 10.21 8 8 8C5.79 8 4 6.21 4 4ZM10 14.2C10 13.24 10.5 12.34 11.2 11.74V11.5C11.2 11.11 11.27 10.74 11.38 10.38C10.35 10.14 9.21 10 8 10C3.58 10 0 11.79 0 14V16H10V14.2ZM20 14.3V17.8C20 18.4 19.4 19 18.7 19H13.2C12.6 19 12 18.4 12 17.7V14.2C12 13.6 12.6 13 13.2 13V11.5C13.2 10.1 14.6 9 16 9C17.4 9 18.8 10.1 18.8 11.5V13C19.4 13 20 13.6 20 14.3ZM17.5 11.5C17.5 10.7 16.8 10.2 16 10.2C15.2 10.2 14.5 10.7 14.5 11.5V13H17.5V11.5Z"
      fill="white"
    />
  </svg>
);
