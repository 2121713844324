export const SadEmoji = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2095_45645)">
      <path
        d="M10.834 19.25C8.51334 19.25 6.28774 18.3281 4.6468 16.6872C3.00586 15.0462 2.08398 12.8206 2.08398 10.5C2.08398 8.17936 3.00586 5.95376 4.6468 4.31282C6.28774 2.67187 8.51334 1.75 10.834 1.75C13.1546 1.75 15.3802 2.67187 17.0212 4.31282C18.6621 5.95376 19.584 8.17936 19.584 10.5C19.584 12.8206 18.6621 15.0462 17.0212 16.6872C15.3802 18.3281 13.1546 19.25 10.834 19.25ZM10.834 20.5C13.4861 20.5 16.0297 19.4464 17.9051 17.5711C19.7804 15.6957 20.834 13.1522 20.834 10.5C20.834 7.84784 19.7804 5.3043 17.9051 3.42893C16.0297 1.55357 13.4861 0.5 10.834 0.5C8.18182 0.5 5.63828 1.55357 3.76292 3.42893C1.88755 5.3043 0.833984 7.84784 0.833984 10.5C0.833984 13.1522 1.88755 15.6957 3.76292 17.5711C5.63828 19.4464 8.18182 20.5 10.834 20.5Z"
        fill="currentColor"
      />
      <path
        d="M6.18944 16.0412C6.33298 16.1241 6.50358 16.1466 6.66368 16.1037C6.82379 16.0608 6.9603 15.956 7.04319 15.8125C7.42707 15.1471 7.9795 14.5945 8.64485 14.2105C9.31019 13.8265 10.065 13.6245 10.8332 13.625C12.4519 13.625 13.8657 14.5037 14.6232 15.8125C14.7067 15.9549 14.8431 16.0585 15.0026 16.1006C15.1622 16.1428 15.3319 16.1202 15.4749 16.0377C15.6178 15.9551 15.7223 15.8194 15.7655 15.6602C15.8088 15.5009 15.7873 15.331 15.7057 15.1875C15.2121 14.3321 14.5019 13.6217 13.6465 13.128C12.7911 12.6343 11.8208 12.3746 10.8332 12.375C9.8456 12.3748 8.87538 12.6346 8.02006 13.1283C7.16474 13.622 6.45448 14.3322 5.96069 15.1875C5.87781 15.331 5.85535 15.5016 5.89825 15.6617C5.94115 15.8219 6.04589 15.9584 6.18944 16.0412ZM9.58319 8.625C9.58319 9.66 9.02319 10.5 8.33319 10.5C7.64319 10.5 7.08319 9.66 7.08319 8.625C7.08319 7.59 7.64319 6.75 8.33319 6.75C9.02319 6.75 9.58319 7.59 9.58319 8.625ZM14.5832 8.625C14.5832 9.66 14.0232 10.5 13.3332 10.5C12.6432 10.5 12.0832 9.66 12.0832 8.625C12.0832 7.59 12.6432 6.75 13.3332 6.75C14.0232 6.75 14.5832 7.59 14.5832 8.625Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2095_45645">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.833984 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const NeutralEmoji = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 19.25C8.17936 19.25 5.95376 18.3281 4.31282 16.6872C2.67187 15.0462 1.75 12.8206 1.75 10.5C1.75 8.17936 2.67187 5.95376 4.31282 4.31282C5.95376 2.67187 8.17936 1.75 10.5 1.75C12.8206 1.75 15.0462 2.67187 16.6872 4.31282C18.3281 5.95376 19.25 8.17936 19.25 10.5C19.25 12.8206 18.3281 15.0462 16.6872 16.6872C15.0462 18.3281 12.8206 19.25 10.5 19.25ZM10.5 20.5C13.1522 20.5 15.6957 19.4464 17.5711 17.5711C19.4464 15.6957 20.5 13.1522 20.5 10.5C20.5 7.84784 19.4464 5.3043 17.5711 3.42893C15.6957 1.55357 13.1522 0.5 10.5 0.5C7.84784 0.5 5.3043 1.55357 3.42893 3.42893C1.55357 5.3043 0.5 7.84784 0.5 10.5C0.5 13.1522 1.55357 15.6957 3.42893 17.5711C5.3043 19.4464 7.84784 20.5 10.5 20.5V20.5Z"
      fill="currentColor"
    />
    <path
      d="M5.5 13.625C5.5 13.7908 5.56585 13.9497 5.68306 14.0669C5.80027 14.1842 5.95924 14.25 6.125 14.25H14.875C15.0408 14.25 15.1997 14.1842 15.3169 14.0669C15.4342 13.9497 15.5 13.7908 15.5 13.625C15.5 13.4592 15.4342 13.3003 15.3169 13.1831C15.1997 13.0658 15.0408 13 14.875 13H6.125C5.95924 13 5.80027 13.0658 5.68306 13.1831C5.56585 13.3003 5.5 13.4592 5.5 13.625ZM9.25 8.625C9.25 7.59 8.69 6.75 8 6.75C7.31 6.75 6.75 7.59 6.75 8.625C6.75 9.66 7.31 10.5 8 10.5C8.69 10.5 9.25 9.66 9.25 8.625ZM14.25 8.625C14.25 7.59 13.69 6.75 13 6.75C12.31 6.75 11.75 7.59 11.75 8.625C11.75 9.66 12.31 10.5 13 10.5C13.69 10.5 14.25 9.66 14.25 8.625Z"
      fill="currentColor"
    />
  </svg>
);

export const HappyEmoji = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.168 19.25C7.84732 19.25 5.62173 18.3281 3.98078 16.6872C2.33984 15.0462 1.41797 12.8206 1.41797 10.5C1.41797 8.17936 2.33984 5.95376 3.98078 4.31282C5.62173 2.67187 7.84732 1.75 10.168 1.75C12.4886 1.75 14.7142 2.67187 16.3552 4.31282C17.9961 5.95376 18.918 8.17936 18.918 10.5C18.918 12.8206 17.9961 15.0462 16.3552 16.6872C14.7142 18.3281 12.4886 19.25 10.168 19.25ZM10.168 20.5C12.8201 20.5 15.3637 19.4464 17.239 17.5711C19.1144 15.6957 20.168 13.1522 20.168 10.5C20.168 7.84784 19.1144 5.3043 17.239 3.42893C15.3637 1.55357 12.8201 0.5 10.168 0.5C7.5158 0.5 4.97226 1.55357 3.0969 3.42893C1.22154 5.3043 0.167969 7.84784 0.167969 10.5C0.167969 13.1522 1.22154 15.6957 3.0969 17.5711C4.97226 19.4464 7.5158 20.5 10.168 20.5Z"
      fill="currentColor"
    />
    <path
      d="M5.52342 12.4588C5.66697 12.3759 5.83756 12.3534 5.99767 12.3963C6.15778 12.4392 6.29429 12.544 6.37717 12.6875C6.76106 13.3529 7.31349 13.9055 7.97883 14.2895C8.64418 14.6735 9.39895 14.8755 10.1672 14.875C10.9354 14.8755 11.6902 14.6735 12.3555 14.2895C13.0209 13.9055 13.5733 13.3529 13.9572 12.6875C13.9979 12.6158 14.0524 12.553 14.1176 12.5025C14.1827 12.452 14.2572 12.4148 14.3367 12.3932C14.4163 12.3716 14.4993 12.366 14.5811 12.3766C14.6628 12.3872 14.7416 12.4139 14.813 12.4551C14.8844 12.4964 14.9469 12.5513 14.997 12.6168C15.047 12.6822 15.0837 12.757 15.1047 12.8367C15.1258 12.9163 15.1309 12.9994 15.1197 13.0811C15.1086 13.1627 15.0814 13.2414 15.0397 13.3125C14.5461 14.1679 13.8359 14.8783 12.9805 15.372C12.1251 15.8657 11.1548 16.1254 10.1672 16.125C9.17954 16.1254 8.20921 15.8657 7.35385 15.372C6.49849 14.8783 5.78827 14.1679 5.29467 13.3125C5.21179 13.169 5.18933 12.9984 5.23223 12.8383C5.27513 12.6781 5.37987 12.5416 5.52342 12.4588ZM8.91717 8.625C8.91717 9.66 8.35717 10.5 7.66717 10.5C6.97717 10.5 6.41717 9.66 6.41717 8.625C6.41717 7.59 6.97717 6.75 7.66717 6.75C8.35717 6.75 8.91717 7.59 8.91717 8.625ZM13.9172 8.625C13.9172 9.66 13.3572 10.5 12.6672 10.5C11.9772 10.5 11.4172 9.66 11.4172 8.625C11.4172 7.59 11.9772 6.75 12.6672 6.75C13.3572 6.75 13.9172 7.59 13.9172 8.625Z"
      fill="currentColor"
    />
  </svg>
);
