export const DotsVertical = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.375 14.375C13.2034 14.375 13.875 13.7034 13.875 12.875C13.875 12.0466 13.2034 11.375 12.375 11.375C11.5466 11.375 10.875 12.0466 10.875 12.875C10.875 13.7034 11.5466 14.375 12.375 14.375Z"
      fill="black"
    />
    <path
      d="M12.375 8.375C13.2034 8.375 13.875 7.70343 13.875 6.875C13.875 6.04657 13.2034 5.375 12.375 5.375C11.5466 5.375 10.875 6.04657 10.875 6.875C10.875 7.70343 11.5466 8.375 12.375 8.375Z"
      fill="black"
    />
    <path
      d="M12.375 20.375C13.2034 20.375 13.875 19.7034 13.875 18.875C13.875 18.0466 13.2034 17.375 12.375 17.375C11.5466 17.375 10.875 18.0466 10.875 18.875C10.875 19.7034 11.5466 20.375 12.375 20.375Z"
      fill="black"
    />
  </svg>
);
