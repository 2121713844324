export const MxListing = () => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.0416 2.38889V8.40973H8.40975V38.0417H2.38889C2.02053 38.0417 1.66728 37.8953 1.40682 37.6349C1.14635 37.3744 1 37.0211 1 36.6528V2.38889C1 2.02053 1.14635 1.66726 1.40682 1.4068C1.66728 1.14633 2.02053 1 2.38889 1H36.6528C37.0211 1 37.3745 1.14633 37.6349 1.4068C37.8954 1.66726 38.0416 2.02053 38.0416 2.38889Z"
      fill="#C41719"
    />
    <path
      d="M15.8194 18.2847H38.0417M15.8194 24.4583H38.0417M15.8194 30.632H38.0417M45.4445 9.80555V34.8056C43.9609 34.2813 42.3593 34.1878 40.8248 34.5358C39.2903 34.8838 37.8856 35.6591 36.7734 36.7721C35.6611 37.8851 34.8867 39.2902 34.5397 40.8249C34.1927 42.3596 34.2873 43.9612 34.8126 45.4445H9.81255C9.44419 45.4445 9.09086 45.2981 8.83039 45.0377C8.56992 44.7772 8.42366 44.4239 8.42366 44.0556V9.80555C8.42366 9.4372 8.56992 9.08392 8.83039 8.82346C9.09086 8.56299 9.44419 8.41666 9.81255 8.41666H44.0695C44.4378 8.41666 44.7911 8.56299 45.0515 8.82346C45.312 9.08392 45.4584 9.4372 45.4584 9.80555H45.4445ZM38.0417 2.38889V8.40973H9.79864C9.43029 8.40973 9.07695 8.55606 8.81649 8.81653C8.55602 9.07699 8.40975 9.43027 8.40975 9.79862V38.0417H2.38889C2.02053 38.0417 1.66728 37.8953 1.40682 37.6349C1.14635 37.3744 1 37.0211 1 36.6528V2.38889C1 2.02053 1.14635 1.66726 1.40682 1.4068C1.66728 1.14633 2.02053 1 2.38889 1H36.6528C37.0211 1 37.3745 1.14633 37.6349 1.4068C37.8954 1.66726 38.0417 2.02053 38.0417 2.38889V2.38889Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.9997 42.6746C50.998 44.6391 50.3023 46.5398 49.0354 48.0413C47.7686 49.5427 46.0119 50.5483 44.0757 50.8806C42.1395 51.2128 40.1482 50.8504 38.4532 49.8572C36.7583 48.864 35.4688 47.3039 34.8122 45.4524C34.4064 44.3079 34.2555 43.0886 34.3703 41.8798C34.485 40.671 34.8626 39.5019 35.4765 38.4542C36.0904 37.4066 36.9258 36.5058 37.9242 35.8148C38.9227 35.1238 40.0602 34.6594 41.2569 34.454C42.4537 34.2487 43.6809 34.3073 44.8526 34.6259C46.0243 34.9444 47.1123 35.5152 48.0403 36.2983C48.9683 37.0813 49.714 38.0577 50.2252 39.1591C50.7364 40.2605 51.0007 41.4603 50.9997 42.6746Z"
      fill="#C41719"
    />
    <path
      d="M50.9997 42.6746C50.998 44.6391 50.3023 46.5398 49.0354 48.0413C47.7686 49.5427 46.0119 50.5483 44.0757 50.8806C42.1395 51.2128 40.1482 50.8504 38.4532 49.8572C36.7583 48.864 35.4688 47.3039 34.8122 45.4524C34.4064 44.3079 34.2555 43.0886 34.3703 41.8798C34.485 40.671 34.8626 39.5019 35.4765 38.4542C36.0904 37.4066 36.9258 36.5058 37.9242 35.8148C38.9227 35.1238 40.0602 34.6594 41.2569 34.454C42.4537 34.2487 43.6809 34.3073 44.8526 34.6259C46.0243 34.9444 47.1123 35.5152 48.0403 36.2983C48.9683 37.0813 49.714 38.0577 50.2252 39.1591C50.7364 40.2605 51.0007 41.4603 50.9997 42.6746Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.6667 38.5078V46.8411M46.8333 42.6745H38.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
