export const Package = () => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.4352 0.599609H22.9996H8.56405L0.599609 10.0121H22.9996H45.3996L37.4352 0.599609Z"
      fill="#EF9021"
    />
    <path
      opacity="0.2"
      d="M37.4352 0.599609H22.9996H8.56405L0.599609 10.0121H22.9996H45.3996L37.4352 0.599609Z"
      fill="white"
    />
    <path
      d="M0.599609 10.0127H45.3996V45.2111H0.599609V10.0127Z"
      fill="#EF9021"
    />
    <path
      d="M26.8794 0.599609H19.1171L17.126 10.0121H28.8705L26.8794 0.599609Z"
      fill="#242E5F"
    />
    <path d="M17.126 10.0127H28.87V20.2973H17.126V10.0127Z" fill="#242E5F" />
    <path
      opacity="0.2"
      d="M17.126 10.0127H28.87V20.2973H17.126V10.0127Z"
      fill="#202330"
    />
    <path d="M30.0771 33.7773H40.4976V40.0802H30.0771V33.7773Z" fill="white" />
  </svg>
);

export const PackageOutline = ({
  width = 24,
  height = 24,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 16.621V7.37727C20.9993 7.24374 20.9634 7.11277 20.8959 6.99755C20.8284 6.88234 20.7317 6.78695 20.6156 6.72102L12.3656 2.08039C12.2545 2.01621 12.1284 1.98242 12 1.98242C11.8716 1.98242 11.7455 2.01621 11.6344 2.08039L3.38437 6.72102C3.26827 6.78695 3.1716 6.88234 3.10411 6.99755C3.03663 7.11277 3.00072 7.24374 3 7.37727V16.621C3.00072 16.7545 3.03663 16.8855 3.10411 17.0007C3.1716 17.1159 3.26827 17.2113 3.38437 17.2773L11.6344 21.9179C11.7455 21.9821 11.8716 22.0159 12 22.0159C12.1284 22.0159 12.2545 21.9821 12.3656 21.9179L20.6156 17.2773C20.7317 17.2113 20.8284 17.1159 20.8959 17.0007C20.9634 16.8855 20.9993 16.7545 21 16.621V16.621Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8438 14.2969V15.0469H17.3438V14.2969H15.8438ZM16.5938 9.42188H17.3438C17.3438 9.14868 17.1952 8.89709 16.956 8.76514L16.5938 9.42188ZM7.86222 3.74952C7.49952 3.54947 7.04332 3.68133 6.84327 4.04403C6.64322 4.40673 6.77508 4.86293 7.13778 5.06298L7.86222 3.74952ZM17.3438 14.2969V9.42188H15.8438V14.2969H17.3438ZM16.956 8.76514L7.86222 3.74952L7.13778 5.06298L16.2315 10.0786L16.956 8.76514Z"
      fill="currentColor"
    />
    <path
      d="M20.8973 6.99414L12.0848 12.0004L3.10352 6.99414"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0844 12L12 22.0125"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
