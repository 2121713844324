export const ShoppingBag = () => (
  <svg
    width="57"
    height="59"
    viewBox="0 0 57 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.375 13.8184H56.625L54.6112 48.8421C54.4669 51.3521 53.368 53.7118 51.5399 55.4378C49.7118 57.1637 47.2929 58.1252 44.7787 58.1252H12.2212C9.70711 58.1252 7.28816 57.1637 5.46006 55.4378C3.63196 53.7118 2.53314 51.3521 2.38875 48.8421L0.375 13.8184Z"
      fill="#D9D9D9"
    />
    <path
      d="M42.1322 22.9025C41.8422 22.9023 41.5641 22.787 41.359 22.582C41.1539 22.3769 41.0386 22.0988 41.0384 21.8088V9.13625C41.0366 7.50109 40.3862 5.93342 39.23 4.77718C38.0738 3.62094 36.5061 2.97057 34.8709 2.96875H22.1284C20.4933 2.97057 18.9256 3.62094 17.7694 4.77718C16.6131 5.93342 15.9628 7.50109 15.9609 9.13625V21.8088C15.9609 22.0988 15.8457 22.377 15.6406 22.5821C15.4355 22.7873 15.1573 22.9025 14.8672 22.9025C14.5771 22.9025 14.2989 22.7873 14.0938 22.5821C13.8887 22.377 13.7734 22.0988 13.7734 21.8088V9.13625C13.7759 6.92113 14.657 4.79744 16.2233 3.23111C17.7896 1.66478 19.9133 0.783731 22.1284 0.78125H34.8709C37.0861 0.783731 39.2097 1.66478 40.7761 3.23111C42.3424 4.79744 43.2235 6.92113 43.2259 9.13625V21.8088C43.2258 22.0988 43.1105 22.3769 42.9054 22.582C42.7003 22.787 42.4222 22.9023 42.1322 22.9025V22.9025Z"
      fill="#808080"
    />
    <path
      d="M42.1322 22.9025C41.8422 22.9023 41.5641 22.787 41.359 22.582C41.1539 22.3769 41.0386 22.0988 41.0384 21.8088V9.13625C41.0366 7.50109 40.3862 5.93342 39.23 4.77718C38.0738 3.62094 36.5061 2.97057 34.8709 2.96875H22.1284C20.4933 2.97057 18.9256 3.62094 17.7694 4.77718C16.6131 5.93342 15.9628 7.50109 15.9609 9.13625V21.8088C15.9609 22.0988 15.8457 22.377 15.6406 22.5821C15.4355 22.7873 15.1573 22.9025 14.8672 22.9025C14.5771 22.9025 14.2989 22.7873 14.0938 22.5821C13.8887 22.377 13.7734 22.0988 13.7734 21.8088V9.13625C13.7759 6.92113 14.657 4.79744 16.2233 3.23111C17.7896 1.66478 19.9133 0.783731 22.1284 0.78125H34.8709C37.0861 0.783731 39.2097 1.66478 40.7761 3.23111C42.3424 4.79744 43.2235 6.92113 43.2259 9.13625V21.8088C43.2258 22.0988 43.1105 22.3769 42.9054 22.582C42.7003 22.787 42.4222 22.9023 42.1322 22.9025V22.9025Z"
      fill="#808080"
    />
  </svg>
);
