export const Grid = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33301 6.66658H4.66634C5.99967 6.66658 6.66634 5.99992 6.66634 4.66659V3.33325C6.66634 1.99992 5.99967 1.33325 4.66634 1.33325H3.33301C1.99967 1.33325 1.33301 1.99992 1.33301 3.33325V4.66659C1.33301 5.99992 1.99967 6.66658 3.33301 6.66658Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.333 6.66658H12.6663C13.9997 6.66658 14.6663 5.99992 14.6663 4.66659V3.33325C14.6663 1.99992 13.9997 1.33325 12.6663 1.33325H11.333C9.99967 1.33325 9.33301 1.99992 9.33301 3.33325V4.66659C9.33301 5.99992 9.99967 6.66658 11.333 6.66658Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.333 14.6666H12.6663C13.9997 14.6666 14.6663 13.9999 14.6663 12.6666V11.3333C14.6663 9.99992 13.9997 9.33325 12.6663 9.33325H11.333C9.99967 9.33325 9.33301 9.99992 9.33301 11.3333V12.6666C9.33301 13.9999 9.99967 14.6666 11.333 14.6666Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33301 14.6666H4.66634C5.99967 14.6666 6.66634 13.9999 6.66634 12.6666V11.3333C6.66634 9.99992 5.99967 9.33325 4.66634 9.33325H3.33301C1.99967 9.33325 1.33301 9.99992 1.33301 11.3333V12.6666C1.33301 13.9999 1.99967 14.6666 3.33301 14.6666Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
