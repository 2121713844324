export const Discount = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2115 10.8699L20.6915 9.34988C20.4315 9.08988 20.2215 8.57988 20.2215 8.21988V6.05988C20.2215 5.17988 19.5015 4.45988 18.6215 4.45988H16.4715C16.1115 4.45988 15.6015 4.24988 15.3415 3.98988L13.8215 2.46988C13.2015 1.84988 12.1815 1.84988 11.5615 2.46988L10.0215 3.98988C9.77152 4.24988 9.26152 4.45988 8.89152 4.45988H6.74152C5.86152 4.45988 5.14152 5.17988 5.14152 6.05988V8.20988C5.14152 8.56988 4.93152 9.07988 4.67152 9.33988L3.15152 10.8599C2.53152 11.4799 2.53152 12.4999 3.15152 13.1199L4.67152 14.6399C4.93152 14.8999 5.14152 15.4099 5.14152 15.7699V17.9199C5.14152 18.7999 5.86152 19.5199 6.74152 19.5199H8.89152C9.25152 19.5199 9.76152 19.7299 10.0215 19.9899L11.5415 21.5099C12.1615 22.1299 13.1815 22.1299 13.8015 21.5099L15.3215 19.9899C15.5815 19.7299 16.0915 19.5199 16.4515 19.5199H18.6015C19.4815 19.5199 20.2015 18.7999 20.2015 17.9199V15.7699C20.2015 15.4099 20.4115 14.8999 20.6715 14.6399L22.1915 13.1199C22.8415 12.5099 22.8415 11.4899 22.2115 10.8699ZM8.68152 8.99988C8.68152 8.44988 9.13152 7.99988 9.68152 7.99988C10.2315 7.99988 10.6815 8.44988 10.6815 8.99988C10.6815 9.54988 10.2415 9.99988 9.68152 9.99988C9.13152 9.99988 8.68152 9.54988 8.68152 8.99988ZM10.2115 15.5299C10.0615 15.6799 9.87152 15.7499 9.68152 15.7499C9.49152 15.7499 9.30152 15.6799 9.15152 15.5299C8.86152 15.2399 8.86152 14.7599 9.15152 14.4699L15.1515 8.46988C15.4415 8.17988 15.9215 8.17988 16.2115 8.46988C16.5015 8.75988 16.5015 9.23988 16.2115 9.52988L10.2115 15.5299ZM15.6815 15.9999C15.1215 15.9999 14.6715 15.5499 14.6715 14.9999C14.6715 14.4499 15.1215 13.9999 15.6715 13.9999C16.2215 13.9999 16.6715 14.4499 16.6715 14.9999C16.6715 15.5499 16.2315 15.9999 15.6815 15.9999Z"
      fill="white"
    />
  </svg>
);
