export const LockCircle = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0156 7.75C10.1256 7.75 9.76562 8.54 9.76562 10V10.62H14.2656V10C14.2656 8.54 13.9056 7.75 12.0156 7.75Z"
      fill="white"
    />
    <path
      d="M12.016 15.0999C12.6235 15.0999 13.116 14.6074 13.116 13.9999C13.116 13.3924 12.6235 12.8999 12.016 12.8999C11.4085 12.8999 10.916 13.3924 10.916 13.9999C10.916 14.6074 11.4085 15.0999 12.016 15.0999Z"
      fill="white"
    />
    <path
      d="M12.0156 2C6.49563 2 2.01562 6.48 2.01562 12C2.01562 17.52 6.49563 22 12.0156 22C17.5356 22 22.0156 17.52 22.0156 12C22.0156 6.48 17.5356 2 12.0156 2ZM17.3956 14.5C17.3956 16.7 16.7156 17.38 14.5156 17.38H9.51562C7.31563 17.38 6.63562 16.7 6.63562 14.5V13.5C6.63562 11.79 7.04562 11 8.26562 10.73V10C8.26562 9.07 8.26562 6.25 12.0156 6.25C15.7656 6.25 15.7656 9.07 15.7656 10V10.73C16.9856 11 17.3956 11.79 17.3956 13.5V14.5Z"
      fill="white"
    />
  </svg>
);
