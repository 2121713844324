export const ChatFeature = ({ className }: { className?: string }) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M52.0003 18.7188V41.5807H47.3935C47.0987 41.5807 46.816 41.6977 46.6076 41.9062C46.3991 42.1146 46.282 42.3973 46.282 42.6921V50.3331C46.2811 50.5522 46.2154 50.7662 46.0932 50.9481C45.9711 51.13 45.7978 51.2718 45.5953 51.3555C45.3928 51.4392 45.1701 51.4612 44.9551 51.4187C44.7401 51.3762 44.5425 51.2711 44.3871 51.1166L35.179 41.9085C35.0756 41.8045 34.9526 41.722 34.8172 41.6657C34.6817 41.6094 34.5365 41.5805 34.3899 41.5807H23.4202L28.8105 36.1903C28.9144 36.0873 29.0376 36.0058 29.173 35.9504C29.3084 35.8951 29.4534 35.8671 29.5997 35.868H45.1595C45.4543 35.868 45.737 35.7509 45.9454 35.5424C46.1538 35.334 46.2709 35.0513 46.2709 34.7565V18.7188H52.0003Z"
      fill="#D9D9D9"
    />
    <path
      d="M17.7016 18.7182H40.569M17.7016 24.4365H40.569M17.7016 30.1548H40.569M46.2817 14.1114V34.756C46.2817 35.0508 46.1646 35.3335 45.9562 35.5419C45.7478 35.7504 45.4651 35.8675 45.1703 35.8675H29.6104C29.4642 35.8666 29.3192 35.8946 29.1838 35.9499C29.0483 36.0053 28.9252 36.0868 28.8213 36.1898L19.6132 45.3979C19.4581 45.5543 19.26 45.661 19.0441 45.7046C18.8282 45.7482 18.6041 45.7267 18.4005 45.6427C18.1968 45.5588 18.0227 45.4162 17.9003 45.2331C17.7778 45.05 17.7125 44.8346 17.7127 44.6143V36.9789C17.7127 36.6841 17.5956 36.4014 17.3872 36.193C17.1787 35.9845 16.8961 35.8675 16.6013 35.8675H13.1114C12.8167 35.8675 12.534 35.7504 12.3255 35.5419C12.1171 35.3335 12 35.0508 12 34.756V14.1114C12 13.8167 12.1171 13.534 12.3255 13.3255C12.534 13.1171 12.8167 13 13.1114 13H45.1814C45.4762 13 45.7589 13.1171 45.9673 13.3255C46.1757 13.534 46.2929 13.8167 46.2929 14.1114H46.2817ZM52 18.7182V41.5801H47.3931C47.0984 41.5801 46.8157 41.6972 46.6073 41.9057C46.3988 42.1141 46.2817 42.3968 46.2817 42.6916V50.3326C46.2808 50.5517 46.2151 50.7657 46.0929 50.9476C45.9708 51.1295 45.7975 51.2713 45.595 51.355C45.3925 51.4387 45.1698 51.4607 44.9548 51.4182C44.7398 51.3757 44.5422 51.2706 44.3868 51.1161L35.1787 41.908C35.0753 41.804 34.9523 41.7215 34.8169 41.6652C34.6814 41.6089 34.5362 41.58 34.3896 41.5801H23.4199L28.8102 36.1898C28.9141 36.0868 29.0372 36.0053 29.1727 35.9499C29.3081 35.8946 29.4531 35.8666 29.5993 35.8675H45.1592C45.454 35.8675 45.7367 35.7504 45.9451 35.5419C46.1535 35.3335 46.2706 35.0508 46.2706 34.756V18.7182H52Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
