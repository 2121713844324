export const Card = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0ZM18 7H2V4H18V7Z"
      fill="currentColor"
    />
  </svg>
);

interface CardWithFieldsProps {
  width?: string;
  height?: string;
  fill?: string;
}

export const CardWithFields = ({
  fill = '#EF9021',
  width = '46',
  height = '30',
}: CardWithFieldsProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 46 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.811 0.574219H4.189C2.15162 0.574219 0.5 2.22584 0.5 4.26322V25.7367C0.5 27.7741 2.15162 29.4257 4.189 29.4257H41.811C43.8484 29.4257 45.5 27.7741 45.5 25.7367V4.26322C45.5 2.22584 43.8484 0.574219 41.811 0.574219Z"
      fill={fill}
    />
    <path
      d="M37.9895 6.79297H32.2545C31.5725 6.79297 31.0195 7.3459 31.0195 8.02797V11.6835C31.0195 12.3655 31.5725 12.9185 32.2545 12.9185H37.9895C38.6716 12.9185 39.2245 12.3655 39.2245 11.6835V8.02797C39.2245 7.3459 38.6716 6.79297 37.9895 6.79297Z"
      fill="white"
    />
    <path
      d="M10.2798 20.6797H5.53626C5.02705 20.6797 4.61426 21.0925 4.61426 21.6017V23.8282C4.61426 24.3374 5.02705 24.7502 5.53626 24.7502H10.2798C10.789 24.7502 11.2018 24.3374 11.2018 23.8282V21.6017C11.2018 21.0925 10.789 20.6797 10.2798 20.6797Z"
      fill="white"
    />
    <path
      d="M20.3413 20.6797H15.5978C15.0886 20.6797 14.6758 21.0925 14.6758 21.6017V23.8282C14.6758 24.3374 15.0886 24.7502 15.5978 24.7502H20.3413C20.8505 24.7502 21.2633 24.3374 21.2633 23.8282V21.6017C21.2633 21.0925 20.8505 20.6797 20.3413 20.6797Z"
      fill="white"
    />
    <path
      d="M30.4028 20.6797H25.6593C25.1501 20.6797 24.7373 21.0925 24.7373 21.6017V23.8282C24.7373 24.3374 25.1501 24.7502 25.6593 24.7502H30.4028C30.912 24.7502 31.3248 24.3374 31.3248 23.8282V21.6017C31.3248 21.0925 30.912 20.6797 30.4028 20.6797Z"
      fill="white"
    />
    <path
      d="M40.4643 20.6797H35.7208C35.2116 20.6797 34.7988 21.0925 34.7988 21.6017V23.8282C34.7988 24.3374 35.2116 24.7502 35.7208 24.7502H40.4643C40.9735 24.7502 41.3863 24.3374 41.3863 23.8282V21.6017C41.3863 21.0925 40.9735 20.6797 40.4643 20.6797Z"
      fill="white"
    />
    <path
      d="M11.5713 9.75977H4.61426C4.38219 9.75977 4.15963 9.66758 3.99554 9.50348C3.83144 9.33939 3.73926 9.11683 3.73926 8.88477C3.73926 8.6527 3.83144 8.43014 3.99554 8.26605C4.15963 8.10195 4.38219 8.00977 4.61426 8.00977H11.5713C11.8033 8.00977 12.0259 8.10195 12.19 8.26605C12.3541 8.43014 12.4463 8.6527 12.4463 8.88477C12.4463 9.11683 12.3541 9.33939 12.19 9.50348C12.0259 9.66758 11.8033 9.75977 11.5713 9.75977Z"
      fill="white"
    />
    <path
      d="M15.0698 13.793H4.61426C4.38219 13.793 4.15963 13.7008 3.99554 13.5367C3.83144 13.3726 3.73926 13.15 3.73926 12.918C3.73926 12.6859 3.83144 12.4633 3.99554 12.2993C4.15963 12.1352 4.38219 12.043 4.61426 12.043H15.0698C15.3018 12.043 15.5244 12.1352 15.6885 12.2993C15.8526 12.4633 15.9448 12.6859 15.9448 12.918C15.9448 13.15 15.8526 13.3726 15.6885 13.5367C15.5244 13.7008 15.3018 13.793 15.0698 13.793Z"
      fill="white"
    />
  </svg>
);
