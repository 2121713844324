import React from 'react';

const PrintALabel = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.96938 12.9694C3.82899 12.8288 3.75009 12.6383 3.75 12.4397V3.75H12.4397C12.6383 3.75009 12.8288 3.82899 12.9694 3.96938L22.2806 13.2806C22.4212 13.4213 22.5001 13.612 22.5001 13.8108C22.5001 14.0096 22.4212 14.2003 22.2806 14.3409L14.3438 22.2806C14.2031 22.4212 14.0124 22.5001 13.8136 22.5001C13.6148 22.5001 13.4241 22.4212 13.2834 22.2806L3.96938 12.9694Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.875 8.8125C8.39277 8.8125 8.8125 8.39277 8.8125 7.875C8.8125 7.35723 8.39277 6.9375 7.875 6.9375C7.35723 6.9375 6.9375 7.35723 6.9375 7.875C6.9375 8.39277 7.35723 8.8125 7.875 8.8125Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PrintALabel;
