export const Filter = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.78923 3.75L17.2111 3.75C17.3323 3.75004 17.4509 3.78533 17.5525 3.85159C17.654 3.91784 17.7341 4.01219 17.7829 4.12315C17.8318 4.2341 17.8473 4.35687 17.8276 4.47649C17.8079 4.59611 17.7539 4.70743 17.672 4.79688L12.5392 10.4453C12.4324 10.5597 12.3737 10.7107 12.3752 10.8672V15.2891C12.3761 15.3929 12.3508 15.4954 12.3017 15.5869C12.2525 15.6784 12.1811 15.756 12.0939 15.8125L9.59392 17.4766C9.50014 17.5383 9.39145 17.5737 9.27928 17.5789C9.16711 17.5842 9.05561 17.5591 8.95648 17.5063C8.85736 17.4536 8.77428 17.3751 8.71597 17.2791C8.65767 17.1831 8.62631 17.0732 8.62517 16.9609V10.8672C8.62668 10.7107 8.56793 10.5597 8.46111 10.4453L3.32829 4.79688C3.24646 4.70743 3.19241 4.59611 3.17272 4.47649C3.15304 4.35687 3.16857 4.2341 3.21742 4.12315C3.26627 4.01219 3.34634 3.91784 3.44787 3.85159C3.54939 3.78533 3.668 3.75004 3.78923 3.75V3.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
