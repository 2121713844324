import { BaseIconProps } from './types';

export const ShieldStar = ({ width = 21, height = 20 }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2344 3.125H4.73438C4.40285 3.125 4.08491 3.2567 3.85049 3.49112C3.61607 3.72554 3.48438 4.04348 3.48438 4.375V8.96094C3.48438 15.9453 9.40625 18.2656 10.5938 18.6562C10.8457 18.7503 11.1231 18.7503 11.375 18.6562C12.5625 18.2656 18.4844 15.9453 18.4844 8.96094V4.375C18.4844 4.04348 18.3527 3.72554 18.1183 3.49112C17.8838 3.2567 17.5659 3.125 17.2344 3.125ZM14.1484 10.25L11.9922 10.9531L13.3281 12.7891C13.3764 12.855 13.411 12.9299 13.43 13.0094C13.4491 13.0889 13.4522 13.1714 13.4391 13.252C13.426 13.3327 13.397 13.41 13.3538 13.4794C13.3106 13.5488 13.2541 13.6089 13.1875 13.6562C13.0825 13.7378 12.9533 13.7819 12.8203 13.7812C12.7215 13.7803 12.6243 13.7566 12.5362 13.7119C12.4481 13.6672 12.3715 13.6027 12.3125 13.5234L10.9844 11.6875L9.65625 13.5234C9.59722 13.6027 9.52066 13.6672 9.43257 13.7119C9.34447 13.7566 9.24723 13.7803 9.14844 13.7812C9.0155 13.7819 8.8862 13.7378 8.78125 13.6562C8.71464 13.6089 8.65811 13.5488 8.61492 13.4794C8.57173 13.41 8.54276 13.3327 8.52968 13.252C8.51659 13.1714 8.51966 13.0889 8.53871 13.0094C8.55776 12.9299 8.5924 12.855 8.64062 12.7891L9.97656 10.9531L7.82031 10.25C7.66321 10.1997 7.53234 10.0894 7.45624 9.94307C7.38014 9.79672 7.36498 9.62623 7.41406 9.46875C7.466 9.31106 7.57783 9.18011 7.72544 9.10411C7.87304 9.02812 8.0446 9.01317 8.20312 9.0625L10.3594 9.76562V7.5C10.3594 7.33424 10.4252 7.17527 10.5424 7.05806C10.6596 6.94085 10.8186 6.875 10.9844 6.875C11.1501 6.875 11.3091 6.94085 11.4263 7.05806C11.5435 7.17527 11.6094 7.33424 11.6094 7.5V9.76562L13.7656 9.0625C13.9241 9.01317 14.0957 9.02812 14.2433 9.10411C14.3909 9.18011 14.5027 9.31106 14.5547 9.46875C14.6038 9.62623 14.5886 9.79672 14.5125 9.94307C14.4364 10.0894 14.3055 10.1997 14.1484 10.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ShieldCheck = ({ fill = '#C41719' }: { fill?: string }) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.95 10.6999L5.575 9.2999C5.375 9.0999 5.13767 8.9999 4.863 8.9999C4.58833 8.9999 4.35067 9.0999 4.15 9.2999C3.95 9.4999 3.84567 9.73757 3.837 10.0129C3.82833 10.2882 3.92433 10.5256 4.125 10.7249L6.25 12.8499C6.45 13.0499 6.68333 13.1499 6.95 13.1499C7.21667 13.1499 7.45 13.0499 7.65 12.8499L11.9 8.5999C12.1 8.3999 12.2 8.16257 12.2 7.8879C12.2 7.61323 12.1 7.37557 11.9 7.1749C11.7 6.9749 11.4623 6.8749 11.187 6.8749C10.9117 6.8749 10.6743 6.9749 10.475 7.1749L6.95 10.6999ZM8 19.9249H7.75C7.66667 19.9249 7.59167 19.9082 7.525 19.8749C5.34167 19.1916 3.54167 17.8372 2.125 15.8119C0.708333 13.7866 0 11.5492 0 9.0999V4.3749C0 3.95824 0.121 3.58324 0.363 3.2499C0.605 2.91657 0.917333 2.6749 1.3 2.5249L7.3 0.274902C7.53333 0.191569 7.76667 0.149902 8 0.149902C8.23333 0.149902 8.46667 0.191569 8.7 0.274902L14.7 2.5249C15.0833 2.6749 15.396 2.91657 15.638 3.2499C15.88 3.58324 16.0007 3.95824 16 4.3749V9.0999C16 11.5499 15.2917 13.7876 13.875 15.8129C12.4583 17.8382 10.6583 19.1922 8.475 19.8749C8.39167 19.9082 8.23333 19.9249 8 19.9249Z"
      fill={fill}
    />
  </svg>
);
