import { BaseIconProps } from './types';

export const LogoWithoutText = ({
  fill = 'black',
  className,
}: BaseIconProps & {
  className?: string;
}) => (
  <svg
    viewBox="0 0 49 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={!className ? 33 : undefined}
    width={!className ? 49 : undefined}
    className={className}
  >
    <path
      d="M6.68922 0.240234L0.120026 23.1893L5.71677 23.211L12.1132 0.240234H6.68922Z"
      fill={fill}
    />
    <path
      d="M21.2082 0.26184L14.855 7.24165L12.6508 0.240234L10.209 8.45178L12.0673 16.6417L18.8527 8.47339L21.2082 0.26184Z"
      fill={fill}
    />
    <path
      d="M20.6679 23.2521L27.1938 0.281371L21.5754 0.259766L15.071 23.2305L20.6679 23.2521Z"
      fill={fill}
    />
    <path
      d="M33.1202 23.2755L39.4733 23.2971L36.6209 11.0879L45.524 0.326423H40.1865L35.4107 6.18256L34.071 0.304817L27.7611 0.283203L30.4406 11.7794L13.2612 32.1786L18.6851 32.2002L31.6076 16.9656L33.1202 23.2755Z"
      fill={fill}
    />
    <path
      d="M26.8048 24.5723H25.854L24.363 29.8666H27.7989L27.9933 29.0886L25.53 29.0454L26.8048 24.5723Z"
      fill={fill}
    />
    <path
      d="M31.0416 28.6366L30.6095 29.004H29.3993L29.14 28.6799L29.9395 25.7842L30.3716 25.4601H31.5386L31.8411 25.7842L31.0416 28.6366ZM32.2302 24.5957H30.2204L29.1183 25.3952L28.1244 29.0472L28.7941 29.9332H30.7174L31.8628 29.0256L32.8783 25.4385L32.2302 24.5957Z"
      fill={fill}
    />
    <path
      d="M35.1675 27.9235L34.9731 28.7231L34.5625 29.0256H33.4604L33.2011 28.615L34.0007 25.8707L34.4544 25.4817H35.5781L35.7727 25.849L35.6214 26.4973H36.5722L36.8531 25.3736L36.2264 24.5957H34.3247L33.1362 25.5033L32.1423 28.9608L32.8769 29.9332H34.7138L35.8374 29.0472L36.1183 27.9452L35.1675 27.9235Z"
      fill={fill}
    />
    <path
      d="M41.267 24.6152H40.2514L37.8312 26.7329L38.4145 24.6152H37.4637L35.951 29.9311L36.9451 29.9527L37.4854 28.0943L38.3714 27.3164L38.8684 29.9527H39.8192L39.1493 26.5601L41.267 24.6152Z"
      fill={fill}
    />
    <path
      d="M41.7396 27.5325H43.5114L43.6844 26.7978H41.9124L42.2582 25.5012H44.8297L45.0674 24.6368L41.6314 24.6152L40.0973 29.9527H43.5763L43.814 29.0668H41.3074L41.7396 27.5325Z"
      fill={fill}
    />
    <path
      d="M45.7133 26.927L46.1021 25.544H47.4851L47.7012 25.8033L47.5284 26.6245L47.1393 26.9703L45.7133 26.927ZM48.3712 26.927L48.76 25.4144L48.1981 24.6797H45.454L43.9195 30.0172H44.8705L45.4756 27.8562H46.1237L46.4695 30.0172H47.4419L47.053 27.8562H47.3123L48.3712 26.927Z"
      fill={fill}
    />
  </svg>
);
