export const Like = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.9584 2.04258C9.5334 1.55091 10.4751 1.55091 11.0584 2.04258L12.3751 3.176C12.6251 3.39267 13.0917 3.56764 13.4251 3.56764H14.8417C15.7251 3.56764 16.4501 4.29259 16.4501 5.17592V6.59265C16.4501 6.91765 16.6251 7.39265 16.8417 7.64265L17.9751 8.95928C18.4667 9.53428 18.4667 10.476 17.9751 11.0593L16.8417 12.3759C16.6251 12.6259 16.4501 13.0926 16.4501 13.4259V14.8427C16.4501 15.726 15.7251 16.4509 14.8417 16.4509H13.4251C13.1001 16.4509 12.6251 16.6259 12.3751 16.8426L11.0584 17.9759C10.4834 18.4676 9.54173 18.4676 8.9584 17.9759L7.64174 16.8426C7.39174 16.6259 6.92507 16.4509 6.59173 16.4509H5.15006C4.26672 16.4509 3.54173 15.726 3.54173 14.8427V13.4176C3.54173 13.0926 3.36674 12.6259 3.1584 12.3759L2.0334 11.051C1.55007 10.476 1.55007 9.54263 2.0334 8.96763L3.1584 7.64265C3.36674 7.39265 3.54173 6.92599 3.54173 6.60099V5.17592C3.54173 4.29259 4.26672 3.56764 5.15006 3.56764H6.59173C6.91673 3.56764 7.39174 3.39267 7.64174 3.176L8.9584 2.04258Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.24178 12.3497L9.4501 13.283C9.60843 13.4413 9.95844 13.5163 10.1918 13.5163H11.6668C12.1334 13.5163 12.6418 13.1664 12.7584 12.6997L13.6918 9.84966C13.8834 9.30799 13.5334 8.83302 12.9501 8.83302H11.3918C11.1584 8.83302 10.9668 8.64141 11.0001 8.36641L11.1918 7.11641C11.2668 6.76641 11.0334 6.37471 10.6834 6.25805C10.3751 6.14138 9.98344 6.29968 9.82511 6.53301L8.2251 8.90799"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M6.25 12.3493V8.53269C6.25 7.98269 6.48334 7.79102 7.03334 7.79102H7.425C7.96667 7.79102 8.20834 7.98269 8.20834 8.53269V12.3493C8.20834 12.891 7.975 13.091 7.425 13.091H7.03334C6.48334 13.091 6.25 12.8993 6.25 12.3493Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LikeShapes = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M35.8336 8.16641C38.1336 6.19974 41.9002 6.19974 44.2336 8.16641L49.5003 12.6997C50.5003 13.5664 52.3669 14.2665 53.7002 14.2665H59.3669C62.9002 14.2665 65.8002 17.1664 65.8002 20.6998V26.3663C65.8002 27.6663 66.5002 29.5663 67.3669 30.5663L71.9003 35.833C73.8669 38.133 73.8669 41.8998 71.9003 44.2331L67.3669 49.4996C66.5002 50.4996 65.8002 52.3663 65.8002 53.6997V59.3664C65.8002 62.8997 62.9002 65.7997 59.3669 65.7997H53.7002C52.4002 65.7997 50.5003 66.4998 49.5003 67.3664L44.2336 71.8997C41.9335 73.8664 38.1669 73.8664 35.8336 71.8997L30.567 67.3664C29.567 66.4998 27.7003 65.7997 26.3669 65.7997H20.6002C17.0669 65.7997 14.1669 62.8997 14.1669 59.3664V53.6663C14.1669 52.3663 13.4669 50.4996 12.6336 49.4996L8.13359 44.1997C6.20026 41.8997 6.20026 38.1664 8.13359 35.8664L12.6336 30.5663C13.4669 29.5663 14.1669 27.6996 14.1669 26.3996V20.6998C14.1669 17.1664 17.0669 14.2665 20.6002 14.2665H26.3669C27.6669 14.2665 29.567 13.5664 30.567 12.6997L35.8336 8.16641Z"
      fill="#D9D9D9"
    />
    <path
      d="M55.2662 36.9004C54.6329 36.0004 53.5662 35.5005 52.2996 35.5005H45.7995C45.3662 35.5005 44.9663 35.3337 44.6996 35.0004C44.4329 34.6671 44.2996 34.2338 44.3662 33.7671L45.1662 28.5671C45.4995 27.0338 44.4663 25.2671 42.9329 24.7671C41.4996 24.2337 39.7995 24.967 39.1329 25.967L32.6662 35.567V34.3671C32.6662 32.0337 31.6663 31.0671 29.1996 31.0671H27.5662C25.0995 31.0671 24.0996 32.0337 24.0996 34.3671V50.3005C24.0996 52.6338 25.0995 53.6005 27.5662 53.6005H29.1996C31.5329 53.6005 32.5329 52.7004 32.6329 50.567L37.5329 54.3337C38.1996 55.0004 39.6996 55.367 40.7663 55.367H46.9329C49.0662 55.367 51.1996 53.767 51.6662 51.8003L55.5663 39.9337C55.9996 38.867 55.8995 37.7671 55.2662 36.9004Z"
      fill="#D9D9D9"
    />
  </svg>
);
