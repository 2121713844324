export const SMS = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.23281 20.3228C4.12357 20.4147 3.99038 20.4735 3.84887 20.4923C3.70737 20.5112 3.56343 20.4892 3.43397 20.429C3.30451 20.3689 3.1949 20.273 3.11803 20.1528C3.04116 20.0325 3.00021 19.8928 3 19.75V4.75C3 4.55109 3.07902 4.36032 3.21967 4.21967C3.36032 4.07902 3.55109 4 3.75 4H20.25C20.4489 4 20.6397 4.07902 20.7803 4.21967C20.921 4.36032 21 4.55109 21 4.75V16.75C21 16.9489 20.921 17.1397 20.7803 17.2803C20.6397 17.421 20.4489 17.5 20.25 17.5H7.73438C7.5577 17.5 7.38672 17.5625 7.25156 17.6762L4.23281 20.3228Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12 11.6875C12.5178 11.6875 12.9375 11.2678 12.9375 10.75C12.9375 10.2322 12.5178 9.8125 12 9.8125C11.4822 9.8125 11.0625 10.2322 11.0625 10.75C11.0625 11.2678 11.4822 11.6875 12 11.6875Z"
      fill="currentColor"
    />
    <path
      d="M7.875 11.6875C8.39277 11.6875 8.8125 11.2678 8.8125 10.75C8.8125 10.2322 8.39277 9.8125 7.875 9.8125C7.35723 9.8125 6.9375 10.2322 6.9375 10.75C6.9375 11.2678 7.35723 11.6875 7.875 11.6875Z"
      fill="currentColor"
    />
    <path
      d="M16.125 11.6875C16.6428 11.6875 17.0625 11.2678 17.0625 10.75C17.0625 10.2322 16.6428 9.8125 16.125 9.8125C15.6072 9.8125 15.1875 10.2322 15.1875 10.75C15.1875 11.2678 15.6072 11.6875 16.125 11.6875Z"
      fill="currentColor"
    />
  </svg>
);
