export const Garage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M1.5 18.75H22.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M21 18.7514V9.25174C21.0002 9.12807 20.9699 9.00627 20.9117 8.89717C20.8534 8.78807 20.7691 8.69506 20.6663 8.62642L12.4163 3.12611C12.293 3.04388 12.1482 3 12 3C11.8518 3 11.707 3.04388 11.5838 3.12611L3.33375 8.62642C3.23088 8.69506 3.14658 8.78807 3.08835 8.89717C3.03012 9.00627 2.99977 9.12807 3 9.25174V18.7514"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M6.75 18.75V12.75H17.25V18.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
