export const Hand = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width ?? 24}
    height={height ?? 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 5.625C12 5.12772 12.1975 4.65081 12.5492 4.29917C12.9008 3.94754 13.3777 3.75 13.875 3.75C14.3723 3.75 14.8492 3.94754 15.2008 4.29917C15.5525 4.65081 15.75 5.12772 15.75 5.625V10.875"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M8.25 7.125V4.125C8.25 3.62772 8.44754 3.15081 8.79917 2.79917C9.15081 2.44754 9.62772 2.25 10.125 2.25C10.6223 2.25 11.0992 2.44754 11.4508 2.79917C11.8025 3.15081 12 3.62772 12 4.125V10.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M12 16.5C12 15.5054 12.3951 14.5516 13.0983 13.8483C13.8016 13.1451 14.7554 12.75 15.75 12.75V10.875C15.75 10.3777 15.9475 9.90081 16.2992 9.54917C16.6508 9.19754 17.1277 9 17.625 9C18.1223 9 18.5992 9.19754 18.9508 9.54917C19.3025 9.90081 19.5 10.3777 19.5 10.875V14.25C19.5 16.2391 18.7098 18.1468 17.3033 19.5533C15.8968 20.9598 13.9891 21.75 12 21.75C10.0109 21.75 8.10322 20.9598 6.6967 19.5533C5.29018 18.1468 4.5 16.2391 4.5 14.25V7.125C4.5 6.62772 4.69754 6.15081 5.04917 5.79917C5.40081 5.44754 5.87772 5.25 6.375 5.25C6.87228 5.25 7.34919 5.44754 7.70083 5.79917C8.05246 6.15081 8.25 6.62772 8.25 7.125V11.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
