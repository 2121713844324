export const GlobeSimple = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      stroke="#808080"
      strokeMiterlimit="10"
    />
    <path
      d="M2 8H14"
      stroke="#808080"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 13.8359C9.38071 13.8359 10.5 11.2224 10.5 7.99839C10.5 4.77443 9.38071 2.16089 8 2.16089C6.61929 2.16089 5.5 4.77443 5.5 7.99839C5.5 11.2224 6.61929 13.8359 8 13.8359Z"
      stroke="#808080"
      strokeMiterlimit="10"
    />
  </svg>
);
