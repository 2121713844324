export function Phone({
  width = 16,
  height = 17,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.89325 8.75015C5.53639 10.0783 6.61034 11.1495 7.94012 11.7892C8.03821 11.8357 8.14671 11.8558 8.25493 11.8475C8.36315 11.8393 8.46736 11.8031 8.55731 11.7423L10.5104 10.4376C10.5967 10.3791 10.6964 10.3434 10.8003 10.3338C10.9041 10.3243 11.0087 10.3412 11.1042 10.383L14.7604 11.9533C14.8854 12.0053 14.9898 12.0969 15.0576 12.2141C15.1253 12.3313 15.1527 12.4675 15.1354 12.6017C15.0196 13.5062 14.5781 14.3375 13.8936 14.94C13.2091 15.5425 12.3286 15.8749 11.4167 15.8751C8.59876 15.8751 5.89625 14.7557 3.90368 12.7632C1.9111 10.7706 0.791687 8.06807 0.791687 5.25015C0.791893 4.33827 1.12435 3.45771 1.72686 2.77324C2.32937 2.08877 3.16064 1.64728 4.06512 1.5314C4.19937 1.51415 4.33557 1.54152 4.45274 1.60928C4.56991 1.67704 4.66155 1.78144 4.71356 1.9064L6.28387 5.57046C6.32471 5.66448 6.34179 5.7671 6.33362 5.86928C6.32544 5.97146 6.29226 6.07006 6.237 6.1564L4.93231 8.14077C4.87423 8.23052 4.84014 8.33368 4.8333 8.44037C4.82646 8.54705 4.8471 8.65371 4.89325 8.75015V8.75015Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
