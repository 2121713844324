export const ChattingApp = () => (
  <svg
    width="52"
    height="51"
    viewBox="0 0 52 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.0006 8.39746V36.9748H45.242C44.8735 36.9748 44.5202 37.1212 44.2597 37.3817C43.9991 37.6423 43.8527 37.9957 43.8527 38.3641V47.9154C43.8516 48.1893 43.7695 48.4568 43.6167 48.6842C43.464 48.9116 43.2475 49.0887 42.9944 49.1934C42.7412 49.2981 42.4628 49.3255 42.1941 49.2724C41.9253 49.2193 41.6783 49.0879 41.484 48.8948L29.9739 37.3847C29.8446 37.2547 29.6909 37.1515 29.5216 37.0812C29.3523 37.0108 29.1708 36.9747 28.9875 36.9748H15.2754L22.0133 30.2369C22.1431 30.1081 22.2971 30.0062 22.4664 29.9371C22.6357 29.8679 22.8169 29.8329 22.9998 29.834H42.4496C42.818 29.834 43.1714 29.6876 43.4319 29.4271C43.6925 29.1665 43.8389 28.8132 43.8389 28.4447V8.39746H51.0006Z"
      fill="#C41719"
    />
    <path
      d="M8.12698 8.39781H36.7113M8.12698 15.5456H36.7113M8.12698 22.6935H36.7113M43.8522 2.63927V28.445C43.8522 28.8135 43.7058 29.1669 43.4453 29.4274C43.1847 29.688 42.8313 29.8343 42.4629 29.8343H23.013C22.8302 29.8333 22.649 29.8683 22.4797 29.9374C22.3104 30.0066 22.1565 30.1084 22.0267 30.2372L10.5165 41.7473C10.3226 41.9428 10.075 42.0763 9.80511 42.1308C9.5352 42.1853 9.25519 42.1584 9.00062 42.0534C8.74605 41.9485 8.52842 41.7702 8.37535 41.5413C8.22227 41.3125 8.14067 41.0433 8.14089 40.7679V31.2236C8.14089 30.8551 7.9945 30.5018 7.73396 30.2412C7.47342 29.9807 7.12007 29.8343 6.75161 29.8343H2.38927C2.02082 29.8343 1.66747 29.688 1.40693 29.4274C1.14639 29.1669 1 28.8135 1 28.445V2.63927C1 2.27082 1.14639 1.91745 1.40693 1.65691C1.66747 1.39637 2.02082 1.25 2.38927 1.25H42.4768C42.8452 1.25 43.1986 1.39637 43.4591 1.65691C43.7197 1.91745 43.8661 2.27082 43.8661 2.63927H43.8522ZM51 8.39781V36.9752H45.2414C44.873 36.9752 44.5196 37.1216 44.2591 37.3821C43.9985 37.6426 43.8522 37.996 43.8522 38.3645V47.9157C43.851 48.1896 43.7689 48.4571 43.6162 48.6845C43.4634 48.9119 43.2469 49.0891 42.9938 49.1937C42.7406 49.2984 42.4622 49.3259 42.1935 49.2728C41.9248 49.2197 41.6777 49.0883 41.4835 48.8952L29.9733 37.385C29.8441 37.255 29.6904 37.1519 29.5211 37.0815C29.3518 37.0112 29.1703 36.975 28.987 36.9752H15.2748L22.0128 30.2372C22.1426 30.1084 22.2966 30.0066 22.4658 29.9374C22.6351 29.8683 22.8163 29.8333 22.9992 29.8343H42.449C42.8175 29.8343 43.1708 29.688 43.4314 29.4274C43.6919 29.1669 43.8383 28.8135 43.8383 28.445V8.39781H51Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
