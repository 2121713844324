export const Wishlist = () => (
  <svg
    width="81"
    height="80"
    viewBox="0 0 81 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.75 70C58.8211 70 60.5 68.3211 60.5 66.25C60.5 64.1789 58.8211 62.5 56.75 62.5C54.6789 62.5 53 64.1789 53 66.25C53 68.3211 54.6789 70 56.75 70Z"
      fill="#D9D9D9"
    />
    <path
      d="M63.6275 56.25H26.1275C25.0925 56.25 24.2525 55.41 24.2525 54.375C24.2525 53.34 25.0925 52.5 26.1275 52.5H29.875H63.625C64.51 52.5 65.2775 51.8775 65.46 51.01L70.46 27.26C70.575 26.7075 70.4375 26.1325 70.0825 25.6925C69.725 25.255 69.1925 25 68.625 25H26.3975L25.4475 20.485C24.7825 17.3375 21.9675 15.0425 18.75 15.0275L12.385 15H12.375C11.345 15 10.505 15.835 10.5 16.8675C10.4975 17.9025 11.3325 18.745 12.3675 18.75L18.7325 18.7775C20.195 18.7825 21.475 19.8275 21.7775 21.2575L23.04 27.2575L27.565 48.7475H26.125C23.0225 48.7475 20.5 51.27 20.5 54.3725C20.5 57.475 23.0225 59.9975 26.125 59.9975H63.625C64.66 59.9975 65.5 59.1575 65.5 58.1225C65.5 57.09 64.6625 56.25 63.6275 56.25Z"
      fill="#D9D9D9"
    />
    <path
      d="M36.75 62.5C34.68 62.5 33 64.1775 33 66.25C33 68.3225 34.6775 70 36.75 70C38.8225 70 40.5 68.3225 40.5 66.25C40.5 64.1775 38.8225 62.5 36.75 62.5Z"
      fill="#D9D9D9"
    />
    <path
      d="M53.1325 39.63C51.5825 38.4775 38 28.125 38 19.345C38 14.1925 42.2075 10 47.375 10C50.035 10 52.51 11.115 54.25 12.985C55.99 11.115 58.465 10 61.125 10C66.2925 10 70.5 14.1925 70.5 19.345C70.5 28.125 56.9175 38.4775 55.3675 39.63C54.705 40.1225 53.795 40.1225 53.1325 39.63Z"
      fill="#808080"
    />
  </svg>
);
