export const Warning = ({ fill, className }: { fill?: string, className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M18.4914 14.6876L11.6242 2.81263C11.4598 2.52703 11.223 2.2898 10.9377 2.12485C10.6524 1.9599 10.3287 1.87305 9.99917 1.87305C9.66963 1.87305 9.34591 1.9599 9.06063 2.12485C8.77535 2.2898 8.53858 2.52703 8.37417 2.81263L1.50699 14.6876C1.34016 14.9718 1.25151 15.295 1.25002 15.6245C1.24853 15.954 1.33426 16.278 1.49851 16.5637C1.66276 16.8493 1.89967 17.0864 2.18519 17.2509C2.47071 17.4154 2.79467 17.5014 3.12417 17.5001H16.8742C17.2037 17.5014 17.5276 17.4154 17.8132 17.2509C18.0987 17.0864 18.3356 16.8493 18.4998 16.5637C18.6641 16.278 18.7498 15.954 18.7483 15.6245C18.7468 15.295 18.6582 14.9718 18.4914 14.6876ZM9.37417 8.12513C9.37417 7.95937 9.44002 7.8004 9.55723 7.68319C9.67444 7.56598 9.83341 7.50013 9.99917 7.50013C10.1649 7.50013 10.3239 7.56598 10.4411 7.68319C10.5583 7.8004 10.6242 7.95937 10.6242 8.12513V11.2501C10.6242 11.4159 10.5583 11.5749 10.4411 11.6921C10.3239 11.8093 10.1649 11.8751 9.99917 11.8751C9.83341 11.8751 9.67444 11.8093 9.55723 11.6921C9.44002 11.5749 9.37417 11.4159 9.37417 11.2501V8.12513ZM9.99917 15.0001C9.81375 15.0001 9.6325 14.9451 9.47833 14.8421C9.32416 14.7391 9.20399 14.5927 9.13304 14.4214C9.06208 14.2501 9.04351 14.0616 9.07969 13.8797C9.11586 13.6979 9.20515 13.5308 9.33626 13.3997C9.46737 13.2686 9.63442 13.1793 9.81628 13.1431C9.99813 13.107 10.1866 13.1255 10.3579 13.1965C10.5292 13.2675 10.6757 13.3876 10.7787 13.5418C10.8817 13.696 10.9367 13.8772 10.9367 14.0626C10.9367 14.3113 10.8379 14.5497 10.6621 14.7255C10.4863 14.9014 10.2478 15.0001 9.99917 15.0001Z"
      fill={fill ?? '#FF4242'}
    />
  </svg>
);
