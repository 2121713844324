import { BaseIconProps } from './types';

const AppleBrand = ({ height = 25, width = 24 }: BaseIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none">
      <path
        d="M16.6984 6.41553C14.8084 6.41553 14.0096 7.3174 12.6934 7.3174C11.3439 7.3174 10.3145 6.42209 8.67667 6.42209C7.07354 6.42209 5.36401 7.40084 4.27839 9.06818C2.75401 11.4194 3.01276 15.8477 5.48167 19.6202C6.36479 20.9707 7.54417 22.4852 9.09104 22.5016H9.11917C10.4635 22.5016 10.8629 21.6213 12.7131 21.611H12.7412C14.5637 21.611 14.9293 22.4965 16.2681 22.4965H16.2962C17.8431 22.4801 19.0857 20.8019 19.9689 19.4566C20.6045 18.4891 20.8407 18.0035 21.3282 16.909C17.7568 15.5533 17.1831 10.4904 20.7151 8.54928C19.637 7.19928 18.122 6.4174 16.6937 6.4174L16.6984 6.41553Z"
        fill="black"
      />
      <path
        d="M16.2821 1.5C15.1571 1.57641 13.8446 2.29266 13.0758 3.22781C12.3783 4.07531 11.8046 5.3325 12.0296 6.55172H12.1196C13.3177 6.55172 14.544 5.83031 15.2602 4.90594C15.9502 4.02609 16.4733 2.77922 16.2821 1.5Z"
        fill="black"
      />
    </svg>
  );
};

export default AppleBrand;
