export const Pushpin = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.875 13.125L3.75 16.25"
      stroke="#242E5F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0541 2.31927L7.18692 7.18645C7.18692 7.18645 5.02285 6.10052 2.70254 7.97552C2.63427 8.03077 2.5784 8.09979 2.53859 8.17808C2.49878 8.25637 2.47592 8.34217 2.4715 8.42989C2.46707 8.51761 2.48119 8.60528 2.51292 8.68718C2.54466 8.76908 2.59329 8.84337 2.65567 8.9052L11.0775 17.3271C11.1406 17.3912 11.2167 17.441 11.3008 17.473C11.3848 17.5051 11.4748 17.5186 11.5645 17.5126C11.6543 17.5067 11.7417 17.4815 11.8208 17.4387C11.8999 17.3958 11.9688 17.3365 12.0229 17.2646C12.6791 16.3974 13.7104 14.6005 12.8119 12.8115L17.6791 7.94427C17.7382 7.88611 17.7851 7.81679 17.8171 7.74034C17.8491 7.66389 17.8656 7.58184 17.8656 7.49895C17.8656 7.41607 17.8491 7.33402 17.8171 7.25757C17.7851 7.18112 17.7382 7.1118 17.6791 7.05364L12.9447 2.31927C12.8866 2.26021 12.8173 2.21332 12.7408 2.18131C12.6644 2.1493 12.5823 2.13281 12.4994 2.13281C12.4165 2.13281 12.3345 2.1493 12.258 2.18131C12.1816 2.21332 12.1123 2.26021 12.0541 2.31927Z"
      stroke="#242E5F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
