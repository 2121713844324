export const Support = () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8906 10.0002V17.5002C19.8881 18.494 19.4923 19.4464 18.7895 20.1491C18.0868 20.8519 17.1344 21.2477 16.1406 21.2502H10.75C10.5511 21.2502 10.3603 21.1712 10.2197 21.0306C10.079 20.8899 10 20.6991 10 20.5002C10 20.3013 10.079 20.1105 10.2197 19.9699C10.3603 19.8292 10.5511 19.7502 10.75 19.7502H16.1406C16.7366 19.7478 17.3075 19.5099 17.7289 19.0885C18.1503 18.6671 18.3882 18.0962 18.3906 17.5002V17.369C18.1501 17.4557 17.8963 17.5001 17.6406 17.5002H16.1406C15.5439 17.5002 14.9716 17.2632 14.5496 16.8412C14.1277 16.4193 13.8906 15.847 13.8906 15.2502V11.5002C13.8906 10.9035 14.1277 10.3312 14.5496 9.90923C14.9716 9.48727 15.5439 9.25022 16.1406 9.25022H18.3531C18.1651 7.1898 17.2097 5.27524 15.6765 3.88609C14.1432 2.49694 12.1439 1.73461 10.075 1.75022H10.0656C7.99548 1.73025 5.99383 2.49109 4.45962 3.88109C2.92542 5.27108 1.97132 7.18816 1.7875 9.25022H4C4.59598 9.25269 5.16684 9.49053 5.58826 9.91196C6.00969 10.3334 6.24753 10.9042 6.25 11.5002V15.2502C6.24753 15.8462 6.00969 16.4171 5.58826 16.8385C5.16684 17.2599 4.59598 17.4978 4 17.5002H2.5C1.90402 17.4978 1.33316 17.2599 0.911736 16.8385C0.490313 16.4171 0.252468 15.8462 0.25 15.2502V10.0002C0.249971 8.71429 0.50432 7.44106 0.998402 6.25384C1.49248 5.06662 2.21654 3.98886 3.12887 3.08262C4.04121 2.17639 5.1238 1.45958 6.31431 0.973475C7.50481 0.487368 8.77973 0.241566 10.0656 0.250221H10.1406C12.7257 0.252703 15.2042 1.28073 17.0322 3.10867C18.8601 4.93661 19.8881 7.41512 19.8906 10.0002Z"
      fill="white"
    />
  </svg>
);
