export const SecuritySafe = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9099 11.6203C20.9099 16.5103 17.3599 21.0903 12.5099 22.4303C12.1799 22.5203 11.8198 22.5203 11.4898 22.4303C6.63984 21.0903 3.08984 16.5103 3.08984 11.6203V7.23028C3.08984 6.41028 3.70986 5.48028 4.47986 5.17028L10.0498 2.89031C11.2998 2.38031 12.7098 2.38031 13.9598 2.89031L19.5298 5.17028C20.2898 5.48028 20.9199 6.41028 20.9199 7.23028L20.9099 11.6203Z"
      stroke="#808080"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z"
      stroke="#808080"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13V16"
      stroke="#808080"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
