export const Shop = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5083 9.3501V13.0918C2.5083 16.8334 4.0083 18.3334 7.74997 18.3334H12.2416C15.9833 18.3334 17.4833 16.8334 17.4833 13.0918V9.3501"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0002 10.0001C11.5252 10.0001 12.6502 8.75841 12.5002 7.23341L11.9502 1.66675H8.05857L7.50024 7.23341C7.35024 8.75841 8.47524 10.0001 10.0002 10.0001Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2582 10.0001C16.9415 10.0001 18.1748 8.63341 18.0082 6.95841L17.7748 4.66675C17.4748 2.50008 16.6415 1.66675 14.4582 1.66675H11.9165L12.4998 7.50841C12.6415 8.88341 13.8832 10.0001 15.2582 10.0001Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.70015 10.0001C6.07515 10.0001 7.31681 8.88341 7.45015 7.50841L7.63348 5.66675L8.03348 1.66675H5.49181C3.30848 1.66675 2.47515 2.50008 2.17515 4.66675L1.95015 6.95841C1.78348 8.63341 3.01681 10.0001 4.70015 10.0001Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99984 14.1667C8.60817 14.1667 7.9165 14.8584 7.9165 16.2501V18.3334H12.0832V16.2501C12.0832 14.8584 11.3915 14.1667 9.99984 14.1667Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
