import { BaseIconProps } from './types';

export const Star = ({
  width = 18,
  height = 18,
  fill = '#C41719',
}: BaseIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6877 7.10938C17.6095 6.86327 17.4587 6.64654 17.2552 6.48761C17.0516 6.32868 16.8048 6.23496 16.5471 6.21875L11.9065 5.89844L10.1877 1.5625C10.0939 1.32375 9.9305 1.11867 9.71878 0.973816C9.50707 0.828956 9.25674 0.750987 9.00021 0.75C8.74368 0.750987 8.49335 0.828956 8.28164 0.973816C8.06992 1.11867 7.90656 1.32375 7.81271 1.5625L6.06271 5.92188L1.45334 6.21875C1.19592 6.23601 0.949603 6.33012 0.746253 6.4889C0.542904 6.64768 0.391877 6.86383 0.312711 7.10938C0.231415 7.35869 0.226665 7.62664 0.299074 7.87868C0.371483 8.13072 0.517731 8.35529 0.718961 8.52344L4.26584 11.5234L3.21115 15.6719C3.13818 15.9525 3.15131 16.2486 3.24884 16.5217C3.34637 16.7948 3.52378 17.0322 3.75802 17.2031C3.98539 17.3663 4.25628 17.4579 4.53602 17.4663C4.81577 17.4746 5.09164 17.3993 5.32834 17.25L8.9924 14.9297H9.00802L12.9533 17.4219C13.1557 17.5534 13.3917 17.6239 13.633 17.625C13.8301 17.6235 14.0242 17.5767 14.2004 17.4884C14.3766 17.4002 14.5302 17.2727 14.6494 17.1157C14.7686 16.9588 14.8502 16.7766 14.888 16.5832C14.9258 16.3898 14.9188 16.1903 14.8674 16L13.7502 11.4609L17.2815 8.52344C17.4827 8.35529 17.6289 8.13072 17.7013 7.87868C17.7738 7.62664 17.769 7.35869 17.6877 7.10938Z"
      fill={fill}
    />
  </svg>
);
