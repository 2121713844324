export const Sale = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.70188 12.9386L7.47688 16.7136C9.02688 18.2636 11.5435 18.2636 13.1019 16.7136L16.7602 13.0553C18.3102 11.5053 18.3102 8.98864 16.7602 7.43031L12.9769 3.66364C12.1852 2.87197 11.0935 2.44697 9.97688 2.50531L5.81021 2.70531C4.14355 2.78031 2.81855 4.10531 2.73521 5.76364L2.53521 9.93031C2.48521 11.0553 2.91021 12.147 3.70188 12.9386Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.14388 10.1886C9.29447 10.1886 10.2272 9.2559 10.2272 8.10531C10.2272 6.95471 9.29447 6.02197 8.14388 6.02197C6.99329 6.02197 6.06055 6.95471 6.06055 8.10531C6.06055 9.2559 6.99329 10.1886 8.14388 10.1886Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M11.0605 14.3553L14.3939 11.022"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SaleFeature = ({
  fill = '#D9D9D9',
  width = '80',
  height = '80',
}: {
  fill?: string;
  width?: string;
  height?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66 40.7495L40.5 66.2495C38.0625 68.687 34.0625 68.687 31.625 66.2495L13.6875 48.437C11.25 45.9995 11.25 41.9995 13.6875 39.562L39.1875 14.062C40.5625 12.687 42.5 11.8745 44.4375 11.937L60.625 12.187C64.5625 12.2495 67.8125 15.437 67.8125 19.3745L68.0625 35.562C68.125 37.437 67.375 39.3745 66 40.7495Z"
      fill={fill}
    />
    <path
      d="M59.3125 23.6875C60.9694 23.6875 62.3125 22.3444 62.3125 20.6875C62.3125 19.0306 60.9694 17.6875 59.3125 17.6875C57.6556 17.6875 56.3125 19.0306 56.3125 20.6875C56.3125 22.3444 57.6556 23.6875 59.3125 23.6875Z"
      fill="white"
    />
    <path
      d="M32.875 43.1875C31.6875 43.1875 30.5625 42.75 29.75 41.875C28 40.125 28 37.3125 29.75 35.5625C31.5 33.8125 34.3125 33.8125 36.0625 35.5625C37.8125 37.3125 37.8125 40.125 36.0625 41.875C35.1875 42.6875 34.0625 43.1875 32.875 43.1875ZM32.875 36.4375C32.3125 36.4375 31.75 36.6875 31.25 37.125C30.375 38 30.375 39.4375 31.25 40.3125C32.125 41.1875 33.5625 41.1875 34.4375 40.3125C35.3125 39.4375 35.3125 38 34.4375 37.125C34.0625 36.6875 33.5 36.4375 32.875 36.4375Z"
      fill="white"
    />
    <path
      d="M49.4375 43.1875C48.25 43.1875 47.125 42.75 46.3125 41.875C45.5 41.0625 45 39.9375 45 38.75C45 37.5625 45.4375 36.4375 46.3125 35.625C47.125 34.8125 48.25 34.3125 49.4375 34.3125C50.625 34.3125 51.75 34.75 52.5625 35.625C54.3125 37.375 54.3125 40.1875 52.5625 41.9375C51.75 42.6875 50.625 43.1875 49.4375 43.1875ZM49.4375 36.4375C48.8125 36.4375 48.25 36.6875 47.8125 37.125C47.375 37.5625 47.125 38.125 47.125 38.75C47.125 39.375 47.375 39.9375 47.8125 40.375C48.6875 41.25 50.125 41.25 51 40.375C51.875 39.5 51.875 38.0625 51 37.1875C50.625 36.6875 50.0625 36.4375 49.4375 36.4375Z"
      fill="white"
    />
    <path
      d="M43.5622 50.4381C43.0622 50.4381 42.6247 50.0632 42.4997 49.5632L37.7497 28.2507C37.6247 27.6882 37.9997 27.0631 38.5622 26.9381C39.1247 26.8131 39.7497 27.1882 39.8747 27.7507L44.6247 49.0632C44.7497 49.6257 44.3747 50.2507 43.8122 50.3757C43.6872 50.4382 43.6247 50.4381 43.5622 50.4381Z"
      fill="white"
    />
  </svg>
);
