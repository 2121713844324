export const Location = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9999 11.1922C11.4358 11.1922 12.5999 10.0281 12.5999 8.59219C12.5999 7.15625 11.4358 5.99219 9.9999 5.99219C8.56396 5.99219 7.3999 7.15625 7.3999 8.59219C7.3999 10.0281 8.56396 11.1922 9.9999 11.1922Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M3.01675 7.07435C4.65842 -0.142316 15.3501 -0.133982 16.9834 7.08268C17.9417 11.316 15.3084 14.8994 13.0001 17.116C11.3251 18.7327 8.67508 18.7327 6.99175 17.116C4.69175 14.8994 2.05842 11.3077 3.01675 7.07435Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export const LocationFilled = () => (
  <svg
    width="42"
    height="57"
    viewBox="0 0 42 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.4327 20.7827C41.4327 35.424 20.9995 56.6246 20.9995 56.6246C20.9995 56.6246 0.566406 35.424 0.566406 20.7827C0.566406 18.0994 1.09492 15.4424 2.12178 12.9633C3.14865 10.4843 4.65374 8.23172 6.55113 6.33433C8.44852 4.43694 10.7011 2.93185 13.1801 1.90499C15.6592 0.878128 18.3162 0.349609 20.9995 0.349609C23.6828 0.349609 26.3399 0.878128 28.819 1.90499C31.298 2.93185 33.5505 4.43694 35.4479 6.33433C37.3453 8.23172 38.8504 10.4843 39.8773 12.9633C40.9041 15.4424 41.4327 18.0994 41.4327 20.7827Z"
      fill="#D9D9D9"
    />
    <path
      d="M30.6926 22.2794C31.5184 16.9118 27.8366 11.8911 22.469 11.0654C17.1014 10.2396 12.0807 13.9214 11.255 19.289C10.4292 24.6565 14.111 29.6772 19.4786 30.503C24.8462 31.3288 29.8669 27.647 30.6926 22.2794Z"
      fill="white"
    />
  </svg>
);
