export const QuickResponder = ({ className }: { className?: string }) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M26.001 9.85913V21.2901H23.6976C23.5502 21.2901 23.4089 21.3486 23.3046 21.4528C23.2004 21.5571 23.1419 21.6984 23.1419 21.8458V25.6663C23.1414 25.7759 23.1086 25.8829 23.0475 25.9738C22.9864 26.0648 22.8998 26.1356 22.7985 26.1775C22.6973 26.2194 22.5859 26.2304 22.4784 26.2091C22.3709 26.1879 22.2721 26.1353 22.1944 26.0581L17.5903 21.454C17.5386 21.402 17.4772 21.3608 17.4094 21.3326C17.3417 21.3045 17.2691 21.29 17.1958 21.2901H11.7109L14.4061 18.5949C14.458 18.5434 14.5196 18.5026 14.5873 18.475C14.655 18.4473 14.7275 18.4333 14.8007 18.4337H22.5806C22.728 18.4337 22.8693 18.3752 22.9736 18.271C23.0778 18.1668 23.1363 18.0254 23.1363 17.878V9.85913H26.001Z"
        fill="#C41719"
      />
      <path
        d="M8.85079 9.85912H20.2845M8.85079 12.7183H20.2845M8.85079 15.5774H20.2845M23.1409 7.55571V17.878C23.1409 18.0254 23.0823 18.1667 22.9781 18.271C22.8739 18.3752 22.7325 18.4337 22.5852 18.4337H14.8052C14.7321 18.4333 14.6596 18.4473 14.5919 18.475C14.5242 18.5026 14.4626 18.5434 14.4107 18.5949L9.80661 23.1989C9.72905 23.2771 9.63001 23.3305 9.52204 23.3523C9.41408 23.3741 9.30207 23.3633 9.20025 23.3214C9.09842 23.2794 9.01137 23.2081 8.95014 23.1165C8.88891 23.025 8.85627 22.9173 8.85636 22.8072V18.9894C8.85636 18.8421 8.7978 18.7007 8.69358 18.5965C8.58937 18.4923 8.44803 18.4337 8.30065 18.4337H6.55571C6.40833 18.4337 6.26699 18.3752 6.16277 18.271C6.05856 18.1667 6 18.0254 6 17.878V7.55571C6 7.40833 6.05856 7.26698 6.16277 7.16276C6.26699 7.05855 6.40833 7 6.55571 7H22.5907C22.7381 7 22.8794 7.05855 22.9837 7.16276C23.0879 7.26698 23.1464 7.40833 23.1464 7.55571H23.1409ZM26 9.85912V21.2901H23.6966C23.5492 21.2901 23.4079 21.3486 23.3036 21.4528C23.1994 21.5571 23.1409 21.6984 23.1409 21.8458V25.6663C23.1404 25.7759 23.1076 25.8828 23.0465 25.9738C22.9854 26.0648 22.8988 26.1356 22.7975 26.1775C22.6963 26.2194 22.5849 26.2304 22.4774 26.2091C22.3699 26.1879 22.2711 26.1353 22.1934 26.0581L17.5893 21.454C17.5376 21.402 17.4761 21.3607 17.4084 21.3326C17.3407 21.3045 17.2681 21.29 17.1948 21.2901H11.7099L14.4051 18.5949C14.457 18.5434 14.5186 18.5026 14.5863 18.475C14.654 18.4473 14.7265 18.4333 14.7997 18.4337H22.5796C22.727 18.4337 22.8683 18.3752 22.9725 18.271C23.0768 18.1667 23.1353 18.0254 23.1353 17.878V9.85912H26Z"
        stroke="black"
        strokeWidth="0.820312"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FastShipper = ({ className }: { className?: string }) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6667 18.125H5V9.11111C5 8.94903 5.06439 8.7936 5.179 8.67899C5.2936 8.56438 5.44903 8.5 5.61111 8.5H19.0556C19.2176 8.5 19.3731 8.56438 19.4877 8.67899C19.6023 8.7936 19.6667 8.94903 19.6667 9.11111V18.125Z"
        fill="#C41719"
      />
      <path
        d="M20.5497 21.5625H19.6667M19.6667 11.25H23.9444C24.036 11.2501 24.1264 11.2707 24.2089 11.3103C24.2914 11.35 24.3639 11.4077 24.4211 11.4792L26.8655 14.5347C26.9525 14.6431 26.9999 14.7778 27 14.9167V20.9636C27 21.1257 26.9356 21.2811 26.821 21.3957C26.7064 21.5103 26.551 21.5747 26.3889 21.5747H24.4364M22.1111 11.2622V14.7761C22.1111 14.9382 22.1755 15.0936 22.2901 15.2082C22.4047 15.3228 22.5601 15.3872 22.7222 15.3872H27M19.6667 18.125H5V9.11111C5 8.94903 5.06439 8.7936 5.179 8.67899C5.2936 8.56438 5.44903 8.5 5.61111 8.5H19.0556C19.2176 8.5 19.3731 8.56438 19.4877 8.67899C19.6023 8.7936 19.6667 8.94903 19.6667 9.11111V18.125ZM19.6667 18.125V21.5625H9.69333C9.55206 21.1598 9.28921 20.811 8.94108 20.5642C8.59295 20.3174 8.17674 20.1848 7.75 20.1848C7.32326 20.1848 6.90705 20.3174 6.55892 20.5642C6.21079 20.811 5.94793 21.1598 5.80667 21.5625H5.61111C5.44903 21.5625 5.2936 21.4981 5.179 21.3835C5.06439 21.2689 5 21.1135 5 20.9514V18.125H19.6667ZM9.8125 22.2622C9.8125 22.8092 9.59519 23.3338 9.2084 23.7206C8.82161 24.1074 8.29701 24.3247 7.75 24.3247C7.20299 24.3247 6.67839 24.1074 6.2916 23.7206C5.90481 23.3338 5.6875 22.8092 5.6875 22.2622C5.68602 22.0278 5.72638 21.795 5.80667 21.5747C5.94793 21.172 6.21079 20.8232 6.55892 20.5764C6.90705 20.3296 7.32326 20.197 7.75 20.197C8.17674 20.197 8.59295 20.3296 8.94108 20.5764C9.28921 20.8232 9.55206 21.172 9.69333 21.5747C9.77362 21.795 9.81398 22.0278 9.8125 22.2622V22.2622ZM24.5556 22.2622C24.5565 22.7797 24.3632 23.2788 24.0139 23.6606C23.6645 24.0424 23.1846 24.2791 22.669 24.324C22.1534 24.3688 21.6398 24.2185 21.2298 23.9027C20.8197 23.587 20.5432 23.1288 20.4548 22.6189C20.3664 22.109 20.4726 21.5845 20.7525 21.1492C21.0323 20.7139 21.4654 20.3995 21.966 20.2682C22.4666 20.1369 22.9982 20.1983 23.4557 20.4403C23.9131 20.6822 24.2631 21.0871 24.4364 21.5747C24.5149 21.7955 24.5552 22.0279 24.5556 22.2622V22.2622Z"
        stroke="black"
        strokeWidth="0.820312"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.05093 11.3753L7.14648 14.5439H7.91954L8.79954 11.3783L8.05093 11.3753Z"
        fill="white"
      />
      <path
        d="M10.0546 11.3814L9.18073 12.3439L8.87518 11.3783L8.53906 12.5089L8.79267 13.6395L9.72768 12.512L10.0546 11.3814Z"
        fill="white"
      />
      <path
        d="M9.98204 14.55L10.8834 11.3814H10.1073L9.20898 14.5469L9.98204 14.55Z"
        fill="white"
      />
      <path
        d="M11.6948 14.5561L12.5718 14.5592L12.1806 12.8725L13.409 11.3905L12.6726 11.3875L12.0156 12.1942L11.8293 11.3844H10.9584L11.3251 12.9703L8.95703 15.7844H9.70565L11.4901 13.6853L11.6948 14.5561Z"
        fill="white"
      />
      <path
        d="M10.8263 14.7333H10.695L10.4902 15.4636H10.9638L10.9883 15.3567L10.6491 15.3506L10.8263 14.7333Z"
        fill="white"
      />
      <path
        d="M11.4014 15.2925L11.3403 15.3444H11.1783L11.1416 15.2986L11.2547 14.9014L11.3127 14.8555H11.4716L11.5144 14.9014L11.4014 15.2925ZM11.5633 14.7364H11.2883L11.1386 14.8464L10.998 15.3505L11.0897 15.4697H11.3555L11.5144 15.3444L11.655 14.8494L11.5633 14.7364Z"
        fill="white"
      />
      <path
        d="M11.98 15.1947L11.9556 15.3047L11.8975 15.3475L11.7447 15.3444L11.7111 15.2925L11.8181 14.9105L11.8822 14.8586H12.0381L12.0656 14.9105L12.0442 14.9961L12.1756 14.9992L12.2122 14.8433L12.1267 14.7364H11.867L11.702 14.8617L11.5645 15.3383L11.6653 15.4728H11.9189L12.0717 15.3505L12.1114 15.1978L11.98 15.1947Z"
        fill="white"
      />
      <path
        d="M12.8223 14.7394H12.6818L12.3487 15.0328L12.4282 14.7394L12.2937 14.7364L12.0859 15.4728H12.2265L12.2968 15.2192L12.4221 15.1092L12.4923 15.4758H12.6207L12.529 15.0053L12.8223 14.7394Z"
        fill="white"
      />
      <path
        d="M12.8863 15.1397H13.1307L13.1552 15.0419H12.9107L12.9596 14.8616H13.314L13.3477 14.7394H12.874L12.6602 15.4758H13.1399L13.1735 15.3536H12.8282L12.8863 15.1397Z"
        fill="white"
      />
      <path
        d="M13.437 15.0542L13.4889 14.8647H13.6814L13.7089 14.8983L13.6845 15.0114L13.6325 15.0603L13.437 15.0542ZM13.8006 15.0542L13.8556 14.8464L13.7792 14.7455H13.4003L13.1895 15.4819H13.3178L13.4034 15.1764H13.492L13.5408 15.4819H13.6723L13.6203 15.1764H13.657L13.8006 15.0542Z"
        fill="white"
      />
    </svg>
  );
};

export const AccurateDescriber = ({ className }: { className?: string }) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8 9.62748H11.5103C11.6724 9.62748 11.8278 9.5631 11.9424 9.44851C12.057 9.33392 12.1214 9.17851 12.1214 9.01645V5.50305"
        fill="#C41719"
      />
      <path
        d="M8 9.62748H11.5103C11.6724 9.62748 11.8278 9.56311 11.9424 9.44852C12.057 9.33393 12.1214 9.17851 12.1214 9.01645V5.50305M10.7466 16.5015H21.745M10.7466 13.7519H21.745M10.7466 19.2511H21.745M10.7466 22.0008H21.745M24.4977 6.11408V26.889C24.4977 27.051 24.4333 27.2064 24.3187 27.321C24.2041 27.4356 24.0487 27.5 23.8867 27.5H8.61103C8.44897 27.5 8.29355 27.4356 8.17896 27.321C8.06437 27.2064 8 27.051 8 26.889V9.88106C7.99987 9.7197 8.06358 9.56484 8.1772 9.45028L11.9442 5.68025C12.0582 5.5655 12.2132 5.50068 12.375 5.5H23.8867C24.0487 5.5 24.2041 5.56438 24.3187 5.67896C24.4333 5.79355 24.4977 5.94897 24.4977 6.11103V6.11408Z"
        stroke="black"
        strokeWidth="0.820312"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
