export const Copy = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.66602 3.33268V13.3327C6.66602 13.7747 6.84161 14.1986 7.15417 14.5112C7.46673 14.8238 7.89065 14.9993 8.33268 14.9993H14.9993C15.4414 14.9993 15.8653 14.8238 16.1779 14.5112C16.4904 14.1986 16.666 13.7747 16.666 13.3327V6.03435C16.666 5.81232 16.6216 5.59253 16.5354 5.38789C16.4493 5.18325 16.3231 4.99788 16.1643 4.84268L13.4018 2.14102C13.0905 1.83656 12.6723 1.66607 12.2368 1.66602H8.33268C7.89065 1.66602 7.46673 1.84161 7.15417 2.15417C6.84161 2.46673 6.66602 2.89065 6.66602 3.33268V3.33268Z"
      stroke="#242E5F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.333 14.9987V16.6654C13.333 17.1074 13.1574 17.5313 12.8449 17.8439C12.5323 18.1564 12.1084 18.332 11.6663 18.332H4.99967C4.55765 18.332 4.13372 18.1564 3.82116 17.8439C3.5086 17.5313 3.33301 17.1074 3.33301 16.6654V7.4987C3.33301 7.05667 3.5086 6.63275 3.82116 6.32019C4.13372 6.00763 4.55765 5.83203 4.99967 5.83203H6.66634"
      stroke="#242E5F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
