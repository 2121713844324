export function Cash() {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.61523 18.0667H37.5527V36.1414H6.61523V18.0667Z"
        fill="#EF9021"
      />
      <path
        opacity="0.3"
        d="M6.61523 18.0669H37.5527V36.1416H6.61523V18.0669Z"
        fill="white"
      />
      <path
        d="M34.8027 30.514V23.6943C34.0466 23.6943 33.3214 23.3939 32.7867 22.8593C32.2521 22.3246 31.9517 21.5994 31.9517 20.8433H12.2163C12.2158 21.5992 11.9152 22.3241 11.3806 22.8587C10.8461 23.3932 10.1212 23.6938 9.36523 23.6943V30.514C9.73964 30.514 10.1104 30.5877 10.4563 30.731C10.8022 30.8743 11.1165 31.0843 11.3812 31.349C11.646 31.6138 11.856 31.9281 11.9993 32.274C12.1426 32.6199 12.2163 32.9906 12.2163 33.365H31.9517C31.9517 32.6089 32.2521 31.8837 32.7867 31.349C33.3214 30.8144 34.0466 30.514 34.8027 30.514Z"
        fill="#EF9021"
      />
      <path
        d="M22.084 30.7131C24.0774 30.7131 25.6934 29.0972 25.6934 27.1038C25.6934 25.1104 24.0774 23.4944 22.084 23.4944C20.0906 23.4944 18.4746 25.1104 18.4746 27.1038C18.4746 29.0972 20.0906 30.7131 22.084 30.7131Z"
        fill="white"
      />
      <path
        d="M36.7862 18.0677H9.88086L33.1424 8.85864L36.7862 18.0677Z"
        fill="#EF9021"
      />
      <path
        opacity="0.3"
        d="M36.7842 18.0677H9.87891L33.1405 8.85864L36.7842 18.0677Z"
        fill="white"
      />
      <path
        d="M33.8291 18.0677H17.4219L28.9547 13.5027C29.0924 13.8507 29.2972 14.1683 29.5576 14.4371C29.818 14.706 30.1288 14.921 30.4723 15.0697C30.8157 15.2185 31.1851 15.2981 31.5594 15.3041C31.9336 15.3101 32.3054 15.2423 32.6534 15.1046L33.8291 18.0677Z"
        fill="#EF9021"
      />
    </svg>
  );
}
