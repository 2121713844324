export const Smartphone = () => (
  <svg
    width="39"
    height="58"
    viewBox="0 0 39 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.7509 38.8945C21.1456 40.8394 18.174 44.5828 17.1552 46.8828C17.0443 47.1273 16.918 47.3645 16.7771 47.5929C15.4572 49.7772 12.7096 51.9383 11.3743 52.903C11.1411 53.0702 10.9561 53.2959 10.8382 53.5575C10.7203 53.8191 10.6736 54.1072 10.7028 54.3926V54.7013C10.7243 54.9292 10.698 55.1591 10.6256 55.3762C10.5531 55.5933 10.4361 55.7928 10.2821 55.9621C10.128 56.1314 9.94038 56.2666 9.73107 56.3592C9.52176 56.4518 9.29543 56.4996 9.06657 56.4997H2.892C2.65444 56.5005 2.41955 56.4496 2.20365 56.3505C1.98775 56.2514 1.79598 56.1065 1.64174 55.9258C1.4875 55.7451 1.37448 55.533 1.31048 55.3042C1.24648 55.0755 1.23309 54.8355 1.27119 54.601C2.11247 49.3217 4.55915 34.7806 5.90212 33.4377L10.8494 28.498V41.673C10.8494 41.673 15.3491 41.2099 17.7958 37.5283C19.6019 35.8921 21.0915 35.923 22.1334 36.3552C22.3629 36.4597 22.565 36.6161 22.7237 36.8121C22.8824 37.008 22.9936 37.2382 23.0482 37.4844C23.1028 37.7306 23.0994 37.9861 23.0385 38.2307C22.9775 38.4754 22.8607 38.7027 22.697 38.8945"
      fill="#C41719"
    />
    <path
      d="M37.5699 8.4927H10.919M22.4963 45.1388H26.4943M22.7509 38.8947C21.1456 40.8397 18.174 44.583 17.1552 46.8831C17.0443 47.1275 16.918 47.3647 16.7771 47.5932C15.4572 49.7774 12.7096 51.9385 11.3743 52.9033C11.1411 53.0705 10.9561 53.2962 10.8382 53.5578C10.7203 53.8194 10.6736 54.1075 10.7028 54.3929V54.7016C10.7243 54.9295 10.698 55.1594 10.6256 55.3765C10.5531 55.5936 10.4361 55.7931 10.2821 55.9624C10.128 56.1317 9.94038 56.2669 9.73107 56.3595C9.52176 56.4521 9.29543 56.4999 9.06657 56.5H2.892C2.65444 56.5008 2.41955 56.4499 2.20365 56.3508C1.98775 56.2517 1.79598 56.1068 1.64174 55.9261C1.4875 55.7454 1.37448 55.5333 1.31048 55.3045C1.24648 55.0757 1.23309 54.8358 1.27119 54.6013C2.11247 49.322 4.55915 34.7809 5.90212 33.438L10.8494 28.4983V41.6733C10.8494 41.6733 15.3491 41.2102 17.7958 37.5286C19.6019 35.8923 21.0915 35.9233 22.1334 36.3555C22.3629 36.46 22.565 36.6164 22.7237 36.8124C22.8824 37.0083 22.9936 37.2385 23.0482 37.4847C23.1028 37.7308 23.0994 37.9863 23.0385 38.231C22.9775 38.4757 22.8607 38.7029 22.697 38.8947H22.7509ZM37.5699 3.15171V45.9338C37.5709 46.1509 37.5289 46.3662 37.4463 46.567C37.3636 46.7679 37.242 46.9504 37.0884 47.104C36.9348 47.2575 36.7523 47.3792 36.5515 47.4618C36.3506 47.5444 36.1354 47.5865 35.9183 47.5855H16.8002C16.9412 47.357 17.0675 47.1199 17.1784 46.8754C18.1972 44.5599 21.1687 40.832 22.7741 38.887C22.9378 38.6952 23.0548 38.468 23.1157 38.2233C23.1767 37.9786 23.18 37.7231 23.1254 37.4769C23.0707 37.2307 22.9597 37.0006 22.801 36.8046C22.6422 36.6087 22.4402 36.4523 22.2107 36.3477C21.1687 35.9155 19.6791 35.8847 17.8731 37.5209C15.4187 41.2025 10.9267 41.6656 10.9267 41.6656V3.15171C10.9267 2.71499 11.0996 2.29602 11.4077 1.98649C11.7157 1.67695 12.1339 1.50204 12.5707 1.5H35.9259C36.3639 1.5 36.7841 1.67405 37.0939 1.9838C37.4036 2.29355 37.5776 2.71365 37.5776 3.15171H37.5699Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0589 19.8389L15.7666 27.8426H17.7193L19.9499 19.8466L18.0589 19.8389Z"
      fill="black"
    />
    <path
      d="M23.1202 19.8544L20.9051 22.2856L20.141 19.8467L19.292 22.7024L19.9326 25.5582L22.2944 22.7102L23.1202 19.8544Z"
      fill="black"
    />
    <path
      d="M22.936 27.8573L25.2052 19.8535H23.2447L20.9756 27.8496L22.936 27.8573Z"
      fill="black"
    />
    <path
      d="M27.2661 27.8727H29.4812L28.4856 23.62L31.5883 19.8689H29.7282L28.0688 21.9065L27.6057 19.8612L25.406 19.8535L26.3322 23.8593L20.3506 30.9754H22.2415L26.749 25.673L27.2661 27.8727Z"
      fill="black"
    />
    <path
      d="M25.0668 28.3213H24.7349L24.2178 30.166H25.4218L25.4836 29.8881L24.6269 29.8804L25.0668 28.3213Z"
      fill="black"
    />
    <path
      d="M26.518 29.7328L26.3713 29.864H25.9468L25.8542 29.7483L26.1398 28.7449L26.2941 28.6291H26.6955L26.7958 28.7449L26.518 29.7328ZM26.9348 28.3281H26.2324L25.8542 28.606L25.5068 29.8795L25.7384 30.1805H26.4099L26.8113 29.864L27.1585 28.6137L26.9348 28.3281Z"
      fill="black"
    />
    <path
      d="M27.9842 29.4859L27.9148 29.7637L27.7759 29.8718L27.39 29.864L27.305 29.7328L27.5752 28.7681L27.7372 28.6291L28.1309 28.6369L28.1926 28.7681L28.1463 28.9842H28.4782L28.5708 28.5982L28.3547 28.3281H27.6987L27.2819 28.6446L26.9346 29.8486L27.1816 30.1882H27.8299L28.2158 29.8795L28.3161 29.4936L27.9842 29.4859Z"
      fill="black"
    />
    <path
      d="M30.1063 28.3359H29.7512L28.9177 29.0769L29.1183 28.3359H28.7787L28.2539 30.1883H28.6012L28.7865 29.5477L29.1029 29.2699L29.2804 30.196H29.6046L29.3653 29.0074L30.1063 28.3359Z"
      fill="black"
    />
    <path
      d="M30.2762 29.347L30.8937 29.3547L30.9554 29.1H30.3379L30.4537 28.6447H31.3491L31.4339 28.3359H30.2376L29.7051 30.196H30.9091L30.994 29.8873H30.1218L30.2762 29.347Z"
      fill="black"
    />
    <path
      d="M31.6652 29.1311L31.7886 28.6525H32.2748L32.352 28.7375L32.2903 29.023L32.1591 29.1465L31.6652 29.1311ZM32.5836 29.1311L32.7148 28.6062L32.5219 28.3516H31.5726L31.0322 30.2116H31.3642L31.5802 29.4398H31.7963L31.9276 30.2116H32.2594L32.1205 29.4398H32.2131L32.5836 29.1311Z"
      fill="black"
    />
  </svg>
);
