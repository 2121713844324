export const Coin = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9199 17.2501C21.5899 19.9101 19.4099 22.0901 16.7499 22.4201C15.1399 22.6201 13.6399 22.1801 12.4699 21.3201C11.7999 20.8301 11.9599 19.7901 12.7599 19.5501C15.7699 18.6401 18.1399 16.2601 19.0599 13.2501C19.2999 12.4601 20.3399 12.3001 20.8299 12.9601C21.6799 14.1401 22.1199 15.6401 21.9199 17.2501Z"
      fill="white"
    />
    <path
      d="M9.99 2.5C5.58 2.5 2 6.08 2 10.49C2 14.9 5.58 18.48 9.99 18.48C14.4 18.48 17.98 14.9 17.98 10.49C17.97 6.08 14.4 2.5 9.99 2.5ZM9.05 9.37L11.46 10.21C12.33 10.52 12.75 11.13 12.75 12.07C12.75 13.15 11.89 14.04 10.84 14.04H10.75V14.09C10.75 14.5 10.41 14.84 10 14.84C9.59 14.84 9.25 14.5 9.25 14.09V14.03C8.14 13.98 7.25 13.05 7.25 11.89C7.25 11.48 7.59 11.14 8 11.14C8.41 11.14 8.75 11.48 8.75 11.89C8.75 12.25 9.01 12.54 9.33 12.54H10.83C11.06 12.54 11.24 12.33 11.24 12.07C11.24 11.72 11.18 11.7 10.95 11.62L8.54 10.78C7.68 10.48 7.25 9.87 7.25 8.92C7.25 7.84 8.11 6.95 9.16 6.95H9.25V6.91C9.25 6.5 9.59 6.16 10 6.16C10.41 6.16 10.75 6.5 10.75 6.91V6.97C11.86 7.02 12.75 7.95 12.75 9.11C12.75 9.52 12.41 9.86 12 9.86C11.59 9.86 11.25 9.52 11.25 9.11C11.25 8.75 10.99 8.46 10.67 8.46H9.17C8.94 8.46 8.76 8.67 8.76 8.93C8.75 9.27 8.81 9.29 9.05 9.37Z"
      fill="white"
    />
  </svg>
);
