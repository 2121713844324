export const FileArrowDown = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.625 18H4.375C4.20924 18 4.05027 17.9342 3.93306 17.8169C3.81585 17.6997 3.75 17.5408 3.75 17.375V3.625C3.75 3.45924 3.81585 3.30027 3.93306 3.18306C4.05027 3.06585 4.20924 3 4.375 3H11.875L16.25 7.375V17.375C16.25 17.5408 16.1842 17.6997 16.0669 17.8169C15.9497 17.9342 15.7908 18 15.625 18Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.875 3V7.375H16.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.8125 12.6875L10 14.875L12.1875 12.6875"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 9.875V14.875"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
