import React from 'react';

const Box = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.06641 7.21094L12.0008 12.1009L20.9352 7.21094"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M12.36 2.34205L20.61 6.85892C20.7278 6.92338 20.8261 7.01828 20.8947 7.13372C20.9633 7.24915 20.9997 7.38089 21 7.51517V16.4814C20.9997 16.6157 20.9633 16.7474 20.8947 16.8629C20.8261 16.9783 20.7278 17.0732 20.61 17.1377L12.36 21.6545C12.2496 21.7149 12.1258 21.7466 12 21.7466C11.8742 21.7466 11.7504 21.7149 11.64 21.6545L3.39 17.1377C3.2722 17.0732 3.17386 16.9783 3.10526 16.8629C3.03666 16.7474 3.0003 16.6157 3 16.4814V7.51517C3.0003 7.38089 3.03666 7.24915 3.10526 7.13372C3.17386 7.01828 3.2722 6.92338 3.39 6.85892L11.64 2.34205C11.7504 2.28165 11.8742 2.25 12 2.25C12.1258 2.25 12.2496 2.28165 12.36 2.34205Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M12 12.1016V21.7494" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  );
};

export default Box;
