import React from 'react';

const Purchases = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0693 20H4.93065C4.76045 20 4.5961 19.9313 4.46856 19.8069C4.34102 19.6824 4.25908 19.5107 4.23818 19.3242L3.0047 7.85362C2.99329 7.74599 3.00272 7.63691 3.03238 7.53362C3.06203 7.43032 3.11123 7.33516 3.1767 7.25445C3.24218 7.17374 3.32244 7.10931 3.41217 7.06545C3.5019 7.02159 3.59905 6.99928 3.69718 7.00002H20.3028C20.401 6.99928 20.4981 7.02159 20.5878 7.06545C20.6776 7.10931 20.7578 7.17374 20.8233 7.25445C20.8888 7.33516 20.938 7.43032 20.9676 7.53362C20.9973 7.63691 21.0067 7.74599 20.9953 7.85362L19.7618 19.3242C19.7409 19.5107 19.659 19.6824 19.5314 19.8069C19.4039 19.9313 19.2396 20 19.0693 20Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M8.25 9.75V6C8.25 5.00544 8.64509 4.05161 9.34835 3.34835C10.0516 2.64509 11.0054 2.25 12 2.25C12.9946 2.25 13.9484 2.64509 14.6517 3.34835C15.3549 4.05161 15.75 5.00544 15.75 6V9.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Purchases;
