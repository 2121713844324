export const Payout = () => (
  <svg
    width="46"
    height="32"
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.3996 0H4.59961C2.39047 0 0.599609 1.79086 0.599609 4V28C0.599609 30.2091 2.39047 32 4.59961 32H41.3996C43.6087 32 45.3996 30.2091 45.3996 28V4C45.3996 1.79086 43.6087 0 41.3996 0Z"
      fill="#EF9021"
    />
    <path
      opacity="0.5"
      d="M41.3996 0H4.59961C2.39047 0 0.599609 1.79086 0.599609 4V28C0.599609 30.2091 2.39047 32 4.59961 32H41.3996C43.6087 32 45.3996 30.2091 45.3996 28V4C45.3996 1.79086 43.6087 0 41.3996 0Z"
      fill="white"
    />
    <path
      d="M41.3988 8.0002C40.3384 7.99892 39.3217 7.57709 38.5718 6.82722C37.8219 6.07735 37.4001 5.06067 37.3988 4.0002C37.3988 3.78802 37.3145 3.58454 37.1645 3.43451C37.0145 3.28448 36.811 3.2002 36.5988 3.2002H9.39883C9.18666 3.2002 8.98317 3.28448 8.83314 3.43451C8.68311 3.58454 8.59883 3.78802 8.59883 4.0002C8.59756 5.06067 8.17572 6.07735 7.42585 6.82722C6.67598 7.57709 5.6593 7.99892 4.59883 8.0002C4.38665 8.0002 4.18317 8.08448 4.03314 8.23451C3.88311 8.38454 3.79883 8.58802 3.79883 8.80019V23.2002C3.79883 23.4124 3.88311 23.6159 4.03314 23.7659C4.18317 23.9159 4.38665 24.0002 4.59883 24.0002C5.6593 24.0015 6.67598 24.4233 7.42585 25.1732C8.17572 25.923 8.59756 26.9397 8.59883 28.0002C8.59883 28.2124 8.68311 28.4159 8.83314 28.5659C8.98317 28.7159 9.18666 28.8002 9.39883 28.8002H36.5988C36.811 28.8002 37.0145 28.7159 37.1645 28.5659C37.3145 28.4159 37.3988 28.2124 37.3988 28.0002C37.4001 26.9397 37.8219 25.923 38.5718 25.1732C39.3217 24.4233 40.3384 24.0015 41.3988 24.0002C41.611 24.0002 41.8145 23.9159 41.9645 23.7659C42.1145 23.6159 42.1988 23.4124 42.1988 23.2002V8.80019C42.1988 8.58802 42.1145 8.38454 41.9645 8.23451C41.8145 8.08448 41.611 8.0002 41.3988 8.0002Z"
      fill="#EF9021"
    />
    <path
      d="M26.201 15.2H20.601V11.5632H23.641C24.1155 11.5716 24.5672 11.7679 24.8972 12.1089C25.2271 12.45 25.4083 12.9079 25.401 13.3824C25.401 13.5946 25.4853 13.7981 25.6353 13.9481C25.7854 14.0981 25.9889 14.1824 26.201 14.1824C26.4132 14.1824 26.6167 14.0981 26.7667 13.9481C26.9167 13.7981 27.001 13.5946 27.001 13.3824C27.0059 12.5131 26.6764 11.6751 26.0808 11.0418C25.4852 10.4086 24.669 10.0284 23.801 9.98V8.8C23.801 8.58783 23.7167 8.38434 23.5667 8.23431C23.4167 8.08428 23.2132 8 23.001 8C22.7889 8 22.5854 8.08428 22.4353 8.23431C22.2853 8.38434 22.201 8.58783 22.201 8.8V9.9632H19.801C19.5889 9.9632 19.3854 10.0475 19.2353 10.1975C19.0853 10.3475 19.001 10.551 19.001 10.7632V16C19.001 16.2122 19.0853 16.4157 19.2353 16.5657C19.3854 16.7157 19.5889 16.8 19.801 16.8H25.401V20.4368H22.361C21.8866 20.4284 21.4348 20.2321 21.1049 19.8911C20.775 19.55 20.5938 19.0921 20.601 18.6176C20.601 18.4054 20.5167 18.2019 20.3667 18.0519C20.2167 17.9019 20.0132 17.8176 19.801 17.8176C19.5889 17.8176 19.3854 17.9019 19.2353 18.0519C19.0853 18.2019 19.001 18.4054 19.001 18.6176C18.9962 19.4869 19.3256 20.3249 19.9212 20.9582C20.5168 21.5914 21.333 21.9716 22.201 22.02V23.2C22.201 23.4122 22.2853 23.6157 22.4353 23.7657C22.5854 23.9157 22.7889 24 23.001 24C23.2132 24 23.4167 23.9157 23.5667 23.7657C23.7167 23.6157 23.801 23.4122 23.801 23.2V22.0368H26.201C26.4132 22.0368 26.6167 21.9525 26.7667 21.8025C26.9167 21.6525 27.001 21.449 27.001 21.2368V16C27.001 15.7878 26.9167 15.5843 26.7667 15.4343C26.6167 15.2843 26.4132 15.2 26.201 15.2Z"
      fill="white"
    />
  </svg>
);
