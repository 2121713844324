export const MessageStar = () => (
  <svg
    width="58"
    height="53"
    viewBox="0 0 58 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.9294 43.565H15.98L0.875 52.155V9.03937C0.875 6.86576 1.73847 4.78117 3.27544 3.24419C4.81242 1.70721 6.89701 0.84375 9.07062 0.84375H48.9294C50.0056 0.84375 51.0714 1.05574 52.0657 1.4676C53.06 1.87947 53.9635 2.48316 54.7246 3.24419C55.4856 4.00523 56.0893 4.90871 56.5011 5.90304C56.913 6.89738 57.125 7.96311 57.125 9.03937V35.3694C57.125 36.4456 56.913 37.5114 56.5011 38.5057C56.0893 39.5 55.4856 40.4035 54.7246 41.1646C53.9635 41.9256 53.06 42.5293 52.0657 42.9411C51.0714 43.353 50.0056 43.565 48.9294 43.565Z"
      fill="#D9D9D9"
    />
    <path
      d="M29 10.3047L32.4912 17.3784L40.2969 18.5128L34.6488 24.0184L35.9819 31.7934L29 28.1222L22.0181 31.7934L23.3513 24.0184L17.7031 18.5128L25.5088 17.3784L29 10.3047Z"
      fill="white"
    />
  </svg>
);
