export const Packaging = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.6562 14.8438H50H27.3438L14.8438 29.6164H50H85.1562L72.6562 14.8438Z"
        fill="#FFA336"
      />
      <path
        d="M14.8438 29.6165H85.1562V84.8594H14.8438V29.6165Z"
        fill="#FFA336"
      />
      <path
        opacity="0.2"
        d="M14.8438 29.6172H85.1562V84.8602H14.8438V29.6172Z"
        fill="#202330"
      />
      <path
        d="M56.091 14.8438H43.9082L40.7832 29.6164H59.216L56.091 14.8438Z"
        fill="#242E5F"
      />
      <path
        d="M40.7832 29.6165H59.2152V45.7579H40.7832V29.6165Z"
        fill="#242E5F"
      />
      <path
        opacity="0.2"
        d="M40.7832 29.6172H59.2152V45.7586H40.7832V29.6172Z"
        fill="#202330"
      />
      <path
        d="M61.1074 66.9155H77.4621V76.8077H61.1074V66.9155Z"
        fill="#202330"
      />
    </svg>
  );
};
export const Delivery = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M85.1559 33.725L64.7488 43.3602L64.6598 43.4079H64.6527L54.3262 48.2868L38.9004 41.0024L23.4824 33.725L33.823 28.8391L44.4441 23.8227L54.3191 19.1633L69.7309 26.4407H69.7371L85.1559 33.725Z"
        fill="#EF9021"
      />
      <path
        d="M85.157 33.7258V66.2743L54.3203 80.8368V48.2883L85.157 33.7258Z"
        fill="#EF9021"
      />
      <path
        opacity="0.2"
        d="M85.157 33.7258V66.2743L54.3203 80.8368V48.2883L85.157 33.7258Z"
        fill="white"
      />
      <path
        d="M71.2555 59.3611V66.39L59.5859 71.9009V64.872L71.2555 59.3611Z"
        fill="#202330"
      />
      <path
        d="M54.3191 48.2868V80.8368L38.9004 73.5595L23.4824 66.2751V33.7251L38.9004 41.0095L54.3191 48.2868Z"
        fill="#EF9021"
      />
      <path
        opacity="0.2"
        d="M54.3191 48.2875V80.8376L38.9004 73.5602L23.4824 66.2758V33.7258L38.9004 41.0102L54.3191 48.2875Z"
        fill="#202330"
      />
      <path
        d="M31.743 62.3572H14.8438C14.4811 62.3572 14.1334 62.2131 13.877 61.9567C13.6206 61.7003 13.4766 61.3526 13.4766 60.99C13.4766 60.6274 13.6206 60.2796 13.877 60.0232C14.1334 59.7668 14.4811 59.6228 14.8438 59.6228H31.743C32.1056 59.6228 32.4533 59.7668 32.7097 60.0232C32.9661 60.2796 33.1102 60.6274 33.1102 60.99C33.1102 61.3526 32.9661 61.7003 32.7097 61.9567C32.4533 62.2131 32.1056 62.3572 31.743 62.3572Z"
        fill="#242E5F"
      />
      <path
        d="M27.1586 43.8142H14.8438C14.4811 43.8142 14.1334 43.6702 13.877 43.4138C13.6206 43.1574 13.4766 42.8096 13.4766 42.447C13.4766 42.0844 13.6206 41.7367 13.877 41.4803C14.1334 41.2239 14.4811 41.0798 14.8438 41.0798H27.1586C27.5212 41.0798 27.8689 41.2239 28.1253 41.4803C28.3817 41.7367 28.5258 42.0844 28.5258 42.447C28.5258 42.8096 28.3817 43.1574 28.1253 43.4138C27.8689 43.6702 27.5212 43.8142 27.1586 43.8142Z"
        fill="#242E5F"
      />
      <path
        d="M33.8613 53.0862H18.9395C18.5769 53.0862 18.2291 52.9421 17.9727 52.6857C17.7163 52.4293 17.5723 52.0816 17.5723 51.719C17.5723 51.3564 17.7163 51.0086 17.9727 50.7522C18.2291 50.4959 18.5769 50.3518 18.9395 50.3518H33.8613C34.2239 50.3518 34.5717 50.4959 34.8281 50.7522C35.0845 51.0086 35.2285 51.3564 35.2285 51.719C35.2285 52.0816 35.0845 52.4293 34.8281 52.6857C34.5717 52.9421 34.2239 53.0862 33.8613 53.0862Z"
        fill="#242E5F"
      />
    </svg>
  );
};
export const GetMoney = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M88.8906 72.7032C88.7135 71.9719 88.375 71.2894 87.9001 70.7058C87.4252 70.1222 86.8257 69.6522 86.1457 69.3301C85.4656 69.0081 84.7222 68.8421 83.9697 68.8445C83.2173 68.8469 82.4749 69.0175 81.7969 69.3438L62.5 77.9375C63.3459 77.5554 64.0623 76.9352 64.5617 76.1528C65.0611 75.3704 65.322 74.4595 65.3125 73.5313C65.325 72.9079 65.2129 72.2882 64.983 71.7087C64.753 71.1291 64.4097 70.6012 63.9732 70.156C63.5366 69.7107 63.0157 69.357 62.4408 69.1156C61.8658 68.8742 61.2485 68.7499 60.625 68.75H48.5625C47.3822 68.7505 46.2191 68.4664 45.1719 67.9219C42.2405 66.3961 38.8616 65.964 35.6406 66.7032L26.5625 68.75H23.4375V89.0625L41.4531 93.625C47.0992 95.144 53.1026 94.5897 58.375 92.0625L85.9375 78.9063C87.0825 78.387 88.0069 77.4794 88.5474 76.3443C89.0878 75.2091 89.2094 73.9194 88.8906 72.7032Z"
        fill="#FED1A2"
      />
      <path
        d="M23.4375 64.0625H14.0625C12.3366 64.0625 10.9375 65.4616 10.9375 67.1875V92.1875C10.9375 93.9134 12.3366 95.3125 14.0625 95.3125H23.4375C25.1634 95.3125 26.5625 93.9134 26.5625 92.1875V67.1875C26.5625 65.4616 25.1634 64.0625 23.4375 64.0625Z"
        fill="#242E5F"
      />
      <path
        d="M50 57.8125C64.6701 57.8125 76.5625 45.9201 76.5625 31.25C76.5625 16.5799 64.6701 4.6875 50 4.6875C35.3299 4.6875 23.4375 16.5799 23.4375 31.25C23.4375 45.9201 35.3299 57.8125 50 57.8125Z"
        fill="#EF9021"
      />
      <path
        opacity="0.3"
        d="M50 51.5625C61.2183 51.5625 70.3125 42.4683 70.3125 31.25C70.3125 20.0317 61.2183 10.9375 50 10.9375C38.7817 10.9375 29.6875 20.0317 29.6875 31.25C29.6875 42.4683 38.7817 51.5625 50 51.5625Z"
        fill="white"
      />
      <path
        d="M51.5625 29.6875H48.4375C47.6087 29.6875 46.8138 29.3583 46.2278 28.7722C45.6417 28.1862 45.3125 27.3913 45.3125 26.5625C45.3125 25.7337 45.6417 24.9388 46.2278 24.3528C46.8138 23.7667 47.6087 23.4375 48.4375 23.4375H51.5625C52.3913 23.4375 53.1862 23.7667 53.7722 24.3528C54.3583 24.9388 54.6875 25.7337 54.6875 26.5625C54.6875 26.9769 54.8521 27.3743 55.1451 27.6674C55.4382 27.9604 55.8356 28.125 56.25 28.125C56.6644 28.125 57.0618 27.9604 57.3549 27.6674C57.6479 27.3743 57.8125 26.9769 57.8125 26.5625C57.8125 24.9049 57.154 23.3152 55.9819 22.1431C54.8098 20.971 53.2201 20.3125 51.5625 20.3125V17.1875C51.5625 16.7731 51.3979 16.3757 51.1049 16.0826C50.8118 15.7896 50.4144 15.625 50 15.625C49.5856 15.625 49.1882 15.7896 48.8951 16.0826C48.6021 16.3757 48.4375 16.7731 48.4375 17.1875V20.3125C46.7799 20.3125 45.1902 20.971 44.0181 22.1431C42.846 23.3152 42.1875 24.9049 42.1875 26.5625C42.1875 28.2201 42.846 29.8098 44.0181 30.9819C45.1902 32.154 46.7799 32.8125 48.4375 32.8125H51.5625C52.3913 32.8125 53.1862 33.1417 53.7722 33.7278C54.3583 34.3138 54.6875 35.1087 54.6875 35.9375C54.6875 36.7663 54.3583 37.5612 53.7722 38.1472C53.1862 38.7333 52.3913 39.0625 51.5625 39.0625H48.4375C47.6087 39.0625 46.8138 38.7333 46.2278 38.1472C45.6417 37.5612 45.3125 36.7663 45.3125 35.9375C45.3125 35.5231 45.1479 35.1257 44.8549 34.8326C44.5618 34.5396 44.1644 34.375 43.75 34.375C43.3356 34.375 42.9382 34.5396 42.6451 34.8326C42.3521 35.1257 42.1875 35.5231 42.1875 35.9375C42.1875 37.5951 42.846 39.1848 44.0181 40.3569C45.1902 41.529 46.7799 42.1875 48.4375 42.1875V45.3125C48.4375 45.7269 48.6021 46.1243 48.8951 46.4174C49.1882 46.7104 49.5856 46.875 50 46.875C50.4144 46.875 50.8118 46.7104 51.1049 46.4174C51.3979 46.1243 51.5625 45.7269 51.5625 45.3125V42.1875C53.2201 42.1875 54.8098 41.529 55.9819 40.3569C57.154 39.1848 57.8125 37.5951 57.8125 35.9375C57.8125 34.2799 57.154 32.6902 55.9819 31.5181C54.8098 30.346 53.2201 29.6875 51.5625 29.6875Z"
        fill="white"
      />
    </svg>
  );
};

export const Camera = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M187.5 89.1718L100 82.3358L12.5 89.1718V165.369C12.5 167.314 14.0764 168.89 16.0212 168.89H183.979C185.924 168.89 187.5 167.314 187.5 165.369V89.1718Z"
        fill="#242E5F"
      />
      <path
        d="M29.9316 41.2974V52.4806L38.765 55.8986L47.5984 52.4806V41.2974C47.5984 39.3526 46.0221 37.7762 44.0772 37.7762H33.4528C31.508 37.7762 29.9316 39.3529 29.9316 41.2974Z"
        fill="#EF9021"
      />
      <path
        d="M75.7795 34.4909L60.9277 57.6518H139.071L124.219 34.4916C122.924 32.4719 120.691 31.25 118.292 31.25H81.708C79.3089 31.25 77.0746 32.4712 75.7795 34.4909Z"
        fill="#EF9021"
      />
      <path
        d="M187.5 89.1714H12.5V56.0011C12.5 54.0562 14.0764 52.4799 16.0212 52.4799H183.979C185.924 52.4799 187.5 54.0562 187.5 56.0007V89.1714Z"
        fill="#242E5F"
      />
      <path
        opacity="0.1"
        d="M187.5 89.172H12.5V56.0017C12.5 54.0568 14.0764 52.4805 16.0212 52.4805H183.979C185.924 52.4805 187.5 54.0568 187.5 56.0013V89.172Z"
        fill="white"
      />
      <path
        d="M153.988 66.6007V75.0513C153.988 76.0237 154.776 76.8119 155.749 76.8119H167.53C168.502 76.8119 169.291 76.0237 169.291 75.0513V66.6007C169.291 65.6283 168.502 64.8401 167.53 64.8401H155.749C154.776 64.8404 153.988 65.6286 153.988 66.6007Z"
        fill="white"
      />
      <path
        d="M99.9988 66.4209C75.5521 66.4209 55.7344 86.2393 55.7344 110.686C55.7344 135.133 75.5521 154.951 99.9988 154.951C124.445 154.951 144.263 135.133 144.263 110.686C144.263 86.2397 124.445 66.4209 99.9988 66.4209Z"
        fill="white"
      />
      <path
        d="M99.9999 82.9532C84.6837 82.9532 72.2676 95.369 72.2676 110.685C72.2676 126.001 84.6837 138.418 99.9999 138.418C115.316 138.418 127.732 126.002 127.732 110.686C127.732 95.3693 115.316 82.9532 99.9999 82.9532Z"
        fill="#242E5F"
      />
      <path
        d="M188.75 18.1049C178.762 18.1049 170.605 26.2482 170.605 36.25C170.605 26.2214 162.529 18.1049 152.5 18.1049C162.529 18.1049 170.605 10.0954 170.605 0C170.605 9.85476 178.561 18.1049 188.75 18.1049Z"
        fill="#32D399"
      />
      <path
        d="M48.75 189.989C43.2391 189.989 38.7389 194.482 38.7389 200C38.7389 194.467 34.283 189.989 28.75 189.989C34.283 189.989 38.7389 185.57 38.7389 180C38.7389 185.437 43.1285 189.989 48.75 189.989Z"
        fill="#32D399"
      />
    </svg>
  );
};

export const Photos = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M91.7211 166.591C75.2168 166.591 61.7836 153.322 61.7836 137.019V38L27.7548 45.6953C19.344 47.8803 14.3008 56.4457 16.5257 64.8303L45.7023 172.435C47.6396 179.394 54.1131 184 61.0587 184C62.3614 184 63.686 183.839 64.9965 183.499L136 166.591H91.7211Z"
        fill="#EF9021"
      />
      <path
        d="M184 35.2504V136.75C184 147.389 175.389 156 164.75 156H91.2503C84.0406 156 77.7409 152.01 74.4507 146.129C72.91 143.399 72 140.18 72 136.75V35.2504C72 24.6094 80.6107 16 91.2503 16H164.75C175.389 16 184 24.6094 184 35.2504Z"
        fill="#F4F4F4"
      />
      <path
        d="M122 63.9994C122 71.7322 115.732 78 108 78C100.269 78 94 71.7322 94 63.9994C94 56.2678 100.269 50 108 50C115.732 50 122 56.2678 122 63.9994Z"
        fill="#EF9021"
      />
      <path
        d="M184 100.131V136.938C184 147.474 175.354 156 164.671 156H90.8687C83.6293 156 77.3037 152.049 74 146.226L143.655 77.5344C148.434 72.8219 156.306 72.8219 161.086 77.5344L184 100.131Z"
        fill="#242E5F"
      />
      <path
        d="M164 156H91.3726C84.117 156 77.7773 152.031 74.4662 146.182C72.9158 143.466 72 140.264 72 136.852V120.768L91.4435 101.551C96.2328 96.8162 104.123 96.8162 108.914 101.551L164 156Z"
        fill="#242E5F"
      />
      <path
        opacity="0.1"
        d="M164 156H91.3726C84.117 156 77.7773 152.031 74.4662 146.182C72.9158 143.466 72 140.264 72 136.852V120.768L91.4435 101.551C96.2328 96.8162 104.123 96.8162 108.914 101.551L164 156Z"
        fill="white"
      />
      <path
        d="M200 23.9734C186.774 23.9734 175.973 34.7562 175.973 48C175.973 34.7208 165.279 23.9734 152 23.9734C165.279 23.9734 175.973 13.3678 175.973 0C175.973 13.0491 186.508 23.9734 200 23.9734Z"
        fill="#32D399"
      />
      <path
        d="M30 155.984C22.2848 155.984 15.9845 162.274 15.9845 170C15.9845 162.253 9.74622 155.984 2 155.984C9.74622 155.984 15.9845 149.797 15.9845 142C15.9845 149.611 22.1298 155.984 30 155.984Z"
        fill="#32D399"
      />
      <path
        d="M174.286 178.295H167.722V171.714C167.722 170.771 166.951 170 166.009 170C165.066 170 164.295 170.771 164.295 171.714V178.295H157.714C156.771 178.295 156 179.066 156 180.009C156 180.951 156.771 181.722 157.714 181.722H164.295V188.286C164.295 189.229 165.066 190 166.009 190C166.951 190 167.722 189.229 167.722 188.286V181.722H174.286C175.229 181.722 176 180.951 176 180.009C176 179.066 175.246 178.295 174.286 178.295Z"
        fill="#EF9021"
      />
    </svg>
  );
};

export const HighValue = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M143.75 6.25L93.75 59.375H118.75V175H168.75V59.375H193.75L143.75 6.25Z"
        fill="#EF9021"
      />
      <path
        d="M128.125 93.75H34.375C32.6491 93.75 31.25 95.1491 31.25 96.875V190.625C31.25 192.351 32.6491 193.75 34.375 193.75H128.125C129.851 193.75 131.25 192.351 131.25 190.625V96.875C131.25 95.1491 129.851 93.75 128.125 93.75Z"
        fill="#242E5F"
      />
      <path
        d="M65.625 93.75V128.125L81.25 115.625L96.875 128.125V93.75H65.625Z"
        fill="#32D399"
      />
      <path
        d="M34.375 62.5C49.908 62.5 62.5 49.908 62.5 34.375C62.5 18.842 49.908 6.25 34.375 6.25C18.842 6.25 6.25 18.842 6.25 34.375C6.25 49.908 18.842 62.5 34.375 62.5Z"
        fill="#EF9021"
      />
      <path
        d="M35.9368 31.25H32.8118C31.983 31.25 31.1882 30.9208 30.6021 30.3347C30.0161 29.7487 29.6868 28.9538 29.6868 28.125C29.6868 27.2962 30.0161 26.5013 30.6021 25.9153C31.1882 25.3292 31.983 25 32.8118 25H35.9368C36.7656 25 37.5605 25.3292 38.1465 25.9153C38.7326 26.5013 39.0618 27.2962 39.0618 28.125H45.3118C45.3059 25.9138 44.5186 23.7759 43.089 22.089C41.6594 20.4021 39.6796 19.2748 37.4993 18.9063V15.625H31.2493V18.75C28.7629 18.9572 26.4607 20.1436 24.849 22.0483C23.2374 23.953 22.4484 26.4198 22.6556 28.9062C22.8628 31.3927 24.0492 33.6949 25.9539 35.3066C27.8585 36.9182 30.3254 37.7072 32.8118 37.5H35.9368C36.7656 37.5 37.5605 37.8292 38.1465 38.4153C38.7326 39.0013 39.0618 39.7962 39.0618 40.625C39.0618 41.4538 38.7326 42.2487 38.1465 42.8347C37.5605 43.4208 36.7656 43.75 35.9368 43.75H32.8118C31.983 43.75 31.1882 43.4208 30.6021 42.8347C30.0161 42.2487 29.6868 41.4538 29.6868 40.625H23.4368C23.4055 42.863 24.176 45.0383 25.6088 46.7577C27.0417 48.4771 29.0424 49.6272 31.2493 50V53.125H37.4993V50C39.9857 49.7928 42.288 48.6064 43.8996 46.7017C45.5113 44.797 46.3003 42.3302 46.0931 39.8438C45.8859 37.3573 44.6994 35.0551 42.7948 33.4434C40.8901 31.8318 38.4232 31.0428 35.9368 31.25Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};

export const FreeShipping = () => {
  return (
    <svg
      width="200"
      height="201"
      viewBox="0 0 200 201"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1052_26185)">
        <path
          d="M82.4623 151.284C88.8414 145.333 89.8202 136.015 84.6485 130.471C79.4768 124.928 70.1131 125.259 63.734 131.21C57.3549 137.162 56.3761 146.48 61.5478 152.024C66.7195 157.567 76.0832 157.236 82.4623 151.284Z"
          fill="#202330"
        />
        <path
          d="M166.88 151.282C173.259 145.331 174.238 136.012 169.066 130.469C163.895 124.926 154.531 125.257 148.152 131.208C141.773 137.16 140.794 146.478 145.966 152.021C151.137 157.565 160.501 157.234 166.88 151.282Z"
          fill="#202330"
        />
        <path
          d="M15.5 78.541C15.5 74.8573 12.5138 71.8711 8.83008 71.8711C5.14638 71.8711 2.16016 74.8573 2.16016 78.541C2.16016 82.2247 5.14638 85.2109 8.83008 85.2109C12.5138 85.2109 15.5 82.2247 15.5 78.541Z"
          fill="#32D399"
        />
        <path
          d="M135.391 139.93C137.762 128.211 148.832 118.898 160.73 118.898C172.629 118.898 180.449 127.535 180.223 138.641C192.613 138.965 196.062 122.648 196.062 122.648C197.105 118.086 198.664 109.191 199.969 100.461C200.387 97.6641 200.059 94.918 199.02 92.4883C197.012 87.793 193.074 79.4062 187.324 70.8164C183.035 64.4102 175.82 60.6094 167.594 60.4766C162.688 60.3945 157.867 60.3516 154.234 60.3516L154.203 60.3203C153.738 52.4727 148.016 46.4687 139.988 45.75C134.766 45.2812 114.078 44.9453 104.148 44.9453C100.203 44.9453 94.5391 45 88.8125 45.0938V45.0703H88.7734L88.7539 45.0625L88.7383 45.0703H19.9102C16.2266 45.0703 13.2383 48.0586 13.2383 51.7422V51.7773C13.2383 55.4414 16.2109 58.4141 19.875 58.4141L33.7539 58.4336C37.1953 58.6797 39.9063 61.5469 39.9063 65.0508V65.0859C39.9063 68.7695 36.918 71.7578 33.2344 71.7578H26.125C22.4414 71.7578 19.4531 74.7461 19.4531 78.4297C19.4531 82.1133 22.4414 85.1016 26.125 85.1016H33.2344C36.918 85.1016 39.9063 88.0898 39.9063 91.7734C39.9063 95.457 36.918 98.4453 33.2344 98.4453H6.625C2.94141 98.4453 -0.046875 101.434 -0.046875 105.117C-0.046875 108.801 2.94141 111.789 6.625 111.789H33.2422C36.9258 111.789 39.9141 114.777 39.9141 118.461C39.9141 122.145 36.9258 125.133 33.2422 125.133H25.0156C21.332 125.133 18.3438 128.121 18.3438 131.805C18.3438 135.488 21.332 138.477 25.0156 138.477L51.2852 138.457L53.4727 133.016C57.9453 124.762 66.8438 118.918 76.2656 118.918C88.168 118.918 96.625 128.238 95.6914 139.965H135.383M153.602 69.6094C157.086 69.6133 161.605 69.6602 166.137 69.7344C171.629 69.8242 176.434 72.3438 179.316 76.6523C182.805 81.8672 185.594 87.0273 187.613 91.1562C188.898 93.7812 186.531 97.1992 183.426 97.1992H150.125L153.602 69.6094Z"
          fill="#242E5F"
        />
        <path
          d="M139.984 45.7461C134.762 45.2773 114.074 44.9414 104.145 44.9414C100.199 44.9414 94.5352 44.9961 88.8086 45.0898V45.0664H88.7695L88.75 45.0586L88.7344 45.0664H19.9102C16.2266 45.0664 13.2383 48.0547 13.2383 51.7383V51.7734C13.2383 55.4375 16.2109 58.4102 19.875 58.4102L33.7539 58.4297C37.1953 58.6758 39.9062 61.543 39.9062 65.0469V65.082C39.9062 68.7656 36.918 71.7539 33.2344 71.7539H26.125C22.4414 71.7539 19.4531 74.7422 19.4531 78.4258C19.4531 82.1094 22.4414 85.0977 26.125 85.0977H33.2344C36.918 85.0977 39.9062 88.0859 39.9062 91.7695C39.9062 95.4531 36.918 98.4414 33.2344 98.4414H6.625C3.30469 98.4414 0.558594 100.871 0.046875 104.051V106.176C0.558594 109.352 3.30469 111.785 6.625 111.785H33.2422C36.9258 111.785 39.9141 114.773 39.9141 118.457C39.9141 120.043 39.3594 121.496 38.4336 122.641C41.4805 122.898 44.5586 123.031 47.6719 123.031C53.6562 123.031 59.5273 122.535 65.2461 121.602C68.6562 119.883 72.418 118.906 76.2656 118.906C76.5977 118.906 76.9258 118.918 77.2539 118.93C111.219 109.281 138.367 83.4375 149.828 50.2734C147.344 47.7539 143.945 46.1094 139.98 45.7539L139.984 45.7461Z"
          fill="#EF9021"
        />
        <path
          d="M115.364 69.6797H102.633C101.118 69.6797 99.7152 70.9102 99.5004 72.4258L96.4183 94.3477C96.2035 95.8633 97.2621 97.0937 98.7777 97.0937H111.508C112.844 97.0937 114.078 96.0117 114.266 94.6758C114.453 93.3398 113.524 92.2578 112.188 92.2578H101.926C101.907 92.2578 101.891 92.2383 101.891 92.2187L102.871 85.2422C102.871 85.2422 102.887 85.2148 102.907 85.2148H110.891C112.227 85.2148 113.461 84.1328 113.649 82.7969C113.836 81.4609 112.907 80.3789 111.571 80.3789H103.594C103.575 80.3789 103.559 80.3594 103.559 80.3398L104.375 74.543C104.375 74.543 104.391 74.5156 104.411 74.5156H114.68C116.016 74.5156 117.25 73.4336 117.438 72.0977C117.625 70.7617 116.696 69.6797 115.36 69.6797H115.364Z"
          fill="white"
        />
        <path
          d="M69.9453 69.6797H58.5273C57.0117 69.6797 55.6093 70.9102 55.3945 72.4258L52.2929 94.5039C52.0937 95.9336 53.0898 97.0937 54.5195 97.0937C55.9492 97.0937 57.2695 95.9336 57.4726 94.5039L58.6679 86.0039C58.6679 86.0039 58.6836 85.9766 58.7031 85.9766H65.375C66.8047 85.9766 68.125 84.8164 68.3281 83.3867C68.5273 81.957 67.5312 80.7969 66.1015 80.7969H59.4375C59.4179 80.7969 59.4023 80.7773 59.4023 80.7578L60.2265 74.8867C60.2265 74.8867 60.2422 74.8594 60.2617 74.8594H69.2187C70.6484 74.8594 71.9726 73.6992 72.1718 72.2695C72.3711 70.8398 71.375 69.6797 69.9453 69.6797Z"
          fill="white"
        />
        <path
          d="M93.1445 71.4492C90.9531 69.5664 87.082 69.6797 87.082 69.6797H78.2539C76.7383 69.6797 75.3359 70.9102 75.1211 72.4258L72.0195 94.5039C71.8203 95.9336 72.8164 97.0938 74.2461 97.0938C75.6758 97.0938 76.9961 95.9336 77.1992 94.5039L78.1367 87.8321C78.1367 87.8321 78.1523 87.8047 78.1718 87.8047H83.8945C83.8945 87.8047 83.9219 87.8125 83.9258 87.8281L86.6133 95.6367C86.9218 96.5313 87.7461 97.0977 88.7383 97.0977H88.8359C90.75 97.0977 92.2851 95.0938 91.6875 93.375L89.4648 86.9727C89.4648 86.9727 89.4648 86.9375 89.4843 86.9297C90.7461 86.3985 91.7968 85.6367 92.6367 84.6367C94.0078 83.0039 94.8554 81.043 95.1797 78.7461C95.3945 77.211 95.3437 75.8164 95.0234 74.5664C94.7031 73.3164 94.0781 72.2774 93.1484 71.4531L93.1445 71.4492ZM89.957 78.7422C89.957 78.7422 89.4375 82.9688 84.9843 82.9688H78.8164L80.0039 74.5156H86.1719C86.1719 74.5156 90.7031 74.2266 89.957 78.7422Z"
          fill="white"
        />
        <path
          d="M136.696 69.6797H123.965C122.45 69.6797 121.047 70.9102 120.832 72.4258L117.75 94.3477C117.536 95.8633 118.594 97.0937 120.11 97.0937H132.84C134.176 97.0937 135.411 96.0117 135.598 94.6758C135.786 93.3398 134.856 92.2578 133.52 92.2578H123.258C123.239 92.2578 123.223 92.2383 123.223 92.2187L124.203 85.2422C124.203 85.2422 124.219 85.2148 124.239 85.2148H132.223C133.559 85.2148 134.793 84.1328 134.981 82.7969C135.168 81.4609 134.239 80.3789 132.903 80.3789H124.926C124.907 80.3789 124.891 80.3594 124.891 80.3398L125.707 74.543C125.707 74.543 125.723 74.5156 125.743 74.5156H136.012C137.348 74.5156 138.582 73.4336 138.77 72.0977C138.957 70.7617 138.028 69.6797 136.692 69.6797H136.696Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1052_26185">
          <rect
            width="200"
            height="200"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
