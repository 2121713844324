export const Message = ({
  color = 'currentColor',
  width = 21,
  height = 20,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.59928 15.8334H7.18262C3.84928 15.8334 2.18262 15.0001 2.18262 10.8334V6.66675C2.18262 3.33341 3.84928 1.66675 7.18262 1.66675H13.8493C17.1826 1.66675 18.8493 3.33341 18.8493 6.66675V10.8334C18.8493 14.1667 17.1826 15.8334 13.8493 15.8334H13.4326C13.1743 15.8334 12.9243 15.9584 12.766 16.1667L11.516 17.8334C10.966 18.5667 10.066 18.5667 9.51595 17.8334L8.26595 16.1667C8.13262 15.9834 7.82428 15.8334 7.59928 15.8334Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8457 9.16667H13.8532"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5117 9.16667H10.5192"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.17771 9.16667H7.1852"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
