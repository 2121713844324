import { BaseIconProps } from './types';

export const Check = ({ height = 21, width = 20 }: BaseIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.015 18.3332C14.6173 18.3332 18.3483 14.6022 18.3483 9.99984C18.3483 5.39746 14.6173 1.6665 10.015 1.6665C5.4126 1.6665 1.68164 5.39746 1.68164 9.99984C1.68164 14.6022 5.4126 18.3332 10.015 18.3332Z"
      fill="currentFill"
    />
    <path
      d="M6.68164 10.3157L8.9518 12.4998L13.3483 6.6665"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
