export const Return = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.8584 24H24.0013V48H6.8584V24Z" fill="#EF9021" />
    <path opacity="0.2" d="M6.8584 24H24.0013V48H6.8584V24Z" fill="#202330" />
    <path d="M24 24H41.1429V48H24V24Z" fill="#EF9021" />
    <path
      d="M6.85714 23.9997L0 17.1426H17.1429L24 23.9997H6.85714Z"
      fill="#EF9021"
    />
    <path
      d="M24 23.9997L30.8571 17.1426H48L41.1429 23.9997H24Z"
      fill="#EF9021"
    />
    <path
      opacity="0.2"
      d="M24 23.9997L30.8571 17.1426H48L41.1429 23.9997H24Z"
      fill="#202330"
    />
    <path d="M34.2871 42.8574H37.7157V44.5717H34.2871V42.8574Z" fill="white" />
    <path d="M34.2871 39.4287H37.7157V41.143H34.2871V39.4287Z" fill="white" />
    <path
      d="M13.7129 24V29.1429L15.4272 27.4286L17.1415 29.1429V24H13.7129Z"
      fill="white"
    />
    <path
      d="M18.8557 3.42857V0L13.7129 5.14286L18.8557 10.2857V6.85714H29.1415C30.0886 6.85714 30.8557 7.62429 30.8557 8.57143C30.8557 9.51857 30.0886 10.2857 29.1415 10.2857H23.9986V13.7143H29.1415C31.982 13.7143 34.2843 11.412 34.2843 8.57143C34.2843 5.73086 31.982 3.42857 29.1415 3.42857H18.8557Z"
      fill="#242E5F"
    />
    <path
      d="M29.1416 10.286H30.8559C31.803 10.286 32.5702 9.51885 32.5702 8.57171C32.5702 7.62456 31.803 6.85742 30.8559 6.85742H29.1416C30.0887 6.85742 30.8559 7.62456 30.8559 8.57171C30.8559 9.51885 30.0887 10.286 29.1416 10.286Z"
      fill="#242E5F"
    />
  </svg>
);
