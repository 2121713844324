import React from 'react';

const Plane = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5595 0.910006C18.1495 1.50001 18.1495 2.45001 17.5595 3.03001L13.6695 6.92001L15.7895 16.11L14.3795 17.53L10.4995 10.1L6.59945 14L6.95945 16.47L5.88945 17.53L4.12945 14.35L0.939453 12.58L1.99945 11.5L4.49945 11.87L8.36945 8.00001L0.939453 4.09001L2.35945 2.68001L11.5495 4.80001L15.4395 0.910006C15.9995 0.330006 16.9995 0.330006 17.5595 0.910006Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Plane;
