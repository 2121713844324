export const ArchiveBox = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.95574 2.91537C4.03514 2.75656 4.19745 2.65625 4.375 2.65625H15.625C15.8025 2.65625 15.9649 2.75656 16.0443 2.91537L17.29 5.40693C17.3224 5.4683 17.3414 5.53771 17.3436 5.61137C17.3437 5.61591 17.3438 5.62045 17.3438 5.625V16.25C17.3438 16.5401 17.2285 16.8183 17.0234 17.0234C16.8183 17.2285 16.5401 17.3438 16.25 17.3438H3.75C3.45992 17.3438 3.18172 17.2285 2.9766 17.0234C2.77148 16.8183 2.65625 16.5401 2.65625 16.25V5.625C2.65625 5.54627 2.67566 5.47207 2.70995 5.40693L3.95574 2.91537ZM16.1165 5.15625H3.88345L4.6647 3.59375H15.3353L16.1165 5.15625Z"
      fill="currentColor"
    />
    <path
      d="M13.0176 9.16992L8.84854 13.5449L6.76758 11.3574"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);
