export const Lightning = () => (
  <svg
    width="16"
    height="30"
    viewBox="0 0 16 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66667 18.9987H0L9.33333 0.332031V10.9987H16L6.66667 29.6654V18.9987Z"
      fill="url(#paint0_linear_2094_41846)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2094_41846"
        x1="0"
        y1="0.332031"
        x2="24.6624"
        y2="13.7843"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF9021" />
        <stop offset="1" stopColor="#EF5F21" />
      </linearGradient>
    </defs>
  </svg>
);
