export const DocumentSmall = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66667 11.6667H3.83333V10H9.66667M12.1667 8.33333H3.83333V6.66667H12.1667M12.1667 5H3.83333V3.33333H12.1667M13.8333 0H2.16667C1.24167 0 0.5 0.741667 0.5 1.66667V13.3333C0.5 13.7754 0.675595 14.1993 0.988155 14.5118C1.30072 14.8244 1.72464 15 2.16667 15H13.8333C14.2754 15 14.6993 14.8244 15.0118 14.5118C15.3244 14.1993 15.5 13.7754 15.5 13.3333V1.66667C15.5 1.22464 15.3244 0.800716 15.0118 0.488155C14.6993 0.175595 14.2754 0 13.8333 0Z"
      fill="currentColor"
    />
  </svg>
);
