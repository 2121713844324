import { BaseIconProps } from './types';

interface TrashProps extends BaseIconProps {
  color?: string;
  className?: string;
}
export function Trash({
  width = 20,
  height = 21,
  color = '#FF5555',
  className,
}: TrashProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      className={className}
    >
      <path
        d="M17.5 5.48356C14.725 5.20856 11.9333 5.06689 9.15 5.06689C7.5 5.06689 5.85 5.15023 4.2 5.31689L2.5 5.48356"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.0835 4.6415L7.26683 3.54984C7.40016 2.75817 7.50016 2.1665 8.9085 2.1665H11.0918C12.5002 2.1665 12.6085 2.7915 12.7335 3.55817L12.9168 4.6415"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7082 8.1167L15.1665 16.5084C15.0748 17.8167 14.9998 18.8334 12.6748 18.8334H7.32484C4.99984 18.8334 4.92484 17.8167 4.83317 16.5084L4.2915 8.1167"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6084 14.25H11.3834"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9165 10.9165H12.0832"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
