export const Paperclip = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5003 6.24894L5.99253 12.8661C5.77708 13.1039 5.66132 13.4154 5.66922 13.7362C5.67711 14.057 5.80806 14.3624 6.03495 14.5893C6.26184 14.8162 6.5673 14.9472 6.88807 14.9551C7.20884 14.963 7.52037 14.8472 7.75815 14.6318L15.516 6.76457C15.9469 6.289 16.1784 5.66595 16.1626 5.0244C16.1468 4.38286 15.8849 3.77195 15.4311 3.31817C14.9773 2.86439 14.3664 2.60249 13.7249 2.5867C13.0833 2.5709 12.4603 2.80242 11.9847 3.23332L4.2269 11.1005C3.52449 11.8029 3.12988 12.7556 3.12988 13.7489C3.12988 14.7423 3.52449 15.695 4.2269 16.3974C4.92931 17.0998 5.88198 17.4944 6.87534 17.4944C7.8687 17.4944 8.82137 17.0998 9.52378 16.3974L15.9378 9.99894"
      stroke="#242E5F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
