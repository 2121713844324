export function LeftArrow() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 12H3.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 5.25L3.75 12L10.5 18.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function RightArrow({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 12H20.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 5.25L20.25 12L13.5 18.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const ArrowUpRight = ({
  width = 20,
  height = 21,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 15.5L15 5.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 5.5H15V13.625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowFatLineUp = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8476 6.26852C10.8189 6.33677 10.7707 6.395 10.709 6.43587C10.6472 6.47675 10.5748 6.49843 10.5007 6.4982H8.62574V9.1232C8.62574 9.22266 8.58624 9.31804 8.51591 9.38837C8.44558 9.45869 8.3502 9.4982 8.25074 9.4982H3.75074C3.65129 9.4982 3.55591 9.45869 3.48558 9.38837C3.41525 9.31804 3.37574 9.22266 3.37574 9.1232V6.4982H1.50074C1.42671 6.49843 1.35426 6.47675 1.29254 6.43587C1.23081 6.395 1.18256 6.33677 1.15387 6.26852C1.12685 6.19925 1.12 6.12375 1.1341 6.05075C1.1482 5.97775 1.18268 5.91024 1.23356 5.85602L5.73356 1.35602C5.80466 1.2856 5.90068 1.24609 6.00074 1.24609C6.10081 1.24609 6.19683 1.2856 6.26793 1.35602L10.7679 5.85602C10.8188 5.91024 10.8533 5.97775 10.8674 6.05075C10.8815 6.12375 10.8746 6.19925 10.8476 6.26852ZM8.25074 10.2482H3.75074C3.65129 10.2482 3.55591 10.2877 3.48558 10.358C3.41525 10.4284 3.37574 10.5237 3.37574 10.6232C3.37574 10.7227 3.41525 10.818 3.48558 10.8884C3.55591 10.9587 3.65129 10.9982 3.75074 10.9982H8.25074C8.3502 10.9982 8.44558 10.9587 8.51591 10.8884C8.58624 10.818 8.62574 10.7227 8.62574 10.6232C8.62574 10.5237 8.58624 10.4284 8.51591 10.358C8.44558 10.2877 8.3502 10.2482 8.25074 10.2482Z"
      fill="#FF4242"
    />
  </svg>
);
