export const Shipping = () => (
  <svg
    width="47"
    height="40"
    viewBox="0 0 47 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.5003 9.3195L33.4398 15.486L33.3828 15.5165H33.3783L26.7693 18.639L16.8968 13.977L7.0293 9.3195L13.6473 6.1925L20.4448 2.982L26.7648 0L36.6283 4.6575H36.6323L46.5003 9.3195Z"
      fill="#EF9021"
    />
    <path
      opacity="0.2"
      d="M46.4983 9.3195L33.4378 15.486L33.3808 15.5165H33.3763L26.7673 18.639L16.8948 13.977L7.02734 9.3195L13.6453 6.1925L20.4428 2.982L26.7628 0L36.6263 4.6575H36.6303L46.4983 9.3195Z"
      fill="white"
    />
    <path
      d="M46.5001 9.32031V30.1513L26.7646 39.4713V18.6403L46.5001 9.32031Z"
      fill="#EF9021"
    />
    <path
      d="M37.6042 25.7266V30.2251L30.1357 33.7521V29.2536L37.6042 25.7266Z"
      fill="white"
    />
    <path
      d="M26.7648 18.6388V39.4708L16.8968 34.8133L7.0293 30.1513V9.31934L16.8968 13.9813L26.7648 18.6388Z"
      fill="#EF9021"
    />
    <path
      opacity="0.2"
      d="M26.7638 18.6398V39.4718L16.8958 34.8143L7.02832 30.1523V9.32031L16.8958 13.9823L26.7638 18.6398Z"
      fill="#202330"
    />
    <path
      d="M12.3155 27.6436H1.5C1.26794 27.6436 1.04538 27.5514 0.881282 27.3873C0.717188 27.2232 0.625 27.0006 0.625 26.7686C0.625 26.5365 0.717188 26.3139 0.881282 26.1498C1.04538 25.9857 1.26794 25.8936 1.5 25.8936H12.3155C12.5476 25.8936 12.7701 25.9857 12.9342 26.1498C13.0983 26.3139 13.1905 26.5365 13.1905 26.7686C13.1905 27.0006 13.0983 27.2232 12.9342 27.3873C12.7701 27.5514 12.5476 27.6436 12.3155 27.6436Z"
      fill="#242E5F"
    />
    <path
      d="M9.3815 15.7764H1.5C1.26794 15.7764 1.04538 15.6842 0.881282 15.5201C0.717188 15.356 0.625 15.1334 0.625 14.9014C0.625 14.6693 0.717188 14.4467 0.881282 14.2826C1.04538 14.1186 1.26794 14.0264 1.5 14.0264H9.3815C9.61356 14.0264 9.83613 14.1186 10.0002 14.2826C10.1643 14.4467 10.2565 14.6693 10.2565 14.9014C10.2565 15.1334 10.1643 15.356 10.0002 15.5201C9.83613 15.6842 9.61356 15.7764 9.3815 15.7764Z"
      fill="#242E5F"
    />
    <path
      d="M13.6711 21.71H4.12109C3.88903 21.71 3.66647 21.6178 3.50237 21.4537C3.33828 21.2896 3.24609 21.067 3.24609 20.835C3.24609 20.6029 3.33828 20.3803 3.50237 20.2162C3.66647 20.0521 3.88903 19.96 4.12109 19.96H13.6711C13.9032 19.96 14.1257 20.0521 14.2898 20.2162C14.4539 20.3803 14.5461 20.6029 14.5461 20.835C14.5461 21.067 14.4539 21.2896 14.2898 21.4537C14.1257 21.6178 13.9032 21.71 13.6711 21.71Z"
      fill="#242E5F"
    />
  </svg>
);
