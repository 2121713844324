import { BaseIconProps } from './types';

export function ChevronUp({
  width = '20',
  height = '20',
  strokeWidth = '1.5',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 12.5L10 6.25L3.75 12.5"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronDown({ width = '12', height = '7', strokeWidth = '2' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1L6 6L1 1"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronRight({
  width = 20,
  height = 20,
  className,
}: BaseIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.73086 15.7941C7.8749 15.9312 8.06747 16.0054 8.26624 16.0005C8.46501 15.9957 8.65371 15.9121 8.79086 15.7681L13.7929 10.5181C13.9258 10.3786 14 10.1933 14 10.0006C14 9.8079 13.9258 9.62259 13.7929 9.48311L8.79186 4.23311C8.7247 4.15889 8.64336 4.09886 8.55265 4.05657C8.46193 4.01428 8.36366 3.99057 8.26364 3.98685C8.16361 3.98312 8.06386 3.99946 7.97025 4.03489C7.87663 4.07032 7.79106 4.12413 7.71857 4.19315C7.64608 4.26217 7.58814 4.345 7.54816 4.43677C7.50818 4.52853 7.48698 4.62736 7.48579 4.72745C7.48461 4.82754 7.50347 4.92685 7.54126 5.01953C7.57906 5.11221 7.63502 5.19639 7.70586 5.26711L12.2139 10.0011L7.70586 14.7341C7.56867 14.878 7.49424 15.0705 7.49893 15.2693C7.50362 15.4681 7.58704 15.6568 7.73086 15.7941V15.7941Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ChevronDoubleRight() {
  return (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.73086 12.794C4.8749 12.931 5.06747 13.0053 5.26624 13.0004C5.46501 12.9955 5.65371 12.9119 5.79086 12.768L10.7929 7.51799C10.9258 7.3785 11 7.19319 11 7.00049C11 6.80778 10.9258 6.62247 10.7929 6.48299L5.79186 1.23299C5.7247 1.15877 5.64336 1.09874 5.55265 1.05645C5.46193 1.01416 5.36366 0.990449 5.26364 0.986725C5.16361 0.983002 5.06386 0.999337 4.97025 1.03477C4.87663 1.0702 4.79106 1.12401 4.71857 1.19303C4.64608 1.26205 4.58814 1.34488 4.54816 1.43664C4.50818 1.52841 4.48698 1.62724 4.48579 1.72733C4.48461 1.82741 4.50347 1.92672 4.54126 2.01941C4.57906 2.11209 4.63502 2.19627 4.70586 2.26699L9.21386 7.00099L4.70586 11.734C4.56867 11.8779 4.49424 12.0704 4.49893 12.2692C4.50362 12.4679 4.58704 12.6567 4.73086 12.794ZM0.730857 12.795C0.874899 12.932 1.06747 13.0063 1.26624 13.0014C1.46501 12.9965 1.65371 12.9129 1.79086 12.769L6.79186 7.51899C6.92482 7.3795 6.999 7.19419 6.999 7.00149C6.999 6.80878 6.92482 6.62347 6.79186 6.48399L1.79086 1.23399C1.7237 1.15977 1.64236 1.09974 1.55165 1.05745C1.46093 1.01516 1.36266 0.991449 1.26264 0.987725C1.16261 0.984002 1.06286 1.00034 0.969246 1.03577C0.875634 1.0712 0.790062 1.12501 0.717571 1.19403C0.645081 1.26305 0.587139 1.34588 0.54716 1.43764C0.507181 1.52941 0.485974 1.62824 0.48479 1.72833C0.483605 1.82841 0.502466 1.92772 0.540261 2.02041C0.578056 2.11309 0.634021 2.19727 0.704857 2.26799L5.21286 7.00099L0.704857 11.735C0.567672 11.8789 0.493242 12.0714 0.497931 12.2702C0.502619 12.4689 0.586039 12.6577 0.729856 12.795H0.730857Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ChevronLeft({
  width = 24,
  height = 24,
  className,
}: BaseIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.2691 15.7941C12.1251 15.9312 11.9325 16.0054 11.7338 16.0005C11.535 15.9957 11.3463 15.9121 11.2091 15.7681L6.20714 10.5181C6.07418 10.3786 6 10.1933 6 10.0006C6 9.8079 6.07418 9.62259 6.20714 9.48311L11.2081 4.23311C11.2753 4.15889 11.3566 4.09886 11.4474 4.05657C11.5381 4.01428 11.6363 3.99057 11.7364 3.98685C11.8364 3.98312 11.9361 3.99946 12.0298 4.03489C12.1234 4.07032 12.2089 4.12413 12.2814 4.19315C12.3539 4.26217 12.4119 4.345 12.4518 4.43677C12.4918 4.52853 12.513 4.62736 12.5142 4.72745C12.5154 4.82754 12.4965 4.92685 12.4587 5.01953C12.4209 5.11221 12.365 5.19639 12.2941 5.26711L7.78614 10.0011L12.2941 14.7341C12.4313 14.878 12.5058 15.0705 12.5011 15.2693C12.4964 15.4681 12.413 15.6568 12.2691 15.7941Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ChevronDoubleLeft() {
  return (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.26914 12.794C6.1251 12.931 5.93253 13.0053 5.73376 13.0004C5.53499 12.9955 5.34629 12.9119 5.20914 12.768L0.207143 7.51799C0.0741758 7.3785 0 7.19319 0 7.00049C0 6.80778 0.0741758 6.62247 0.207143 6.48299L5.20814 1.23299C5.2753 1.15877 5.35664 1.09874 5.44735 1.05645C5.53807 1.01416 5.63634 0.990449 5.73636 0.986725C5.83639 0.983002 5.93614 0.999337 6.02975 1.03477C6.12337 1.0702 6.20894 1.12401 6.28143 1.19303C6.35392 1.26205 6.41186 1.34488 6.45184 1.43664C6.49182 1.52841 6.51302 1.62724 6.51421 1.72733C6.51539 1.82741 6.49653 1.92672 6.45874 2.01941C6.42094 2.11209 6.36498 2.19627 6.29414 2.26699L1.78614 7.00099L6.29414 11.734C6.43133 11.8779 6.50576 12.0704 6.50107 12.2692C6.49638 12.4679 6.41296 12.6567 6.26914 12.794ZM10.2691 12.795C10.1251 12.932 9.93253 13.0063 9.73376 13.0014C9.53499 12.9965 9.34629 12.9129 9.20914 12.769L4.20814 7.51899C4.07518 7.3795 4.001 7.19419 4.001 7.00149C4.001 6.80878 4.07518 6.62347 4.20814 6.48399L9.20914 1.23399C9.2763 1.15977 9.35764 1.09974 9.44835 1.05745C9.53907 1.01516 9.63734 0.991449 9.73736 0.987725C9.83739 0.984002 9.93714 1.00034 10.0308 1.03577C10.1244 1.0712 10.2099 1.12501 10.2824 1.19403C10.3549 1.26305 10.4129 1.34588 10.4528 1.43764C10.4928 1.52941 10.514 1.62824 10.5152 1.72833C10.5164 1.82841 10.4975 1.92772 10.4597 2.02041C10.4219 2.11309 10.366 2.19727 10.2951 2.26799L5.78714 7.00099L10.2951 11.735C10.4323 11.8789 10.5068 12.0714 10.5021 12.2702C10.4974 12.4689 10.414 12.6577 10.2701 12.795H10.2691Z"
        fill="currentColor"
      />
    </svg>
  );
}
