export const Dashboard = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3333 9.08341V3.41675C18.3333 2.16675 17.8 1.66675 16.475 1.66675H13.1083C11.7833 1.66675 11.25 2.16675 11.25 3.41675V9.08341C11.25 10.3334 11.7833 10.8334 13.1083 10.8334H16.475C17.8 10.8334 18.3333 10.3334 18.3333 9.08341Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3333 16.5833V15.0833C18.3333 13.8333 17.8 13.3333 16.475 13.3333H13.1083C11.7833 13.3333 11.25 13.8333 11.25 15.0833V16.5833C11.25 17.8333 11.7833 18.3333 13.1083 18.3333H16.475C17.8 18.3333 18.3333 17.8333 18.3333 16.5833Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.74984 10.9167V16.5834C8.74984 17.8334 8.2165 18.3334 6.8915 18.3334H3.52484C2.19984 18.3334 1.6665 17.8334 1.6665 16.5834V10.9167C1.6665 9.66675 2.19984 9.16675 3.52484 9.16675H6.8915C8.2165 9.16675 8.74984 9.66675 8.74984 10.9167Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.74984 3.41675V4.91675C8.74984 6.16675 8.2165 6.66675 6.8915 6.66675H3.52484C2.19984 6.66675 1.6665 6.16675 1.6665 4.91675V3.41675C1.6665 2.16675 2.19984 1.66675 3.52484 1.66675H6.8915C8.2165 1.66675 8.74984 2.16675 8.74984 3.41675Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
