export const Asterisk = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3125 5.3125H1.3125M5.8125 0.8125V9.8125M3.1875 7.9375L8.4375 2.6875M8.4375 7.9375L3.1875 2.6875"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
