interface CheckpointProps {
  fill?: string;
}

export const Checkpoint = ({ fill = '#EF9021' }: CheckpointProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.2" cx="12" cy="12" r="12" fill={fill} />
    <circle cx="12" cy="12" r="6" fill={fill} />
  </svg>
);
