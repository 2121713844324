import { BaseIconProps } from './types';

const Minus = ({ height = 21, width = 20 }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8332 11.3332H4.1665V9.6665H15.8332V11.3332Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Minus;
