export const MxGetPaid = () => (
  <svg
    width="58"
    height="51"
    viewBox="0 0 58 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.4997 40.1641V47.8029C56.4997 48.2081 56.3387 48.5967 56.0522 48.8833C55.7657 49.1698 55.3771 49.3307 54.9719 49.3307H47.333C47.333 46.8996 48.2988 44.568 50.0179 42.8489C51.737 41.1298 54.0685 40.1641 56.4997 40.1641Z"
      fill="#C41719"
    />
    <path
      d="M56.4997 21.8383V29.4772C54.0685 29.4772 51.737 28.5115 50.0179 26.7924C48.2988 25.0733 47.333 22.7417 47.333 20.3105H54.9719C55.3771 20.3105 55.7657 20.4715 56.0522 20.758C56.3387 21.0445 56.4997 21.4331 56.4997 21.8383Z"
      fill="#C41719"
    />
    <path
      d="M10.6667 49.3307H3.02778C2.62259 49.3307 2.23401 49.1698 1.9475 48.8833C1.66098 48.5967 1.5 48.2081 1.5 47.8029V40.1641C3.93115 40.1641 6.26269 41.1298 7.98177 42.8489C9.70086 44.568 10.6667 46.8996 10.6667 49.3307Z"
      fill="#C41719"
    />
    <path
      d="M10.6667 20.3105C10.6667 22.7417 9.70086 25.0733 7.98177 26.7924C6.26269 28.5115 3.93115 29.4772 1.5 29.4772V21.8383C1.5 21.4331 1.66098 21.0445 1.9475 20.758C2.23401 20.4715 2.62259 20.3105 3.02778 20.3105H10.6667Z"
      fill="#C41719"
    />
    <path
      d="M56.5 21.8383V47.8105C56.5 48.2157 56.339 48.6043 56.0525 48.8908C55.766 49.1774 55.3774 49.3383 54.9722 49.3383H3.02778C2.62259 49.3383 2.23401 49.1774 1.9475 48.8908C1.66098 48.6043 1.5 48.2157 1.5 47.8105V21.8383C1.5 21.4331 1.66098 21.0445 1.9475 20.758C2.23401 20.4715 2.62259 20.3105 3.02778 20.3105H54.9722C55.3774 20.3105 55.766 20.4715 56.0525 20.758C56.339 21.0445 56.5 21.4331 56.5 21.8383ZM10.6667 20.3105C10.6667 22.7417 9.70086 25.0733 7.98177 26.7924C6.26269 28.5114 3.93115 29.4772 1.5 29.4772V21.8383C1.5 21.4331 1.66098 21.0445 1.9475 20.758C2.23401 20.4715 2.62259 20.3105 3.02778 20.3105H10.6667ZM10.6667 49.3383H3.02778C2.62259 49.3383 2.23401 49.1774 1.9475 48.8908C1.66098 48.6043 1.5 48.2157 1.5 47.8105V40.1717C3.93115 40.1717 6.26269 41.1374 7.98177 42.8565C9.70086 44.5756 10.6667 46.9072 10.6667 49.3383ZM56.5 21.8383V29.4772C54.0688 29.4772 51.7373 28.5114 50.0182 26.7924C48.2991 25.0733 47.3333 22.7417 47.3333 20.3105H54.9722C55.3774 20.3105 55.766 20.4715 56.0525 20.758C56.339 21.0445 56.5 21.4331 56.5 21.8383ZM37.0438 34.8244C37.0468 36.416 36.5776 37.9727 35.6956 39.2975C34.8136 40.6223 33.5584 41.6556 32.0888 42.2668C30.6193 42.8779 29.0015 43.0394 27.4401 42.7308C25.8788 42.4221 24.4441 41.6572 23.3176 40.5329C22.1912 39.4086 21.4236 37.9754 21.112 36.4146C20.8003 34.8539 20.9587 33.2357 21.5671 31.765C22.1755 30.2944 23.2065 29.0372 24.5296 28.1526C25.8527 27.2681 27.4084 26.796 29 26.796C30.057 26.796 31.1036 27.0043 32.08 27.409C33.0564 27.8137 33.9434 28.4069 34.6905 29.1546C35.4375 29.9024 36.0299 30.79 36.4337 31.7668C36.8374 32.7436 37.0448 33.7904 37.0438 34.8474V34.8244ZM56.5 40.1717V47.8105C56.5 48.2157 56.339 48.6043 56.0525 48.8908C55.766 49.1774 55.3774 49.3383 54.9722 49.3383H47.3333C47.3333 46.9072 48.2991 44.5756 50.0182 42.8565C51.7373 41.1374 54.0688 40.1717 56.5 40.1717Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.4285 35.1996C31.2434 34.9071 30.9783 34.6738 30.6646 34.5273C30.2984 34.3713 29.9169 34.2535 29.5264 34.176H29.4729H29.4195C29.1786 34.1319 28.9412 34.0706 28.709 33.9926C28.541 33.9355 28.391 33.8355 28.2737 33.7023C28.1489 33.5472 28.0861 33.3511 28.0979 33.1523C28.0964 33.0305 28.1241 32.9101 28.1785 32.8011C28.233 32.6922 28.3127 32.5978 28.4111 32.526C28.6779 32.3657 28.9864 32.2886 29.2972 32.3044C29.583 32.3062 29.8658 32.3633 30.1299 32.4725C30.4288 32.5927 30.7091 32.7547 30.9625 32.9537L31.5889 31.7162C31.3506 31.5421 31.0945 31.3936 30.825 31.2732C30.5809 31.1546 30.3248 31.0624 30.0611 30.9982C29.995 30.9945 29.9287 30.9945 29.8625 30.9982V30.0586H28.3958V31.0364C28.2071 31.0765 28.0229 31.1353 27.8458 31.2121C27.4656 31.3839 27.148 31.6695 26.9369 32.0294C26.72 32.4244 26.6118 32.8699 26.6236 33.3204C26.5961 33.7827 26.7135 34.242 26.9597 34.6343C27.1442 34.9199 27.4104 35.1435 27.7236 35.276C28.1228 35.4206 28.5346 35.5281 28.9535 35.5968H29.068C29.2779 35.6353 29.4847 35.6889 29.6868 35.7572C29.8398 35.8068 29.9751 35.8997 30.0764 36.0246C30.1864 36.1629 30.2408 36.3372 30.2292 36.5135C30.2386 36.6475 30.2125 36.7816 30.1534 36.9023C30.0944 37.023 30.0046 37.126 29.8931 37.201C29.6062 37.3822 29.2692 37.4678 28.9306 37.4454C28.5576 37.4446 28.1876 37.3774 27.8382 37.2468C27.4933 37.1133 27.1752 36.9193 26.8986 36.6739L26.2188 37.8885C26.391 38.0877 26.6049 38.2467 26.8452 38.3544C27.1644 38.5109 27.5 38.6313 27.8458 38.7135C28.0143 38.7551 28.1853 38.7857 28.3577 38.8051V39.7218H29.8625V38.7593C30.0666 38.7141 30.2661 38.6502 30.4584 38.5683C30.8472 38.405 31.1756 38.1246 31.3979 37.7662C31.6259 37.3806 31.7397 36.9383 31.7264 36.4905C31.7518 36.0542 31.6454 35.6204 31.4209 35.2454"
      fill="black"
    />
    <path
      d="M18.8555 20.3105L29.4125 2.01535C29.5129 1.84092 29.6468 1.68807 29.8065 1.56556C29.9662 1.44305 30.1486 1.35331 30.3431 1.30149C30.5376 1.24968 30.7404 1.2368 30.9399 1.26361C31.1394 1.29042 31.3316 1.35639 31.5055 1.45771L41.986 7.50771"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.5062 1.45771L38.1215 5.27716C37.5195 6.3203 36.7177 7.23457 35.7622 7.96772C34.8066 8.70087 33.716 9.23853 32.5526 9.54996C31.3891 9.8614 30.1757 9.9405 28.9816 9.78276C27.7876 9.62501 26.6364 9.23351 25.5938 8.63063L29.4132 2.01535C29.5136 1.84092 29.6476 1.68807 29.8073 1.56556C29.967 1.44305 30.1493 1.35331 30.3438 1.30149C30.5383 1.24968 30.7411 1.2368 30.9406 1.26361C31.1401 1.29042 31.3323 1.35639 31.5062 1.45771V1.45771Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8184 20.3111L44.2628 6.20967C44.4367 6.10834 44.629 6.04238 44.8284 6.01557C45.0279 5.98876 45.2307 6.00163 45.4252 6.05345C45.6197 6.10527 45.8021 6.19501 45.9618 6.31752C46.1215 6.44002 46.2554 6.59288 46.3559 6.76731L54.1704 20.3111"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.3246 6.76731L50.144 13.3826C49.1014 13.9855 47.9501 14.377 46.756 14.5347C45.562 14.6925 44.3486 14.6134 43.1852 14.3019C42.0217 13.9905 40.931 13.4528 39.9755 12.7197C39.0199 11.9865 38.2183 11.0723 37.6162 10.0291L44.2315 6.20967C44.4054 6.10834 44.5976 6.04238 44.7971 6.01557C44.9966 5.98876 45.1995 6.00163 45.3939 6.05345C45.5884 6.10527 45.7707 6.19501 45.9304 6.31752C46.0901 6.44002 46.2241 6.59288 46.3246 6.76731V6.76731Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8555 20.3105L29.4125 2.01535C29.5129 1.84092 29.6468 1.68807 29.8065 1.56556C29.9662 1.44305 30.1486 1.35331 30.3431 1.30149C30.5376 1.24968 30.7404 1.2368 30.9399 1.26361C31.1394 1.29042 31.3316 1.35639 31.5055 1.45771L41.986 7.50771"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.5062 1.45771L38.1215 5.27716C37.5195 6.3203 36.7177 7.23457 35.7622 7.96772C34.8066 8.70087 33.716 9.23853 32.5526 9.54996C31.3891 9.8614 30.1757 9.9405 28.9816 9.78276C27.7876 9.62501 26.6364 9.23351 25.5938 8.63063L29.4132 2.01535C29.5136 1.84092 29.6476 1.68807 29.8073 1.56556C29.967 1.44305 30.1493 1.35331 30.3438 1.30149C30.5383 1.24968 30.7411 1.2368 30.9406 1.26361C31.1401 1.29042 31.3323 1.35639 31.5062 1.45771V1.45771Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8184 20.3111L44.2628 6.20967C44.4367 6.10834 44.629 6.04238 44.8284 6.01557C45.0279 5.98876 45.2307 6.00163 45.4252 6.05345C45.6197 6.10527 45.8021 6.19501 45.9618 6.31752C46.1215 6.44002 46.2554 6.59288 46.3559 6.76731L54.1704 20.3111"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.3246 6.76731L50.144 13.3826C49.1014 13.9855 47.9501 14.377 46.756 14.5347C45.562 14.6925 44.3486 14.6134 43.1852 14.3019C42.0217 13.9905 40.931 13.4528 39.9755 12.7197C39.0199 11.9865 38.2183 11.0723 37.6162 10.0291L44.2315 6.20967C44.4054 6.10834 44.5976 6.04238 44.7971 6.01557C44.9966 5.98876 45.1995 6.00163 45.3939 6.05345C45.5884 6.10527 45.7707 6.19501 45.9304 6.31752C46.0901 6.44002 46.2241 6.59288 46.3246 6.76731V6.76731Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
