export const Protect = () => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5398 2.62009L10.0398 0.560088C9.4698 0.350088 8.5398 0.350088 7.9698 0.560088L2.4698 2.62009C1.4098 3.02009 0.549805 4.26009 0.549805 5.39009V13.4901C0.549805 14.3001 1.0798 15.3701 1.7298 15.8501L7.2298 19.9601C8.1998 20.6901 9.7898 20.6901 10.7598 19.9601L16.2598 15.8501C16.9098 15.3601 17.4398 14.3001 17.4398 13.4901V5.39009C17.4498 4.26009 16.5898 3.02009 15.5398 2.62009ZM12.4798 8.22009L8.1798 12.5201C8.0298 12.6701 7.8398 12.7401 7.6498 12.7401C7.4598 12.7401 7.2698 12.6701 7.1198 12.5201L5.5198 10.9001C5.2298 10.6101 5.2298 10.1301 5.5198 9.84009C5.8098 9.55009 6.2898 9.55009 6.5798 9.84009L7.6598 10.9201L11.4298 7.15009C11.7198 6.86009 12.1998 6.86009 12.4898 7.15009C12.7798 7.44009 12.7798 7.93009 12.4798 8.22009Z"
      fill="white"
    />
  </svg>
);
