export const Refund = ({
  width = 48,
  height = 48,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.799805 29.5811H9.1418V45.3571H0.799805V29.5811Z"
      fill="#242E5F"
    />
    <path
      d="M9.1416 42.9617C10.0502 43.5399 27.1477 48.0001 27.1477 48.0001L45.8201 39.6845C47.7778 38.8126 48.5653 36.4488 47.5217 34.577C46.6921 33.0891 44.943 32.3738 43.3085 32.854L29.7082 36.8496C29.7082 34.6432 28.2617 32.6978 26.1488 32.0624L17.8969 29.5811L9.14161 32.5545V42.9617L9.1416 42.9617Z"
      fill="#FED1A2"
    />
    <path
      d="M5.74985 31.9902H4.18951C3.73985 31.9902 3.37598 32.3545 3.37598 32.8038C3.37598 33.253 3.73985 33.6173 4.18951 33.6173H5.74985C6.19952 33.6173 6.56339 33.253 6.56339 32.8038C6.56339 32.3545 6.19952 31.9902 5.74985 31.9902Z"
      fill="#DDEAF5"
    />
    <path
      d="M25.7724 25.2215C32.5119 25.2215 37.9754 19.758 37.9754 13.0185C37.9754 6.27892 32.5119 0.81543 25.7724 0.81543C19.0328 0.81543 13.5693 6.27892 13.5693 13.0185C13.5693 19.758 19.0328 25.2215 25.7724 25.2215Z"
      fill="#EF9021"
    />
    <path
      d="M25.771 12.2047C24.4633 12.2047 23.3995 11.1409 23.3995 9.8336C23.3995 8.52631 24.4633 7.46251 25.771 7.46251C27.0787 7.46251 28.1425 8.52631 28.1425 9.8336C28.1425 10.2829 28.5064 10.6471 28.956 10.6471C29.4057 10.6471 29.7696 10.2829 29.7696 9.8336C29.7696 7.90776 28.4004 6.29648 26.5846 5.91955V4.88287C26.5846 4.4336 26.2207 4.06934 25.771 4.06934C25.3214 4.06934 24.9575 4.4336 24.9575 4.88287V5.91955C23.1417 6.29648 21.7725 7.90776 21.7725 9.8336C21.7725 12.0383 23.5664 13.8318 25.771 13.8318C27.0787 13.8318 28.1425 14.8956 28.1425 16.2033C28.1425 17.5106 27.0787 18.5743 25.771 18.5743C24.4633 18.5743 23.3995 17.5106 23.3995 16.2033C23.3995 15.754 23.0357 15.3897 22.586 15.3897C22.1363 15.3897 21.7725 15.754 21.7725 16.2033C21.7725 18.1291 23.1417 19.7404 24.9575 20.1173V21.1536C24.9575 21.6029 25.3214 21.9671 25.771 21.9671C26.2207 21.9671 26.5846 21.6029 26.5846 21.1536V20.1173C28.4004 19.7404 29.7696 18.1291 29.7696 16.2033C29.7696 13.9986 27.9757 12.2047 25.771 12.2047Z"
      fill="white"
    />
    <path
      d="M41.0274 24.4661L39.5986 24.3585C42.229 21.1624 43.6682 17.1939 43.6682 13.0178C43.6682 8.17152 41.7559 3.63073 38.2825 0.231593C37.9616 -0.0826186 37.4467 -0.0758662 37.1321 0.244309C36.8183 0.565273 36.8239 1.08049 37.1448 1.3947C40.3021 4.48478 42.0411 8.61285 42.0411 13.0178C42.0411 16.5258 40.9131 19.8664 38.8567 22.6476L38.9054 21.9992C38.9395 21.5512 38.6035 21.1607 38.1554 21.1269C37.7089 21.0876 37.3164 21.4288 37.2831 21.8769L37.0495 24.9825C37.0153 25.4306 37.3514 25.821 37.7995 25.8548L40.9051 26.0884C40.9257 26.09 40.9464 26.0908 40.967 26.0908C41.3889 26.0908 41.7456 25.7654 41.7774 25.3384C41.8115 24.8903 41.4755 24.4998 41.0274 24.4661L41.0274 24.4661Z"
      fill="#242E5F"
    />
  </svg>
);
