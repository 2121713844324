export const Send = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7899 3.77266L13.6961 18.2961C13.6279 18.542 13.4855 18.761 13.2884 18.9231C13.0913 19.0853 12.849 19.1828 12.5946 19.2024H12.4852C12.2498 19.2018 12.0192 19.1348 11.8202 19.009C11.6212 18.8833 11.4617 18.7038 11.3602 18.4914L8.57113 12.6008C8.54375 12.5416 8.53506 12.4755 8.54623 12.4113C8.5574 12.3471 8.58789 12.2878 8.63363 12.2414L12.6414 8.2336C12.7042 8.17675 12.7547 8.10775 12.79 8.03078C12.8253 7.9538 12.8445 7.87047 12.8466 7.78583C12.8487 7.70119 12.8335 7.61701 12.8021 7.53839C12.7707 7.45978 12.7236 7.38837 12.6637 7.3285C12.6039 7.26864 12.5325 7.22156 12.4538 7.19012C12.3752 7.15869 12.291 7.14355 12.2064 7.14563C12.1218 7.14772 12.0384 7.16698 11.9615 7.20224C11.8845 7.23751 11.8155 7.28805 11.7586 7.35079L7.75082 11.3664C7.70439 11.4122 7.64511 11.4426 7.5809 11.4538C7.51668 11.465 7.45059 11.4563 7.39144 11.4289L1.53207 8.65548C1.30274 8.54716 1.1102 8.37397 0.978286 8.15735C0.846371 7.94073 0.780867 7.69019 0.789882 7.43673C0.803711 7.17575 0.899495 6.92579 1.0636 6.72241C1.22771 6.51902 1.45179 6.37258 1.70394 6.30391L16.2586 2.20235C16.5132 2.12965 16.7843 2.13965 17.0328 2.23092C17.2813 2.32218 17.4944 2.48999 17.6414 2.71016C17.7401 2.86662 17.8043 3.04227 17.8299 3.22545C17.8555 3.40863 17.8419 3.59516 17.7899 3.77266Z"
      fill="currentColor"
    />
  </svg>
);
