import { BaseIconProps } from './types';

const EyeSlash = ({ height = 20, width = 20 }: BaseIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path
        d="M3.75 3.125L16.25 16.875"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1012 12.312C11.5277 12.8374 10.7774 13.1275 9.99967 13.1245C9.36894 13.1244 8.75299 12.9335 8.23279 12.5768C7.7126 12.2202 7.31252 11.7144 7.08513 11.1261C6.85775 10.5378 6.81372 9.89442 6.95882 9.2806C7.10392 8.66679 7.43136 8.11125 7.8981 7.68701"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.78125 5.35986C2.59375 6.96924 1.25 10.0005 1.25 10.0005C1.25 10.0005 3.75 15.6255 10 15.6255C11.4645 15.6374 12.9108 15.3 14.2188 14.6411"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2961 13.211C17.9993 11.6875 18.7493 10 18.7493 10C18.7493 10 16.2493 4.37502 9.99927 4.37502C9.45744 4.37395 8.91648 4.41838 8.38208 4.50783"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5859 6.92969C11.2505 7.05561 11.8561 7.39427 12.3113 7.89457C12.7665 8.39487 13.0466 9.02965 13.1094 9.70312"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeSlash;
