import React from 'react';

const BrandsDelivery = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.75 13.2188H3.75V6.45833C3.75 6.33678 3.7983 6.2202 3.88425 6.13424C3.9702 6.04829 4.08678 6 4.20833 6H14.2917C14.4132 6 14.5298 6.04829 14.6158 6.13424C14.7017 6.2202 14.75 6.33678 14.75 6.45833V13.2188Z"
      fill="#C41719"
    />
    <path
      d="M15.4123 15.7969H14.75M14.75 8.0625H17.9583C18.027 8.06254 18.0948 8.07801 18.1566 8.10776C18.2185 8.1375 18.2729 8.18078 18.3158 8.23438L20.1492 10.526C20.2143 10.6073 20.2499 10.7083 20.25 10.8125V15.3477C20.25 15.4693 20.2017 15.5858 20.1158 15.6718C20.0298 15.7577 19.9132 15.806 19.7917 15.806H18.3273M16.5833 8.07166V10.7071C16.5833 10.8286 16.6316 10.9452 16.7176 11.0312C16.8035 11.1171 16.9201 11.1654 17.0417 11.1654H20.25M14.75 13.2188H3.75V6.45833C3.75 6.33678 3.7983 6.2202 3.88425 6.13424C3.9702 6.04829 4.08678 6 4.20833 6H14.2917C14.4132 6 14.5298 6.04829 14.6158 6.13424C14.7017 6.2202 14.75 6.33678 14.75 6.45833V13.2188ZM14.75 13.2188V15.7969H7.27C7.16405 15.4949 6.96691 15.2332 6.70581 15.0481C6.44471 14.863 6.13256 14.7636 5.8125 14.7636C5.49244 14.7636 5.18029 14.863 4.91919 15.0481C4.65809 15.2332 4.46095 15.4949 4.355 15.7969H4.20833C4.08678 15.7969 3.9702 15.7486 3.88425 15.6626C3.7983 15.5767 3.75 15.4601 3.75 15.3385V13.2188H14.75ZM7.35938 16.3217C7.35938 16.7319 7.1964 17.1254 6.9063 17.4155C6.61621 17.7056 6.22276 17.8685 5.8125 17.8685C5.40224 17.8685 5.00879 17.7056 4.7187 17.4155C4.4286 17.1254 4.26563 16.7319 4.26563 16.3217C4.26451 16.1458 4.29478 15.9712 4.355 15.806C4.46095 15.504 4.65809 15.2424 4.91919 15.0573C5.18029 14.8722 5.49244 14.7728 5.8125 14.7728C6.13256 14.7728 6.44471 14.8722 6.70581 15.0573C6.96691 15.2424 7.16405 15.504 7.27 15.806C7.33022 15.9712 7.36049 16.1458 7.35938 16.3217V16.3217ZM18.4167 16.3217C18.4174 16.7098 18.2724 17.0841 18.0104 17.3704C17.7484 17.6568 17.3884 17.8343 17.0017 17.868C16.6151 17.9016 16.2299 17.7889 15.9223 17.5521C15.6148 17.3153 15.4074 16.9716 15.3411 16.5892C15.2748 16.2068 15.3545 15.8134 15.5644 15.4869C15.7743 15.1604 16.0991 14.9246 16.4745 14.8261C16.85 14.7277 17.2487 14.7737 17.5918 14.9552C17.9349 15.1367 18.1974 15.4403 18.3273 15.806C18.3861 15.9716 18.4164 16.146 18.4167 16.3217V16.3217Z"
      stroke="black"
      strokeWidth="0.5625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.03771 8.15625L5.35938 10.5327H5.93917L6.59917 8.15854L6.03771 8.15625Z"
      fill="white"
    />
    <path
      d="M7.54096 8.16245L6.88555 8.88432L6.65638 8.16016L6.4043 9.00808L6.5945 9.85599L7.29576 9.01036L7.54096 8.16245Z"
      fill="white"
    />
    <path
      d="M7.48604 10.5366L8.16209 8.16016H7.58L6.90625 10.5343L7.48604 10.5366Z"
      fill="white"
    />
    <path
      d="M8.77013 10.5428L9.42784 10.5451L9.13451 9.28011L10.0558 8.16865L9.50346 8.16636L9.01076 8.77136L8.87096 8.16406H8.21784L8.49284 9.35344L6.7168 11.4641H7.27826L8.61659 9.88969L8.77013 10.5428Z"
      fill="white"
    />
    <path
      d="M8.11927 10.6758H8.02073L7.86719 11.2235H8.22239L8.24072 11.1433L7.98635 11.1387L8.11927 10.6758Z"
      fill="white"
    />
    <path
      d="M8.55053 11.0929L8.50471 11.1318H8.38325L8.35575 11.0975L8.44054 10.7995L8.48407 10.7652H8.60324L8.63533 10.7995L8.55053 11.0929ZM8.672 10.6758H8.46574L8.35345 10.7583L8.24805 11.1364L8.31678 11.2258H8.51615L8.63533 11.1318L8.74076 10.7606L8.672 10.6758Z"
      fill="white"
    />
    <path
      d="M8.98355 11.0195L8.96521 11.102L8.92167 11.1341L8.80708 11.1318L8.78188 11.0929L8.86209 10.8064L8.91021 10.7675H9.02709L9.04771 10.8064L9.03167 10.8706L9.13021 10.8729L9.15771 10.756L9.09354 10.6758H8.89875L8.775 10.7697L8.67188 11.1272L8.7475 11.2281H8.93771L9.05229 11.1364L9.08209 11.0218L8.98355 11.0195Z"
      fill="white"
    />
    <path
      d="M9.61675 10.6781H9.51133L9.26154 10.8981L9.32113 10.6781L9.22029 10.6758L9.06445 11.2281H9.16987L9.22258 11.0379L9.31654 10.9554L9.36925 11.2304H9.46549L9.39675 10.8774L9.61675 10.6781Z"
      fill="white"
    />
    <path
      d="M9.66568 10.9799H9.84901L9.86734 10.9066H9.68401L9.72068 10.7714H9.98651L10.0117 10.6797H9.65651L9.49609 11.232H9.85589L9.8811 11.1403H9.62214L9.66568 10.9799Z"
      fill="white"
    />
    <path
      d="M10.0763 10.9151L10.1152 10.773H10.2596L10.2802 10.7982L10.2619 10.883L10.2229 10.9196L10.0763 10.9151ZM10.349 10.9151L10.3902 10.7592L10.3329 10.6836H10.0488L9.89062 11.2359H9.98689L10.0511 11.0067H10.1175L10.1542 11.2359H10.2527L10.2138 11.0067H10.2413L10.349 10.9151Z"
      fill="white"
    />
  </svg>
);

export default BrandsDelivery;
