export const Rotate = ({
  width = 20,
  height = 20,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5835 7.7168L10.0002 10.2751L14.3835 7.73346"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 14.8092V10.2676"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.96688 5.2409L6.3002 6.72423C5.7002 7.05756 5.2002 7.89923 5.2002 8.59089V11.4159C5.2002 12.1076 5.69187 12.9492 6.3002 13.2826L8.96688 14.7659C9.53354 15.0826 10.4669 15.0826 11.0419 14.7659L13.7085 13.2826C14.3085 12.9492 14.8085 12.1076 14.8085 11.4159V8.58257C14.8085 7.8909 14.3169 7.04923 13.7085 6.71589L11.0419 5.23256C10.4669 4.91589 9.53354 4.9159 8.96688 5.2409Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3333 12.5C18.3333 15.725 15.725 18.3333 12.5 18.3333L13.375 16.875"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.6665 7.49935C1.6665 4.27435 4.27484 1.66602 7.49984 1.66602L6.62485 3.12435"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ThreeDRotate = () => (
  <svg
    width="80"
    height="81"
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.9993 76.3335C49.0993 76.3335 48.266 75.8335 47.8327 75.0668C47.3993 74.2668 47.3993 73.3335 47.866 72.5335L51.366 66.7001C52.066 65.5335 53.5993 65.1335 54.7993 65.8335C55.9993 66.5335 56.366 68.0668 55.666 69.2668L54.766 70.7668C63.966 68.6001 70.866 60.3335 70.866 50.4668C70.866 49.1001 71.9993 47.9668 73.366 47.9668C74.7327 47.9668 75.866 49.1001 75.866 50.4668C75.8327 64.7335 64.2327 76.3335 49.9993 76.3335Z"
      fill="#D9D9D9"
    />
    <path
      d="M6.66602 32.9993C5.29935 32.9993 4.16602 31.866 4.16602 30.4993C4.16602 16.266 15.766 4.66602 29.9993 4.66602C30.8993 4.66602 31.7327 5.16602 32.166 5.93268C32.5993 6.73268 32.5993 7.66602 32.1327 8.46602L28.6327 14.2993C27.9327 15.466 26.3993 15.866 25.1993 15.1327C24.0327 14.4327 23.6327 12.8993 24.366 11.6993L25.266 10.1993C16.0327 12.366 9.16602 20.6327 9.16602 30.4993C9.16602 31.866 8.03268 32.9993 6.66602 32.9993Z"
      fill="#D9D9D9"
    />
    <path
      d="M54.6672 27.8659L42.1339 21.0992C40.8005 20.3992 39.2339 20.3992 37.9005 21.0992L25.3339 27.8659C24.4339 28.3659 23.8672 29.3326 23.8672 30.3992C23.8672 31.4992 24.4339 32.4659 25.3339 32.9659L37.8672 39.7326C38.5339 40.0992 39.2672 40.2659 40.0005 40.2659C40.7339 40.2659 41.4672 40.0992 42.1339 39.7326L54.6672 32.9659C55.5672 32.4659 56.1339 31.4992 56.1339 30.3992C56.1339 29.3326 55.5672 28.3659 54.6672 27.8659Z"
      fill="#D9D9D9"
    />
    <path
      d="M35.8 42.0674L24.1333 36.2341C23.2333 35.8007 22.2 35.8341 21.3333 36.3674C20.5 36.9007 20 37.8007 20 38.8007V49.8341C20 51.7341 21.0667 53.4674 22.7667 54.3007L34.4333 60.1341C34.8333 60.3341 35.2667 60.4341 35.7333 60.4341C36.2667 60.4341 36.7667 60.3007 37.2333 60.0007C38.1 59.4674 38.6 58.5674 38.6 57.5674V46.5341C38.5667 44.6341 37.5333 42.9341 35.8 42.0674Z"
      fill="#D9D9D9"
    />
    <path
      d="M58.6337 36.3674C57.7671 35.8341 56.7337 35.8007 55.8337 36.2341L44.1671 42.0674C42.4671 42.9341 41.4004 44.6341 41.4004 46.5341V57.5674C41.4004 58.5674 41.9004 59.4674 42.7671 60.0007C43.2337 60.3007 43.7337 60.4341 44.2671 60.4341C44.7004 60.4341 45.1337 60.3341 45.5671 60.1341L57.2337 54.3007C58.9337 53.4341 60.0004 51.7341 60.0004 49.8341V38.8007C60.0004 37.8007 59.5004 36.9007 58.6337 36.3674Z"
      fill="#D9D9D9"
    />
  </svg>
);
